import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type UserListFragment = { __typename?: 'User', _id: string, login?: string | null, firstName?: string | null, lastName?: string | null, secondName?: string | null, phone?: string | null, email?: string | null, createdAt?: string | null, isActivated?: boolean | null };

export const UserListFragmentDoc = gql`
    fragment UserList on User {
  _id
  login
  firstName
  lastName
  secondName
  phone
  email
  createdAt
  isActivated
}
    `;