import React, {FC, useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Tag, Input} from "antd";
import {useGetCityListQuery} from "./__generated__/CityList.query.generated";
import {CityListFragment} from "./__generated__/CityList.fragment.generated";

const { Search } = Input;

const CityList:FC = () => {
  const { openRootHandler } = useDrawer();

  const { data, loading } = useGetCityListQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('cityAdd');

  const columns: ColumnsType<CityListFragment> = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'url',
      dataIndex: 'latin',
      key: 'latin',
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={row.active ? "green" : "volcano"}>
            { row.active ? "Активно" : "Скрыто"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('citySettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];
  return (
    <Table
      bordered
      indentSize={50}
      loading={loading}
      columns={columns}
      dataSource={data?.getCities}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить город</Button>
          </Col>
          <Col span={12} offset={8}>
            <Row justify="space-between">
              <Col span={11} />
              <Col span={11}>
                <Search style={{ width: '100%' }} placeholder="Введите название города" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default CityList;
