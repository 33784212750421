import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePointMutationVariables = Types.Exact<{
  inputs: Types.PointsInputs;
}>;


export type CreatePointMutation = { __typename?: 'Mutation', createPoint?: { __typename?: 'Point', id: string, name: string, address: string, active?: boolean | null, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null>, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null> } | null };


export const CreatePointDocument = gql`
    mutation createPoint($inputs: PointsInputs!) {
  createPoint(inputs: $inputs) {
    id
    name
    address
    emails {
      type
      email
    }
    phones {
      type
      phone
    }
    active
  }
}
    `;
export type CreatePointMutationFn = Apollo.MutationFunction<CreatePointMutation, CreatePointMutationVariables>;

/**
 * __useCreatePointMutation__
 *
 * To run a mutation, you first call `useCreatePointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPointMutation, { data, loading, error }] = useCreatePointMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useCreatePointMutation(baseOptions?: Apollo.MutationHookOptions<CreatePointMutation, CreatePointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePointMutation, CreatePointMutationVariables>(CreatePointDocument, options);
      }
export type CreatePointMutationHookResult = ReturnType<typeof useCreatePointMutation>;
export type CreatePointMutationResult = Apollo.MutationResult<CreatePointMutation>;
export type CreatePointMutationOptions = Apollo.BaseMutationOptions<CreatePointMutation, CreatePointMutationVariables>;