import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import { OrderList, OrderRoot, OrderStatus, OrderDelete } from "../../containers/Order";


const OrderPage:FC = () => {
  return (
    <>
      <OrderList />

      <Sidebar
        name="ordersSettings"
        rootDrawerElement={() => <OrderRoot />}
        childrenDrawerElements={[
          { name: "ordersSettingsStatus", element: () => <OrderStatus /> },
          { name: "ordersSettingsDelete", element: () => <OrderDelete /> }
        ]}
      />
    </>
  )
}


export default OrderPage;
