import Moment from "react-moment";
import React, {FC, useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Tag, Input, Typography, List} from "antd";
import {useGetOrderQuery} from "./__generated__/OrderList.query.generated";
import {OrderListFragment} from "./__generated__/OrderList.fragment.generated";

const { Search } = Input;
const { Text } = Typography;


const OrderList:FC = () => {
  const { openRootHandler } = useDrawer();
  const [search, setSearch] = useState('');

  const { loading, data } = useGetOrderQuery({ pollInterval: 1500 });


  const statuses = {
    WAITING: { color: 'orange', text: 'В ожидании' },
    INWORK: { color: 'blue', text: 'В работе' },
    COMPLETED: { color: 'green', text: 'Завершен' },
    CANCELED: { color: 'red', text: 'Отменен' }
  };

  const columns: ColumnsType<OrderListFragment> = [
    {
      title: 'Код',
      key: 'code',
      dataIndex: 'code',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Объявление',
      dataIndex: 'ads',
      render: (_, row) => (
        <>
          <Text>{row.ads.device.title}</Text>
          <br />
          <Text>Стоимость продавца: <Text strong>{row.ads.device.price.current} ₸</Text></Text>
        </>
      )
    },
    {
      title: 'Заказчик',
      dataIndex: 'customers',
      render: (_, row) => (
        <>
          {row.person.firstName} {row.person.lastName}
          <br />
          {row.person.phone}
        </>
      )
    },
    {
      title: 'Продавец',
      dataIndex: 'seller',
      render: (_, row) =>
        <>
          {row.ads?.user?.firstName} {row.ads?.user?.lastName}
          <br />
          {row.ads.user?.phone}
        </>
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={statuses[row.status.type].color}>
            {statuses[row.status.type].text}
          </Tag>
        </>
      ),
    },
    {
      title: 'Дата оформления',
      key: 'createdAt',
      render: (_, row) => (
        <>
          {row.date.createdAt && <Moment format="DD.MM.YYYY (HH:mm)">{row.date.createdAt}</Moment> }
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('ordersSettings', row._id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      rowKey="_id"
      loading={loading}
      columns={columns}
      dataSource={data?.getOrders}
      pagination={{ defaultPageSize: 20 }}
      expandable={{
        expandedRowRender: record => {
          return (
            <Row gutter={[16, 24]}>
              <Col span={8}>
                <List
                  bordered
                  header={<Text strong>Cервисы добавленные продавцом</Text>}
                  dataSource={record.ads.addedServices}
                  renderItem={item => (
                    <List.Item>
                      {item?.title}: <Text strong>{item?.price} ₸</Text>
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={8}>
                <List
                  bordered
                  header={<Text strong>Cервисы добавленные покупателем</Text>}
                  dataSource={record.services}
                  renderItem={item => (
                    <List.Item>
                      {item?.title}: <Text strong>{item?.price} ₸</Text>
                    </List.Item>
                  )}
                />
              </Col>
              <Col span={8}>
                <List
                  bordered
                  header={<Text strong>Аксессуары добавленные покупателем</Text>}
                  dataSource={record.accessories}
                  renderItem={item => (
                    <List.Item>
                      {item?.title}: <Text strong>{item?.price} ₸</Text>
                    </List.Item>
                  )}
                />
              </Col>

              <Col span={8}>
                <Text strong>Комментарий к заказу</Text>
                <br />
                <Text>{record.subject}</Text>
              </Col>
            </Row>
          )
        }
      }}
      title={() => (
        <Row>
          <Col span={8} offset={16}>
            <Search placeholder="Введите код объявления" onChange={(e) => setSearch(e.target.value)}/>
          </Col>
        </Row>
      )}
    />
  )
}

export default OrderList;
