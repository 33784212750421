import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AdsSlice {
  id: string;
  sidebar: boolean;
  type: string;
}

const initialState: AdsSlice = {
  id: '',
  type: '',
  sidebar: false
}

const drawerSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {
    openSidebar: (state, action: PayloadAction<string>) => {
      state.type = '';
      state.sidebar = true;
      state.id = action.payload;
    },
    closeSidebar: (state) => {
      state.type = '';
      state.id = '';
      state.sidebar = false;
    },
    openOptions: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    closeOptions: (state) => {
      state.type = '';
    }
  }
})

export const {
  openSidebar,
  closeSidebar,
  openOptions,
  closeOptions
} = drawerSlice.actions;

export default drawerSlice.reducer;
