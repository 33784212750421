import React, {FC} from "react";
import styled from "styled-components";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useAppSelector} from "../../../hooks/useAppDispatch";
import {CheckOutlined, UploadOutlined} from "@ant-design/icons";
import UserSelect from "../../../components/UserSelect/UserSelect";
import ModelSelect from "../../../components/ModelSelect/ModelSelect";
import StatusSelect from "../../../components/StatusSelect/StatusSelect";
import {useEditAdMutation} from "./__generated__/AdsEdit.mutation.generated";
import {useGetAdEditInfoQuery} from "./__generated__/AdsEdit.query.generated";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ServicesSelect from "../../../components/ServicesSelect/ServicesSelect";
import ConditionSelect from "../../../components/ConditionsSelect/ConditionSelect";
import AccessoriesSelect from "../../../components/AccessoriesSelect/AccessoriesSelect";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";
import ServicesGeokursSelect from "../../../components/ServicesGeokursSelect/ServicesGeokursSelect";
import { Checkbox, Col, Divider, Form, Input, InputNumber, Row, Space, Timeline, Upload, Typography, Descriptions} from "antd";
import LabelSelect from "../../../components/LabelSelect/LabelSelect";

const { Text } = Typography;

const Ul = styled.ul`
  position: relative;
`;

const Li = styled.li`
  list-style: none;
  position: relative;
  font-size: 1.3rem;
  .anticon {
    margin-right: .5rem;
  }
`;


const AdsEdit:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();
  const { id } = useAppSelector(state => state.drawer);

  const {data, loading, error} = useGetAdEditInfoQuery({ variables: { id }});

  const [editHandler, { loading: editLoading }] = useEditAdMutation({
    onCompleted: () => {
      closeRootHandler();
      toast.success('Объявление успешно обновленно!');
    },
    onError: () => {
      toast.error('При обновлении объявления произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => editHandler({ variables: { adsID: id, inputs: { ...values } }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getAdById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading || editLoading}>
      <Row>
        <Col span={12}>
          <Divider orientation="left" style={{color: '#ac2b6f'}}>Продавец</Divider>
          <Ul>
            <Li><Text strong>ФИО:</Text> <Text>{data?.getAdById?.user?.firstName} {data?.getAdById?.user?.lastName}</Text></Li>
            <Li><Text strong>Телефон:</Text> <Text>{data?.getAdById?.user?.phone}</Text></Li>
            <Li><Text strong>Email:</Text> <Text>{data?.getAdById?.user?.email}</Text></Li>
            <Li><Text strong>Code:</Text> <Text>{data?.getAdById?.code}</Text></Li>
            <Li><Text strong>Id:</Text> <Text>{data?.getAdById?.id}</Text></Li>
          </Ul>
        </Col>
        { data?.getAdById?.desirableServices.length > 0 &&  (
          <Col span={12}>
            <Divider orientation="left" style={{color: '#ac2b6f'}}>Желаемые сервисы</Divider>
            <Ul>
              {data?.getAdById?.desirableServices.map(item => (
                <Li><CheckOutlined/><Text>{item?.title}</Text></Li>
              ))}
            </Ul>
          </Col>
        )}
      </Row>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        initialValues={{
          device: {
            title: data?.getAdById?.device.title,
            description: data?.getAdById?.device.description,
            model: data?.getAdById?.device.model.id,
            category: data?.getAdById?.device.category.id,
            manufacturer: data?.getAdById?.device.manufacturer.id,
            price: {
              current: data?.getAdById?.device.price.current,
              auction: data?.getAdById.device.price.auction
            },
            condition: data?.getAdById?.device.condition.id
          },
          addedServices: data?.getAdById.addedServices.map((item: any) => item.id),
          selectServices: data?.getAdById.selectServices.map((item: any) => item.id),
          accessories: data?.getAdById.accessories.map((item: any) => item.id),
          labels: data?.getAdById.labels.map((item: any) => item.id),
          user: data?.getAdById.user._id,
          status: data?.getAdById?.status.id
        }}
      >
        <Divider orientation="left" style={{color: '#ac2b6f'}}>Информация о приборе</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['device', "category"]}
              label="Выберите категорию"
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <CategorySelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите категорию"
                defaultValue={data?.getAdById?.device.category.id}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Выберите производителя"
              name={['device', "manufacturer"]}
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <ManufacturerSelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите производителя"
                defaultValue={data?.getAdById?.device.manufacturer.id}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Выберите модель"
              name={['device', "model"]}
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <ModelSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите модель"
                defaultValue={data?.getAdById?.device.model.id}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Выберите состояние"
              name={['device', "condition"]}
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <ConditionSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите состояние"
                defaultValue={data?.getAdById?.device.condition.id}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Укажите стоимость">
          <Space style={{alignItems: 'center'}} size={25}>
            <Form.Item
              name={['device', 'price', 'current']}
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <InputNumber addonAfter="₸"/>
            </Form.Item>
            <Form.Item name={['device', 'price', 'auction']} valuePropName="checked">
              <Checkbox>Торг уместен</Checkbox>
            </Form.Item>
          </Space>
        </Form.Item>

        <Divider orientation="left" style={{color: '#ac2b6f'}}>Описание и изображения прибора</Divider>
        <Form.Item
          label="Заголовок"
          name={['device', 'title']}
          rules={[{required: true, message: 'Заполните поле'}]}
        >
          <Input showCount maxLength={150}/>
        </Form.Item>

        <Form.Item
          label="Описание"
          name={['device', 'description']}
          rules={[{required: true, message: 'Заполните поле'}]}
        >
          <Input.TextArea showCount maxLength={1500}/>
        </Form.Item>

        <Divider orientation="left" style={{color: '#ac2b6f'}}>Сервисы</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="addedServices"
              label="Выберите сервисы доступные по умолчанию"
            >
              <ServicesSelect
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Выберите сервисы"
                defaultValue={data?.getAdById.addedServices.map((item: any) => item.id)}
              />
            </Form.Item>
            <Form.Item
              name="selectGeokurs"
              label="Выберите сервисы геокурса"
            >
              <ServicesGeokursSelect
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Выберите сервисы"
                defaultValue={data?.getAdById.selectGeokurs}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="selectServices"
              label="Выберите сервисы доступные для покупателя"
            >
              <ServicesSelect
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Выберите сервисы"
                defaultValue={data?.getAdById.selectServices.map((item: any) => item.id)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" style={{color: '#ac2b6f'}}>Аксессуары</Divider>
        <Form.Item
          name="accessories"
          label="Выберите аксессуары доступные для покупателя"
        >
          <AccessoriesSelect
            mode="multiple"
            allowClear={true}
            showSearch={true}
            placeholder="Выберите аксессуары"
            defaultValue={data?.getAdById.accessories.map((item: any) => item.id)}
          />
        </Form.Item>


        <Divider orientation="left" style={{color: '#ac2b6f'}}>Прочие настройки</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="user"
              label="Выберите менеджера"
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <UserSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите менеджера"
                defaultValue={data?.getAdById.user._id}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Укажите статус объявления"
              rules={[{required: true, message: 'Заполните поле'}]}
            >
              <StatusSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите статус"
                defaultValue={data?.getAdById.status.id}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="labels"
              label="Выберите лейблы"
            >
              <LabelSelect
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Выберите лейблы"
                defaultValue={data?.getAdById.labels.map((item: any) => item.id)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Contentbar>
  )
}

export default AdsEdit;
