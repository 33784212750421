import React, {FC, useState} from "react";
import {Checkbox, Col, Form, Input, Radio, Row} from "antd";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useCreateUserMutation} from "./__generated__/UserAdd.mutation.generated";
import CitySelect from "../../../components/CitySelect/CitySelect";
import {toast} from "react-toastify";


const UserAdd:FC = () => {
  const [form] = Form.useForm();
  const {closeRootHandler } = useDrawer();
  const [userType, setUserType] = useState('');

  const [createHandler, { data, loading }] = useCreateUserMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Пользователь успешно добавлен, ссылка на активацию аккаунта выслана на почту!');
    },
    onError: () => {
      toast.error('При добавлении пользователя произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        initialValues={{
          person: { type: 'INDIVIDUAL' }
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="Введите имя"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Введите фамилию"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Введите номер телефона"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Введите email"
              rules={[
                {
                  type: 'email',
                  message: 'Введите верный email',
                },
                {
                  required: true,
                  message: 'Введите email',
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              name="password"
              label="Введите пароль"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input.Password allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Подтвердите пароль"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Подтвердите пароль',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Пароли не совпадают'));
                  },
                }),
              ]}
            >
              <Input.Password allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="Укажите город"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <CitySelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите город"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              label="Введите адрес"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name={['person', 'type']}
          label="Выберите тип регистрации"
        >
          <Radio.Group onChange={(e) => setUserType(e.target.value)} value="test">
            <Radio value="INDIVIDUAL">Частное лицо</Radio>
            <Radio value="ENTITY">Представитель компании</Radio>
          </Radio.Group>
        </Form.Item>

        { userType === 'ENTITY' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={['person', 'company']}
                label="Наименование компании"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['person', 'address']}
                label="Адрес компании"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="БИН"
                name={['person', 'bin']}
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Contentbar>
  )
}

export default UserAdd;
