import {FC} from "react";
import styled from "styled-components";

const StyledHead = styled.thead`
`;


const Thead:FC = ({ children }) => {
  return (
    <StyledHead>{children}</StyledHead>
  )
}

export default Thead;
