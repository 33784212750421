import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { LabelsListFragmentDoc } from '../../LabelsLists/__generated__/LabelLists.fragmet.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditLabelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  inputs: Types.LabelInput;
}>;


export type EditLabelMutation = { __typename?: 'Mutation', editLabel?: { __typename?: 'Label', id: string, icon: string, name: string, color: string, active?: boolean | null, description: string } | null };


export const EditLabelDocument = gql`
    mutation editLabel($id: ID!, $inputs: LabelInput!) {
  editLabel(id: $id, inputs: $inputs) {
    ...LabelsList
  }
}
    ${LabelsListFragmentDoc}`;
export type EditLabelMutationFn = Apollo.MutationFunction<EditLabelMutation, EditLabelMutationVariables>;

/**
 * __useEditLabelMutation__
 *
 * To run a mutation, you first call `useEditLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLabelMutation, { data, loading, error }] = useEditLabelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useEditLabelMutation(baseOptions?: Apollo.MutationHookOptions<EditLabelMutation, EditLabelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditLabelMutation, EditLabelMutationVariables>(EditLabelDocument, options);
      }
export type EditLabelMutationHookResult = ReturnType<typeof useEditLabelMutation>;
export type EditLabelMutationResult = Apollo.MutationResult<EditLabelMutation>;
export type EditLabelMutationOptions = Apollo.BaseMutationOptions<EditLabelMutation, EditLabelMutationVariables>;