import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { CityListFragmentDoc } from '../../CityList/__generated__/CityList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditCityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  active: Types.Scalars['Boolean'];
}>;


export type EditCityMutation = { __typename?: 'Mutation', editCity?: { __typename?: 'City', id: string, name: string, latin: string, active?: boolean | null } | null };


export const EditCityDocument = gql`
    mutation editCity($id: ID!, $name: String!, $active: Boolean!) {
  editCity(id: $id, name: $name, active: $active) {
    ...CityList
  }
}
    ${CityListFragmentDoc}`;
export type EditCityMutationFn = Apollo.MutationFunction<EditCityMutation, EditCityMutationVariables>;

/**
 * __useEditCityMutation__
 *
 * To run a mutation, you first call `useEditCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCityMutation, { data, loading, error }] = useEditCityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useEditCityMutation(baseOptions?: Apollo.MutationHookOptions<EditCityMutation, EditCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCityMutation, EditCityMutationVariables>(EditCityDocument, options);
      }
export type EditCityMutationHookResult = ReturnType<typeof useEditCityMutation>;
export type EditCityMutationResult = Apollo.MutationResult<EditCityMutation>;
export type EditCityMutationOptions = Apollo.BaseMutationOptions<EditCityMutation, EditCityMutationVariables>;