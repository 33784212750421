import {FC} from "react";
import { toast } from "react-toastify";
import useDrawer from "../../../hooks/useDrawer";
import {Contentbar} from "../../../ui/Sidebar";
import {useDeleteServicesMutation} from "./__generated__/ServicesDelete.mutation.generated";


const ServicesDelete:FC = () => {
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const [deleteHandler, { loading }] = useDeleteServicesMutation({
    variables: { id },
    onCompleted: () => {
      toast.success('Удаление выполнено!');
      closeRootHandler();
      closeChildrenHandler();
    },
    onError: () => {
      toast.error('При удалении произошла ошибка');
    }
  });

  return (
    <Contentbar isLoading={loading} onClose={closeChildrenHandler} onSubmit={deleteHandler}>
      <h2>Вы точно уверены?</h2>
      <p><b>Внимание!</b> Удаление происиходит безвовратно, удаленые данные восстановлению не подлежать.</p>
      <p>Сервис удалится у всех объявлений к которым он был добавлен.</p>
      <p>Для продолжения нажмите кнопку <b>"Подтвердить".</b></p>
    </Contentbar>
  )
}

export default ServicesDelete;
