import {FC} from "react";
import styled from "styled-components";
import {CityListFragment} from "../CityList/__generated__/CityList.fragment.generated";


const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;


interface CityInformProps {
  info: CityListFragment
}

const CityInform:FC<CityInformProps> = ({ info }) => {
  return (
      <Container>
        <p>Информация о городе:</p>
        <h2>{info.name}</h2>
        <p>{info.latin}</p>
      </Container>
  )
}

export default CityInform;
