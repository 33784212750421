import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type ModelsListFragment = { __typename?: 'Models', id: string, name: string, url: string, active?: boolean | null, manufacturers: { __typename?: 'Manufacturer', id: string, name: string }, categories: { __typename?: 'Category', id: string, name: string } };

export const ModelsListFragmentDoc = gql`
    fragment ModelsList on Models {
  id
  name
  url
  active
  manufacturers {
    id
    name
  }
  categories {
    id
    name
  }
}
    `;