import {FC} from "react";
import {Link} from "../../../ui";
import {LabelsInform} from "../index";
import styled from "styled-components";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {LoadingIcon} from "../../../ui/Icons";
import {useGetLabelsByIdQuery} from "./__generated__/LabelsRoot.query.generated";


const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const LabelsRoot:FC = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetLabelsByIdQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getLabelById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <LabelsInform
        info={data?.getLabelById}
      />
      <Options>
        <h2>Управление лейблом</h2>
        <ul>
          <Link colorTheme="green" onClick={() => openChildrenHandler('labelsSettingsEdit')}>Редактировать лейбл</Link>
          <Link colorTheme="red" onClick={() => openChildrenHandler('labelsSettingsDelete')}>Удалить лейбл</Link>
        </ul>
      </Options>
    </>
  )
}

export default LabelsRoot;
