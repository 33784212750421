import { FC } from "react";
import styled, {css} from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  max-width: 50rem;
`;

const Description = styled.div<{ exist: boolean }>`
  ${props => props.exist && css`margin-bottom: 3rem;`}
  h2 {
    margin-bottom: .8rem;
  }
`;


const Error = styled.div`
  font-size: 1.4rem;
  margin-bottom: 2rem;
  margin-top: -2rem;
  color: ${props => props.theme.palette.red};
`;

interface FormProps {
  title?: string;
  description?: string;
  error?: string;
  loading: boolean;
  onSubmit: () => void;
}

const Form:FC<FormProps> = ({ children, error, loading, onSubmit, title, description }) => {
  const cls = ['form'];

  loading && cls.push('form__loading');

  return (
    <Wrapper>

      <Description exist={!!title || !!description}>
        {title && <h2>{title}</h2>}
        {description && <p>{description}</p>}
      </Description>

      { error && <Error>Произошла ошибка: {error}</Error> }

      <form onSubmit={onSubmit} className={cls.join(' ')} noValidate>
        { children }
      </form>
    </Wrapper>
  )
}

export default Form;
