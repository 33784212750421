import {useMutation, useQuery} from "@apollo/client";
import {
  CHANGE_ADS_STATUS,
  DELETE_AD,
  GET_AD_BY_ID,
  GET_CATEGORIES,
  GET_STATUSES,
  GET_USER,
  USER_REFRESH
} from "../query";
import {IAd, ICategory, IStatuses, IUser} from "../../interface";
import {toast} from "react-toastify";


export const useRefreshUser = () => useQuery<{ userRefresh: IUser }>(USER_REFRESH, {
  onCompleted: ({ userRefresh: { accessToken }  }) => {
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    }
  },
  onError: (error) => {
    const code = error.graphQLErrors[0].extensions.code;

    if (code === "UNAUTHENTICATED") {
      localStorage.removeItem("accessToken");
    }
  },
  fetchPolicy: "network-only"
});


export const useDeleteAds = (adsID: string) => useMutation<string>(DELETE_AD, {
  variables: { id: adsID },
  onCompleted: () => {
    toast.success('Удаление прошло успешно!');
  }
});


export const useGetAdsById = (id: string) => useQuery<{ getAdById: IAd }>(GET_AD_BY_ID, { variables: { id }});
export const useGetUser = () => useQuery<{ userInfo: IUser }>(GET_USER);
export const useGetCategories = () => useQuery<{ getCategories: ICategory[] }>(GET_CATEGORIES);
export const useGetStatuses = () => useQuery<{getStatuses: IStatuses[]}>(GET_STATUSES);
