import {FC} from "react";
import {Spin} from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 4rem;
  }
`;

const Loading:FC = () => {
  return (
    <Wrapper>
      <Spin />
    </Wrapper>
  )
}

export default Loading;
