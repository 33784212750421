import { combineReducers, configureStore } from "@reduxjs/toolkit";
import AdsReducer from "./reducers/AdsSlice/AdsSlice";
import DrawerReducer from "./reducers/DrawerSlice/DrawerSlice";

const rootReducer = combineReducers({
  ads: AdsReducer,
  drawer: DrawerReducer
})


export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  })
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
