import {Form} from "antd";
import React, {FC} from "react";
import { toast } from "react-toastify"
import { useForm } from "react-hook-form";
import {LoadingIcon} from "../../../ui/Icons";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import UserSelect from "../../../components/UserSelect/UserSelect";
import {useGetAdManagerQuery} from "./__generated__/AdsManager.query.generated";
import {useChangeManagerMutation} from "./__generated__/AdsManager.mutation.generated";


const AdsManager:FC = () => {
  const { id, closeChildrenHandler } = useDrawer();
  const [form] = Form.useForm<{ managerID: string }>();

  const {data, loading, error} = useGetAdManagerQuery({ variables: { id }});

  const [changeHandler, { loading: changeLoading }] = useChangeManagerMutation({
    onCompleted: () => {
      toast.success('Изменение менеджера прошло успешно!');
    },
    onError: () => {
      toast.error('Произошла ошибка');
    }
  });


  const handleSubmit = () => {
    form.validateFields().then((values) =>  changeHandler({ variables: { adsID: id, ...values }}));
  };

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getAdById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar isLoading={changeLoading} onSubmit={handleSubmit} onClose={closeChildrenHandler}>
      <h2>Смена менджера объявления:</h2>
      <p>Для продолжения укажите нужного менеджера и нажмите кнопку <b>"Подтвердить".</b></p>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        initialValues={{
          device: {
            price: {
              auction: false
            }
          }
        }}
      >
        <Form.Item
          name="managerID"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <UserSelect
            showSearch={true}
            allowClear={true}
            placeholder="Выберите статус"
            defaultValue={data.getAdById?.manager?.firstName ? `${data.getAdById?.manager?.firstName} ${data.getAdById?.manager?.lastName}` : ''}
          />
        </Form.Item>
      </Form>
    </Contentbar>

  )
}

export default AdsManager;
