import styled from "styled-components";
import {FC} from "react";

const StyledTd = styled.td`
  padding: 1.5rem 1rem;
  font-size: 1.3rem;
  line-height: 135%;
  background: #fff;
`;

const Td:FC = ({ children }) => {
  return (
    <StyledTd>{children}</StyledTd>
  )
}

export default Td;
