import React, {FC} from "react";
import {toast} from "react-toastify";
import {Checkbox, Form, Input} from "antd";
import useDrawer from "../../../hooks/useDrawer";
import {Contentbar} from "../../../ui/Sidebar";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import {useCreateManufacturerMutation} from "./__generated__/ManufacturerAdd.mutation.generated";

const ManufacturerAdd:FC = () => {
  const [form] = Form.useForm<{ name: string, categoryIDs: string[]}>();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { data, loading }] = useCreateManufacturerMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Производитель усешно добавлен!');
    },
    onError: () => {
      toast.error('При добавлении производителя произошла ошибка!')
    }
  });

  const handleSubmit = () => form.validateFields().then((values) => createHandler({ variables: { ...values }}));

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="name"
          label="Введите название производителя"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="categoryIDs"
          label="Привяжите к категориям"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <CategorySelect
            mode="multiple"
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
          />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default ManufacturerAdd;
