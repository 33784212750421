import React, {FC} from "react";
import {toast} from "react-toastify";
import {Form, Input, InputNumber} from "antd";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetServicesEditQuery} from "./__generated__/ServicesEdit.query.generated";
import {useEditServiceMutation} from "./__generated__/ServicesEdit.mutation.generated";
import ServicesCategorySelect from "../../../components/ServicesCategorySelect/ServicesCategorySelect";

const ServicesEdit:FC = () => {
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();
  const { data, loading, error } = useGetServicesEditQuery({ variables: { id } });

  const [form] = Form.useForm()

  const [editHandler, { loading: editLoading }] = useEditServiceMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Сервис успешно обновлена!');
    },
    onError: () => {
      toast.error('При обновлении сервиса произошла ошибка!')
    }
  })

  const handleSubmit = () => {
    form.validateFields().then((values) => editHandler({ variables: { ...values, id }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getServiceById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          ...data?.getServiceById,
          category: data?.getServiceById.category.id,
        }}
      >
        <Form.Item
          name="category"
          label="Выберите категорию сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <ServicesCategorySelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
            defaultValue={data.getServiceById?.category.id}
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Заголовок сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Введите описание сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item
          name="price"
          label="Введите стоимость сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <InputNumber addonAfter="₸" style={{ width: "100%" }}/>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default ServicesEdit;
