import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteOrderManagerMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteOrderManagerMutation = { __typename?: 'Mutation', deleteOrderManager?: string | null };


export const DeleteOrderManagerDocument = gql`
    mutation deleteOrderManager($id: ID!) {
  deleteOrderManager(id: $id)
}
    `;
export type DeleteOrderManagerMutationFn = Apollo.MutationFunction<DeleteOrderManagerMutation, DeleteOrderManagerMutationVariables>;

/**
 * __useDeleteOrderManagerMutation__
 *
 * To run a mutation, you first call `useDeleteOrderManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderManagerMutation, { data, loading, error }] = useDeleteOrderManagerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderManagerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderManagerMutation, DeleteOrderManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderManagerMutation, DeleteOrderManagerMutationVariables>(DeleteOrderManagerDocument, options);
      }
export type DeleteOrderManagerMutationHookResult = ReturnType<typeof useDeleteOrderManagerMutation>;
export type DeleteOrderManagerMutationResult = Apollo.MutationResult<DeleteOrderManagerMutation>;
export type DeleteOrderManagerMutationOptions = Apollo.BaseMutationOptions<DeleteOrderManagerMutation, DeleteOrderManagerMutationVariables>;