import bgGrey from "./images/background_grey.png";
import logo from "./images/logo.png";
import logoSvg from "./images/logo.svg";

const images = {
    logo,
    logoSvg
}
const bg = {
    bgGrey
}

export {
    bg,
    images
}
