import {FC} from "react";
import {Select, Space, Tag} from "antd";
import {useGetServiceCategorySelectQuery} from "./__generated__/ServicesCategorySelect.query.generated";


const ServicesCategorySelect:FC<ServicesSelectProps> = ({ defaultValue, onChange, size = "middle", showSearch = true, allowClear = false, placeholder }) => {
  const {loading, data} = useGetServiceCategorySelectQuery();

  return (
      <Select
        size={size}
        loading={loading}
        defaultValue={defaultValue}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: '100%' }}
        optionFilterProp="children"
      >
        {data?.getServicesCategory.map((service) => (
          <Select.Option
            key={service.id}
            value={service.id}
          >
            {service.title}
          </Select.Option>
        ))}
      </Select>
  )
}

interface ServicesSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}


export default ServicesCategorySelect;
