import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { OrderListFragmentDoc } from './OrderList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrderQuery = { __typename?: 'Query', getOrders: Array<{ __typename?: 'Order', _id: string, code: string, subject: string, status: { __typename?: 'OrderStatus', id: string, title: string, type: Types.OrderStatusType }, ads: { __typename?: 'Ads', device: { __typename?: 'AdsDevice', title: string, condition: { __typename?: 'Condition', id: string, name?: string | null }, category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } }, user: { __typename?: 'User', phone?: string | null, lastName?: string | null, firstName?: string | null }, addedServices: Array<{ __typename?: 'Services', id: string, price: number, title: string, category: { __typename?: 'ServicesCategory', title: string } } | null> }, services: Array<{ __typename?: 'Services', id: string, price: number, title: string } | null>, accessories: Array<{ __typename?: 'Accessories', id: string, title: string, subtitle: string, price: number } | null>, person: { __typename?: 'OrderPersonal', phone?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, city?: { __typename?: 'City', id: string, name: string } | null, personal?: { __typename?: 'AdsPersonalPerson', type: Types.PersonType, bin: string, address: string, company: string } | null }, date: { __typename?: 'OrderDate', createdAt?: string | null, finishedAt?: string | null } }> };


export const GetOrderDocument = gql`
    query getOrder {
  getOrders {
    ...OrderList
  }
}
    ${OrderListFragmentDoc}`;

/**
 * __useGetOrderQuery__
 *
 * To run a query within a React component, call `useGetOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
      }
export function useGetOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderQuery, GetOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderQuery, GetOrderQueryVariables>(GetOrderDocument, options);
        }
export type GetOrderQueryHookResult = ReturnType<typeof useGetOrderQuery>;
export type GetOrderLazyQueryHookResult = ReturnType<typeof useGetOrderLazyQuery>;
export type GetOrderQueryResult = Apollo.QueryResult<GetOrderQuery, GetOrderQueryVariables>;