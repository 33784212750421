import styled, {css} from "styled-components";
import React, { forwardRef, InputHTMLAttributes } from 'react';


const StyledInput = styled.input<{ isError: boolean, isIcon: boolean }>`
  display: block;
  width: 100%;
  height: 4.2rem;
  font-size: 1.6rem;
  color: ${props => props.theme.palette.black};
  border: 1px solid ${props => props.theme.palette.line};
  &::placeholder {
    font-size: 1.4rem;
    color: ${props => props.theme.palette.darkGrey};
  }
  &:focus {
    border: 1px solid ${props => props.theme.palette.darkGrey};
  }
  &:disabled {
    background: #F9F9F9;
    border: 1px solid ${props => props.theme.palette.line};
  }
  
  ${props => props.isError && css`
    background: #FFF2F2;
    border: 1px solid ${props => props.theme.palette.red};
    &:focus {
      border: 1px solid ${props => props.theme.palette.red};
    }
  `}
  
  ${props => props.isIcon 
          ? css`padding: 0 5rem 0 1.5rem;` 
          : css`padding: 0 1.5rem;`
  }
`;


export type InputType = 'text' | 'email' | 'tel' | 'password';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  isError?: boolean;
  type?: InputType;
  isIcon?: boolean;
  disabled?: boolean;
  placeholder: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      id,
      name,
      type = 'text',
      placeholder,
      isIcon = false,
      isError = false,
      disabled = false,
      ...props
    },
    ref
  ) => {
    return (
      <StyledInput
        id={id}
        ref={ref}
        name={name}
        type={type}
        value={value}
        isIcon={isIcon}
        isError={isError}
        disabled={disabled}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);

export default Input;
