import {FC} from "react";
import styled, {css} from "styled-components";

const StyledLabel = styled.label<{ disabled?: boolean }>`
  display: block;
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: .25em;
  ${props => props.disabled
    ? css`color: ${props => props.theme.palette.grey};`
    : css`color: ${props => props.theme.palette.black};`
}
`;

interface LabelProps {
  disabled?: boolean;
  htmlFor?: string;
}

const Label:FC<LabelProps> = ({ htmlFor, disabled, children}) => {
  return (
    <StyledLabel disabled={disabled} htmlFor={htmlFor}>{children}</StyledLabel>
  )
}

export default Label;
