import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type StatusListFragment = { __typename?: 'Status', id: string, title: string, type: Types.StatusType };

export const StatusListFragmentDoc = gql`
    fragment StatusList on Status {
  id
  title
  type
}
    `;