import React, {FC} from "react";
import {toast} from "react-toastify";
import {Button, Checkbox, Form, Input} from "antd";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {Contentbar} from "../../../ui/Sidebar";
import {useGetCityByIdQuery} from "./__generated__/CityEdit.query.generated";
import {useEditCityMutation} from "./__generated__/CityEdit.mutation.generated";

const CityEdit:FC = () => {
  const [form] = Form.useForm()
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetCityByIdQuery({ variables: { id } })

  const [editHandler, { loading: editManufacturer }] = useEditCityMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Город успешно обновлен!');
    },
    onError: () => {
      toast.error('При обновлении города произошла ошибка!')
    }
  })

  const handleSubmit = () => form.validateFields().then((values) =>  editHandler({ variables: { ...values, id }}));

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getCityById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editManufacturer}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: data?.getCityById?.name || '',
          active: data?.getCityById?.active || false,
        }}
      >
        <Form.Item
          name="name"
          label="Введите наименование города"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default CityEdit;
