import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { SelectCategoryFragmentDoc } from './CategorySelect.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectCategoriesQuery = { __typename?: 'Query', getCategories: Array<{ __typename?: 'Category', url?: string | null, id: string, name: string }> };


export const GetSelectCategoriesDocument = gql`
    query getSelectCategories {
  getCategories {
    ...SelectCategory
    url
  }
}
    ${SelectCategoryFragmentDoc}`;

/**
 * __useGetSelectCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSelectCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectCategoriesQuery, GetSelectCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectCategoriesQuery, GetSelectCategoriesQueryVariables>(GetSelectCategoriesDocument, options);
      }
export function useGetSelectCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectCategoriesQuery, GetSelectCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectCategoriesQuery, GetSelectCategoriesQueryVariables>(GetSelectCategoriesDocument, options);
        }
export type GetSelectCategoriesQueryHookResult = ReturnType<typeof useGetSelectCategoriesQuery>;
export type GetSelectCategoriesLazyQueryHookResult = ReturnType<typeof useGetSelectCategoriesLazyQuery>;
export type GetSelectCategoriesQueryResult = Apollo.QueryResult<GetSelectCategoriesQuery, GetSelectCategoriesQueryVariables>;