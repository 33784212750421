import {Checkbox, Form, Input} from "antd";
import React, {FC} from "react";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import {useGetManufacturerEditQuery} from "./__generated__/ManufacturerEdit.query.generated";
import {useEditManufacturerMutation} from "./__generated__/ManufacturerEdit.mutation.generated";

const ManufacturerEdit:FC = () => {
  const [form] = Form.useForm<{ name: string, categoryIDs: string[], active: boolean }>()
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetManufacturerEditQuery({ variables: { id } })

  const [editHandler, { loading: editManufacturer }] = useEditManufacturerMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Категория успешно обновлена!');
    },
    onError: () => {
      toast.error('При обновлении категории произошла ошибка!')
    }
  })

  const handleSubmit = () => form.validateFields().then((values) =>  editHandler({ variables: { ...values, id }}));

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getManufacturerById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editManufacturer}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: data?.getManufacturerById?.name || '',
          active: data?.getManufacturerById?.active || false,
          categoryIDs: data?.getManufacturerById?.categories.map(item => item.id)
        }}
      >
        <Form.Item
          name="name"
          label="Введите название производителя"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          name="categoryIDs"
          label="Выберите категорию"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <CategorySelect
            mode="multiple"
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
            defaultValue={data?.getManufacturerById?.categories.map(item => item.id)}
          />
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default ManufacturerEdit;
