import React, {FC} from "react";
import { toast } from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";
import {useCreateModelMutation} from "./__generated__/ModelAdd.mutation.generated";
import useDrawer from "../../../hooks/useDrawer";
import {Form, Input} from "antd";



const ModelAdd:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading, error, data }] = useCreateModelMutation({
    onCompleted: () => {
      closeRootHandler();
      form.resetFields();
      toast.success('Модель успешно добавлена!');
    },
    onError: () => {
      toast.error('При добавлении модели произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar
      isLoading={loading}
      onSubmit={handleSubmit}
      onClose={closeRootHandler}
    >
        <Form layout="vertical" hideRequiredMark form={form}>
          <Form.Item
            name="name"
            label="Введите название модели"
            rules={[{ required: true, message: 'Заполните поле' }]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="categoryID"
            label="Выберите категорию"
            rules={[{ required: true, message: 'Заполните поле' }]}
          >
            <CategorySelect
              showSearch={true}
              allowClear={true}
              placeholder="Выберите категорию"
            />
          </Form.Item>

          <Form.Item
            name="manufacturerID"
            label="Выберите производителя"
            rules={[{ required: true, message: 'Заполните поле' }]}
          >
            <ManufacturerSelect
              showSearch={true}
              allowClear={true}
              placeholder="Выберите производителя"
            />
          </Form.Item>
        </Form>
    </Contentbar>
  )
}

export default ModelAdd;
