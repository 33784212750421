import React from "react";
import {toast} from "react-toastify";
import Text from "antd/es/typography/Text";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Checkbox, Form, Input, Space} from "antd";
import {useCreatePointMutation} from "./__generated__/PointAdd.mutation.generated";
import {PlusOutlined, MinusCircleOutlined} from "@ant-design/icons";

const staticPhoneValue = [{
    phone: '',
    type: ''
}];

const staticEmailValue = [{
    email: '',
    type: ''
}];

const PointAdd = () => {
    const [form] = Form.useForm<{
        n: string
        e: string
        name: string,
        active: boolean
        address: string,
        emails: [{
            email: string,
            type: string
        }],
        phones: [{
            phone: string,
            type: string
        }]
    }>();

    const { closeRootHandler } = useDrawer();

    const [createHandler, { loading }] = useCreatePointMutation({
        onCompleted: () => {
            form.resetFields();
            closeRootHandler();
            toast.success('Филиал успешно добавлен!');
        },
        onError: () => {
            toast.error('При добавлении филиала произошла ошибка!')
        }
    });

    const handleSubmit = () => {
        form.validateFields().then((values) => createHandler({ variables: { inputs: { ...values } }}));
    };


    return (
      <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
          <Form layout="vertical" hideRequiredMark form={form}>
              <Form.Item
                name="name"
                label="Введите название города"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                  <Input allowClear />
              </Form.Item>
              <Form.Item
                name="address"
                label="Введите адрес"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                  <Input.TextArea showCount maxLength={1500} />
              </Form.Item>
              <Form.Item
                name="n"
                label="Координаты на карте (n)"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                  <Input allowClear />
              </Form.Item>
              <Form.Item
                name="e"
                label="Координаты на карте (e)"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                  <Input allowClear />
              </Form.Item>
              <Text style={{ marginBottom: 8, display: 'block' }}>Номера телефонов</Text>
              <Form.List name="phones" initialValue={staticPhoneValue}>
                  {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            align="baseline"
                            style={{ display: "flex" }}
                          >
                              <Form.Item
                                {...field}
                                name={[field.name, "phone"]}
                                rules={[{ required: true, message: 'Заполните поле' }]}
                              >
                                  <Input placeholder="Номер телефона" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "type"]}
                              >
                                  <Input placeholder="Тип номера" />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Space>
                        ))}
                        <Form.Item style={{ marginTop: 0 }}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                               Добавить номер
                            </Button>
                        </Form.Item>
                    </>
                  )}
              </Form.List>
              <Text style={{ marginBottom: .8, display: 'block' }}>Email адреса</Text>
              <Form.List name="emails" initialValue={staticEmailValue}>
                  {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            style={{ display: "flex" }}
                            align="baseline"
                          >
                              <Form.Item
                                {...field}
                                name={[field.name, "email"]}
                                rules={[{ required: true, message: 'Заполните поле' }]}
                              >
                                  <Input placeholder="Email" />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                name={[field.name, "type"]}
                              >
                                  <Input placeholder="Тип email" />
                              </Form.Item>
                              <MinusCircleOutlined onClick={() => remove(field.name)} />
                          </Space>
                        ))}
                        <Form.Item style={{ marginTop: 0 }}>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                                Добавить email
                            </Button>
                        </Form.Item>
                    </>
                  )}
              </Form.List>

              <Form.Item name="active" valuePropName="checked">
                  <Checkbox>Показывать на сайте?</Checkbox>
              </Form.Item>
          </Form>
      </Contentbar>
    )
}

export default PointAdd;
