import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateManufacturerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  categoryIDs: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateManufacturerMutation = { __typename?: 'Mutation', createManufacturer?: { __typename?: 'Manufacturer', id: string, name: string, active?: boolean | null } | null };


export const CreateManufacturerDocument = gql`
    mutation createManufacturer($name: String!, $categoryIDs: [ID!]!, $active: Boolean) {
  createManufacturer(
    inputs: {name: $name, active: $active, categoryIDs: $categoryIDs}
  ) {
    id
    name
    active
  }
}
    `;
export type CreateManufacturerMutationFn = Apollo.MutationFunction<CreateManufacturerMutation, CreateManufacturerMutationVariables>;

/**
 * __useCreateManufacturerMutation__
 *
 * To run a mutation, you first call `useCreateManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManufacturerMutation, { data, loading, error }] = useCreateManufacturerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      categoryIDs: // value for 'categoryIDs'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateManufacturerMutation(baseOptions?: Apollo.MutationHookOptions<CreateManufacturerMutation, CreateManufacturerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManufacturerMutation, CreateManufacturerMutationVariables>(CreateManufacturerDocument, options);
      }
export type CreateManufacturerMutationHookResult = ReturnType<typeof useCreateManufacturerMutation>;
export type CreateManufacturerMutationResult = Apollo.MutationResult<CreateManufacturerMutation>;
export type CreateManufacturerMutationOptions = Apollo.BaseMutationOptions<CreateManufacturerMutation, CreateManufacturerMutationVariables>;