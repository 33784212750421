import React, {FC} from "react";
import {useForm} from "react-hook-form";
import {ILogin} from "../../interface";
import {AuthSchema} from "./AuthSchema";
import {useNavigate} from "react-router-dom";
import {FormInput, Button, Form} from "../../ui";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import { toast } from 'react-toastify';
import {useLoginMutation} from "./__generated__/AuthForm.generated";
import {isLoggedInVar} from "../../cache";


const AuthForm: FC = () => {
  const navigate = useNavigate();

  const [loginHandler, { loading }] = useLoginMutation({
    onError: (error) => {
      toast.error(error.message);
    },
    onCompleted: (data) => {
      if (data.userLogin && data.userLogin.roles === 'admin') {
        isLoggedInVar(true);
        toast.success('Вход выполнен!');
        localStorage.setItem('accessToken', data?.userLogin?.accessToken as string);
        localStorage.setItem('refreshToken', data?.userLogin?.refreshToken as string);
      } else {
        toast.error('Произошла ошибка');
      }
    }
  })

  const { register, handleSubmit, formState: { errors, isValid } } = useForm<ILogin>({
    mode: "onChange",
    resolver: yupResolver(AuthSchema)
  });

  const onSubmit = handleSubmit((data) => {
    loginHandler({ variables: data }).then((response) => {
      if (response.data?.userLogin?.refreshToken) {
        navigate('/ads');
      }
    })
  });

  return (
    <Form
      loading={false}
      onSubmit={onSubmit}
      title="Добро пожаловать"
      description="Для продолжения введите ваши данные"
    >
      <FormInput
        label="Email"
        type="email"
        name="email"
        id="emailAuth"
        register={register}
        placeholder="Введите email"
        error={errors.email?.message}
      />
      <FormInput
        type="password"
        label="Пароль"
        name="password"
        id="passwordAuth"
        register={register}
        placeholder="Введите пароль"
        error={errors.password?.message}
      />
      <Button type="submit" disabled={!isValid} loading={loading}>
        Войти в кабинет
      </Button>
    </Form>
  )
}

export default AuthForm;
