import {useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Table, Input, Tag, Button, Row, Col, Space} from 'antd';
import { useGetCategoriesQuery } from "./__generated__/CategoryList.query.generated";
import {CategoryListFragment} from "./__generated__/CategoryList.fragment.generated";

const { Search } = Input;



const CategoryLists = ({  }) => {
  const { openRootHandler } = useDrawer();
  const [search, setSearch] = useState('');

  const { loading, data } = useGetCategoriesQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('categoryAdd');

  const columns: ColumnsType<CategoryListFragment> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={row.active ? "green" : "volcano"}>
            { row.active ? "Активно" : "Скрыто" }
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('categorySettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={data?.getCategories}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={openDrawer}>Добавить категорию</Button>
          </Col>
          <Col span={8} offset={8}>
            <Search placeholder="Введите название категории" onChange={(e) => setSearch(e.target.value)}/>
          </Col>
        </Row>
      )}
    />
  )
}

export default CategoryLists;
