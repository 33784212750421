import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type OrderInformListFragment = { __typename?: 'Order', code: string, ads: { __typename?: 'Ads', device: { __typename?: 'AdsDevice', title: string } } };

export const OrderInformListFragmentDoc = gql`
    fragment OrderInformList on Order {
  code
  ads {
    device {
      title
    }
  }
}
    `;