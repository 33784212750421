import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeAdStatusMutationVariables = Types.Exact<{
  statusID: Types.Scalars['ID'];
  adsID: Types.Scalars['ID'];
}>;


export type ChangeAdStatusMutation = { __typename?: 'Mutation', changeAdStatus?: string | null };


export const ChangeAdStatusDocument = gql`
    mutation changeAdStatus($statusID: ID!, $adsID: ID!) {
  changeAdStatus(statusID: $statusID, adsID: $adsID)
}
    `;
export type ChangeAdStatusMutationFn = Apollo.MutationFunction<ChangeAdStatusMutation, ChangeAdStatusMutationVariables>;

/**
 * __useChangeAdStatusMutation__
 *
 * To run a mutation, you first call `useChangeAdStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdStatusMutation, { data, loading, error }] = useChangeAdStatusMutation({
 *   variables: {
 *      statusID: // value for 'statusID'
 *      adsID: // value for 'adsID'
 *   },
 * });
 */
export function useChangeAdStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdStatusMutation, ChangeAdStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdStatusMutation, ChangeAdStatusMutationVariables>(ChangeAdStatusDocument, options);
      }
export type ChangeAdStatusMutationHookResult = ReturnType<typeof useChangeAdStatusMutation>;
export type ChangeAdStatusMutationResult = Apollo.MutationResult<ChangeAdStatusMutation>;
export type ChangeAdStatusMutationOptions = Apollo.BaseMutationOptions<ChangeAdStatusMutation, ChangeAdStatusMutationVariables>;