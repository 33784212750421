import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { UserListFragmentDoc } from './UserList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserListQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', _id: string, login?: string | null, firstName?: string | null, lastName?: string | null, secondName?: string | null, phone?: string | null, email?: string | null, createdAt?: string | null, isActivated?: boolean | null }> };


export const GetUserListDocument = gql`
    query getUserList {
  users {
    ...UserList
  }
}
    ${UserListFragmentDoc}`;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserListQuery(baseOptions?: Apollo.QueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
      }
export function useGetUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
        }
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<typeof useGetUserListLazyQuery>;
export type GetUserListQueryResult = Apollo.QueryResult<GetUserListQuery, GetUserListQueryVariables>;