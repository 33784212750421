import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { StatusListFragmentDoc } from '../../StatusList/__generated__/StatusList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStatusEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetStatusEditQuery = { __typename?: 'Query', getStatusById: { __typename?: 'Status', id: string, title: string, type: Types.StatusType } };


export const GetStatusEditDocument = gql`
    query getStatusEdit($id: ID!) {
  getStatusById(id: $id) {
    ...StatusList
  }
}
    ${StatusListFragmentDoc}`;

/**
 * __useGetStatusEditQuery__
 *
 * To run a query within a React component, call `useGetStatusEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStatusEditQuery(baseOptions: Apollo.QueryHookOptions<GetStatusEditQuery, GetStatusEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusEditQuery, GetStatusEditQueryVariables>(GetStatusEditDocument, options);
      }
export function useGetStatusEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusEditQuery, GetStatusEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusEditQuery, GetStatusEditQueryVariables>(GetStatusEditDocument, options);
        }
export type GetStatusEditQueryHookResult = ReturnType<typeof useGetStatusEditQuery>;
export type GetStatusEditLazyQueryHookResult = ReturnType<typeof useGetStatusEditLazyQuery>;
export type GetStatusEditQueryResult = Apollo.QueryResult<GetStatusEditQuery, GetStatusEditQueryVariables>;