import React, {FC} from "react";
import Moment from 'react-moment';
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Input, Row, Space, Table, Tag} from "antd";
import {useGetUserListQuery} from "./__generated__/UserList.query.generated";
import {UserListFragment} from "./__generated__/UserList.fragment.generated";

const { Search } = Input;

const UserList:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading } = useGetUserListQuery({ pollInterval: 1500 });


  const columns: ColumnsType<UserListFragment> = [
    {
      title: 'Логин',
      dataIndex: 'login',
      key: 'login',
      render: (_, row) => (
        <b>{row.login}</b>
      ),
    },
    {
      title: 'ФИО',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, row) => (
        <>
          {row.firstName} {row.lastName}
        </>
      )
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, row) => (
        <>
          {row.createdAt && <Moment format="DD.MM.YYYY">{row.createdAt}</Moment> }
        </>
      )
    },
    {
      title: 'Статус активации',
      key: 'isActivated',
      render: (_, row) => (
        <>
          <Tag color={row.isActivated ? "green" : "volcano"}>
            { row.isActivated ? "Активирован" : "Ожидает активацию"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('userSettings', row._id)}>Настройки</a>
        </Space>
      )
    }
  ];

  const openDrawer = () => openRootHandler('userCreate');

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      indentSize={50}
      dataSource={data?.users}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить пользователя</Button>
          </Col>
          <Col span={18} offset={2}>
            <Row justify="space-between">
              <Col span={7}>

              </Col>
              <Col span={7}>

              </Col>
              <Col span={7}>
                <Search style={{ width: '100%' }} placeholder="Введите название модели" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default UserList;
