import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditPublicServicesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  title: Types.Scalars['String'];
  desc: Types.Scalars['String'];
  subtitle: Types.Scalars['String'];
}>;


export type EditPublicServicesMutation = { __typename?: 'Mutation', editServiceCategory: { __typename?: 'ServicesCategory', id: string, desc: string, title: string, subtitle: string } };


export const EditPublicServicesDocument = gql`
    mutation editPublicServices($id: ID!, $title: String!, $desc: String!, $subtitle: String!) {
  editServiceCategory(
    id: $id
    inputs: {desc: $desc, title: $title, subtitle: $subtitle}
  ) {
    id
    desc
    title
    subtitle
  }
}
    `;
export type EditPublicServicesMutationFn = Apollo.MutationFunction<EditPublicServicesMutation, EditPublicServicesMutationVariables>;

/**
 * __useEditPublicServicesMutation__
 *
 * To run a mutation, you first call `useEditPublicServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPublicServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPublicServicesMutation, { data, loading, error }] = useEditPublicServicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      desc: // value for 'desc'
 *      subtitle: // value for 'subtitle'
 *   },
 * });
 */
export function useEditPublicServicesMutation(baseOptions?: Apollo.MutationHookOptions<EditPublicServicesMutation, EditPublicServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPublicServicesMutation, EditPublicServicesMutationVariables>(EditPublicServicesDocument, options);
      }
export type EditPublicServicesMutationHookResult = ReturnType<typeof useEditPublicServicesMutation>;
export type EditPublicServicesMutationResult = Apollo.MutationResult<EditPublicServicesMutation>;
export type EditPublicServicesMutationOptions = Apollo.BaseMutationOptions<EditPublicServicesMutation, EditPublicServicesMutationVariables>;