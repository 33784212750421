import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { CityListFragmentDoc } from './CityList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCityListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCityListQuery = { __typename?: 'Query', getCities: Array<{ __typename?: 'City', id: string, name: string, latin: string, active?: boolean | null }> };


export const GetCityListDocument = gql`
    query getCityList {
  getCities {
    ...CityList
  }
}
    ${CityListFragmentDoc}`;

/**
 * __useGetCityListQuery__
 *
 * To run a query within a React component, call `useGetCityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCityListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCityListQuery(baseOptions?: Apollo.QueryHookOptions<GetCityListQuery, GetCityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCityListQuery, GetCityListQueryVariables>(GetCityListDocument, options);
      }
export function useGetCityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCityListQuery, GetCityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCityListQuery, GetCityListQueryVariables>(GetCityListDocument, options);
        }
export type GetCityListQueryHookResult = ReturnType<typeof useGetCityListQuery>;
export type GetCityListLazyQueryHookResult = ReturnType<typeof useGetCityListLazyQuery>;
export type GetCityListQueryResult = Apollo.QueryResult<GetCityListQuery, GetCityListQueryVariables>;