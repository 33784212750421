import {FC} from "react";
import styled from "styled-components";
import {PublicServicesListFragment} from "../PublicServicesList/__generated__/PublicServicesList.fragment.generated";


const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;


interface ServicesInformProps {
  info: PublicServicesListFragment
}

const PublicServicesInform:FC<ServicesInformProps> = ({ info }) => {
  return (
      <Container>
        <p>Информация о категории:</p>
        <h2>{info.title}</h2>
        <p>{info.subtitle}</p>
      </Container>
  )
}

export default PublicServicesInform;
