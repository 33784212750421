import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PublicServicesListFragmentDoc } from '../../PublicServicesList/__generated__/PublicServicesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPublicServiceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPublicServiceByIdQuery = { __typename?: 'Query', getServicesCategoryById: { __typename?: 'ServicesCategory', id: string, desc: string, title: string, subtitle: string } };


export const GetPublicServiceByIdDocument = gql`
    query getPublicServiceById($id: ID!) {
  getServicesCategoryById(id: $id) {
    ...PublicServicesList
  }
}
    ${PublicServicesListFragmentDoc}`;

/**
 * __useGetPublicServiceByIdQuery__
 *
 * To run a query within a React component, call `useGetPublicServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicServiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicServiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPublicServiceByIdQuery, GetPublicServiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicServiceByIdQuery, GetPublicServiceByIdQueryVariables>(GetPublicServiceByIdDocument, options);
      }
export function useGetPublicServiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicServiceByIdQuery, GetPublicServiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicServiceByIdQuery, GetPublicServiceByIdQueryVariables>(GetPublicServiceByIdDocument, options);
        }
export type GetPublicServiceByIdQueryHookResult = ReturnType<typeof useGetPublicServiceByIdQuery>;
export type GetPublicServiceByIdLazyQueryHookResult = ReturnType<typeof useGetPublicServiceByIdLazyQuery>;
export type GetPublicServiceByIdQueryResult = Apollo.QueryResult<GetPublicServiceByIdQuery, GetPublicServiceByIdQueryVariables>;