import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAdStatusQuery = { __typename?: 'Query', getAdById: { __typename?: 'Ads', id: string, status: { __typename?: 'Status', id: string, title: string } } };


export const GetAdStatusDocument = gql`
    query getAdStatus($id: ID!) {
  getAdById(id: $id) {
    id
    status {
      id
      title
    }
  }
}
    `;

/**
 * __useGetAdStatusQuery__
 *
 * To run a query within a React component, call `useGetAdStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdStatusQuery(baseOptions: Apollo.QueryHookOptions<GetAdStatusQuery, GetAdStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdStatusQuery, GetAdStatusQueryVariables>(GetAdStatusDocument, options);
      }
export function useGetAdStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdStatusQuery, GetAdStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdStatusQuery, GetAdStatusQueryVariables>(GetAdStatusDocument, options);
        }
export type GetAdStatusQueryHookResult = ReturnType<typeof useGetAdStatusQuery>;
export type GetAdStatusLazyQueryHookResult = ReturnType<typeof useGetAdStatusLazyQuery>;
export type GetAdStatusQueryResult = Apollo.QueryResult<GetAdStatusQuery, GetAdStatusQueryVariables>;