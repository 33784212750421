import {FC} from "react";
import {Select} from "antd";
import {useGetSelectCitiesQuery} from "./__generated__/citySelect.query.generated";

const CitySelect:FC<CitySelectProps> = ({ defaultValue, onChange, size = "middle", showSearch = true, placeholder, allowClear = false }) => {
  const { data, loading } = useGetSelectCitiesQuery();

  return (
    <Select
      size={size}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      placeholder={placeholder}
      showSearch={showSearch}
      defaultValue={defaultValue}
      style={{ width: '100%' }}
      optionFilterProp="children"
    >
      {data?.getCities.map((city) => (
        <Select.Option
          key={city.id}
          value={city.id}
        >
          {city.name}
        </Select.Option>
      ))}
    </Select>
  )
}

interface CitySelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}

export default CitySelect;
