import React, {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import { AdsStatus } from "../../containers/Ads";
import { UserAdd, UserDelete, UserEdit, UserList, UserRoot } from "../../containers/User";

const UsersPage:FC = () => {
  return (
    <>
      <UserList />

      <Sidebar
        width={550}
        name="userCreate"
        rootDrawerElement={() => <UserAdd />}
      />

      <Sidebar
        name="userSettings"
        rootDrawerElement={() => <UserRoot /> }
        childrenDrawerElements={[
          { name: 'userSettingsDelete', element: () => <UserDelete /> },
          { name: 'userSettingsEdit', element: () => <UserEdit /> },
          { name: 'adsSettingsStatus', element: () => <AdsStatus /> },
        ]}
      />

    </>
  )
}

export default UsersPage;
