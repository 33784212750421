import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ManufacturerListFragmentDoc } from './ManufacturerList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetManufacturerListQueryVariables = Types.Exact<{
  categoryIDs?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetManufacturerListQuery = { __typename?: 'Query', getManufacturers: Array<{ __typename?: 'Manufacturer', id: string, url?: string | null, name: string, active?: boolean | null, categories: Array<{ __typename?: 'Category', id: string, name: string, active?: boolean | null }> }> };


export const GetManufacturerListDocument = gql`
    query getManufacturerList($categoryIDs: [String]) {
  getManufacturers(categoryIDs: $categoryIDs) {
    ...ManufacturerList
  }
}
    ${ManufacturerListFragmentDoc}`;

/**
 * __useGetManufacturerListQuery__
 *
 * To run a query within a React component, call `useGetManufacturerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturerListQuery({
 *   variables: {
 *      categoryIDs: // value for 'categoryIDs'
 *   },
 * });
 */
export function useGetManufacturerListQuery(baseOptions?: Apollo.QueryHookOptions<GetManufacturerListQuery, GetManufacturerListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManufacturerListQuery, GetManufacturerListQueryVariables>(GetManufacturerListDocument, options);
      }
export function useGetManufacturerListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturerListQuery, GetManufacturerListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManufacturerListQuery, GetManufacturerListQueryVariables>(GetManufacturerListDocument, options);
        }
export type GetManufacturerListQueryHookResult = ReturnType<typeof useGetManufacturerListQuery>;
export type GetManufacturerListLazyQueryHookResult = ReturnType<typeof useGetManufacturerListLazyQuery>;
export type GetManufacturerListQueryResult = Apollo.QueryResult<GetManufacturerListQuery, GetManufacturerListQueryVariables>;