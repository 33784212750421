import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type AdsDrawerInfoFragment = { __typename?: 'Ads', code: string, device: { __typename?: 'AdsDevice', category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, model: { __typename?: 'Models', id: string, name: string } } };

export const AdsDrawerInfoFragmentDoc = gql`
    fragment AdsDrawerInfo on Ads {
  code
  device {
    category {
      id
      name
    }
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
  }
}
    `;