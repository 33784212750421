import React, {FC} from "react";
import {Form, Input} from "antd";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useCreatePublicServiceMutation} from "./__generated__/PublicServicesAdd.mutation.generated";


const PublicServicesAdd:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useCreatePublicServiceMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Сервис усешно добавлен!');
    },
    onError: () => {
      toast.error('При добавлении сервиса произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="title"
          label="Введите название сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="subtitle"
          label="Введите подзаголовок сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Введите описание сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default PublicServicesAdd;
