import React, {useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Input, Row, Select, Space, Table, Tag} from "antd";
import {useGetModelsQuery} from "./__generated__/ModelsList.query.generated";
import {ModelsListFragment} from "./__generated__/ModelsList.fragment.generated";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";

const { Search } = Input;


const ModelsList = ({  }) => {
  const { openRootHandler } = useDrawer();
  const [category, setCategory] = useState('');
  const [manufacturer, setManufacturer] = useState('');

  const { data, loading } = useGetModelsQuery({
    variables: {
      manufacturerIDs: manufacturer ? [manufacturer] : [],
      categoryIDs: category? [category] : []
    },
    pollInterval: 1500
  });

  const openDrawer = () => openRootHandler('modelsAdd');

  const columns: ColumnsType<ModelsListFragment> = [
    {
      title: 'Модель',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Производитель',
      dataIndex: ["manufacturers", "name"],
      key: 'manufacturers'
    },
    {
      title: 'Категория',
      dataIndex: ["categories", "name"],
      key: 'categories'
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Статус',
      key: 'active',
      render: (active: boolean) => (
        <>
          <Tag color={active ? "green" : "volcano"}>
            { active ? "Активно" : "Скрыто"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('modelsSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      indentSize={50}
      dataSource={data?.getModels}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить модель</Button>
          </Col>
          <Col span={18} offset={2}>
            <Row justify="space-between">
              <Col span={7}>
                <CategorySelect
                  showSearch={true}
                  allowClear={true}
                  placeholder="Выберите категорию"
                  onChange={value => setCategory(value.toString())}
                />
              </Col>
              <Col span={7}>
                <ManufacturerSelect
                  showSearch={true}
                  allowClear={true}
                  placeholder="Выберите производителя"
                  onChange={value => setManufacturer(value)}
                />
              </Col>
              <Col span={7}>
                <Search style={{ width: '100%' }} placeholder="Введите название модели" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default ModelsList;
