import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type OrderListFragment = { __typename?: 'Order', _id: string, code: string, subject: string, status: { __typename?: 'OrderStatus', id: string, title: string, type: Types.OrderStatusType }, ads: { __typename?: 'Ads', device: { __typename?: 'AdsDevice', title: string, condition: { __typename?: 'Condition', id: string, name?: string | null }, category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } }, user: { __typename?: 'User', phone?: string | null, lastName?: string | null, firstName?: string | null }, addedServices: Array<{ __typename?: 'Services', id: string, price: number, title: string, category: { __typename?: 'ServicesCategory', title: string } } | null> }, services: Array<{ __typename?: 'Services', id: string, price: number, title: string } | null>, accessories: Array<{ __typename?: 'Accessories', id: string, title: string, subtitle: string, price: number } | null>, person: { __typename?: 'OrderPersonal', phone?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null, city?: { __typename?: 'City', id: string, name: string } | null, personal?: { __typename?: 'AdsPersonalPerson', type: Types.PersonType, bin: string, address: string, company: string } | null }, date: { __typename?: 'OrderDate', createdAt?: string | null, finishedAt?: string | null } };

export const OrderListFragmentDoc = gql`
    fragment OrderList on Order {
  _id
  code
  subject
  status {
    id
    title
    type
  }
  ads {
    device {
      title
      condition {
        id
        name
      }
      category {
        id
        name
      }
      manufacturer {
        id
        name
      }
      price {
        current
        auction
      }
    }
    user {
      phone
      lastName
      firstName
    }
    addedServices {
      id
      price
      title
      category {
        title
      }
    }
  }
  services {
    id
    price
    title
  }
  accessories {
    id
    title
    subtitle
    price
  }
  person {
    phone
    email
    firstName
    lastName
    city {
      id
      name
    }
    personal {
      type
      bin
      address
      company
    }
  }
  date {
    createdAt
    finishedAt
  }
}
    `;