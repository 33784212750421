import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PointItemFragmentDoc } from '../../PointList/__generated__/PointList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEditPointQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetEditPointQuery = { __typename?: 'Query', getPointById?: { __typename?: 'Point', id: string, e: string, n: string, name: string, active?: boolean | null, address: string, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null>, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null> } | null };


export const GetEditPointDocument = gql`
    query getEditPoint($id: ID!) {
  getPointById(id: $id) {
    ...PointItem
  }
}
    ${PointItemFragmentDoc}`;

/**
 * __useGetEditPointQuery__
 *
 * To run a query within a React component, call `useGetEditPointQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditPointQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditPointQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditPointQuery(baseOptions: Apollo.QueryHookOptions<GetEditPointQuery, GetEditPointQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditPointQuery, GetEditPointQueryVariables>(GetEditPointDocument, options);
      }
export function useGetEditPointLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditPointQuery, GetEditPointQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditPointQuery, GetEditPointQueryVariables>(GetEditPointDocument, options);
        }
export type GetEditPointQueryHookResult = ReturnType<typeof useGetEditPointQuery>;
export type GetEditPointLazyQueryHookResult = ReturnType<typeof useGetEditPointLazyQuery>;
export type GetEditPointQueryResult = Apollo.QueryResult<GetEditPointQuery, GetEditPointQueryVariables>;