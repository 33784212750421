import {FC} from "react";
import styled from "styled-components";
import {UserInformFragment} from "./__generated__/UserInform.fragment.generated";

const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;

interface UserInformProps {
  info: UserInformFragment;
}

const UserInform:FC<UserInformProps> = ({ info }) => {
  return (
    <Container>
      <p>Информация о пользователе:</p>
      <h2>{info.firstName}</h2>
      <p>{info.lastName}</p>
    </Container>
  )
}

export default UserInform;
