import React from "react";
import { Outlet, Navigate } from "react-router-dom";


interface RouteProps {
  to: string;
}

const RedirectRoute = ({ to } : RouteProps) => {
  const isAuth = localStorage.getItem('accessToken');

  return isAuth ? <Navigate to={to} /> : <Outlet />;
}

export default RedirectRoute;
