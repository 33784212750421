import {FC, useState} from "react";
import HeaderSidebar from "../HeaderSidebar/HeaderSidebar";
import HeaderNavigation from "../HeaderNavigation/HeaderNavigation";

interface HeaderProps {
  title?: string;
}

const Header:FC<HeaderProps> = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleHandler = () => setIsOpen(!isOpen);

  return (
      <>
        <HeaderNavigation
          title={title}
          isOpen={isOpen}
          toggleHandler={toggleHandler}
        />

        <HeaderSidebar
          isOpen={isOpen}
          toggleHandler={toggleHandler}
        />
      </>
  )
}

export default Header;
