import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { OrderInformListFragmentDoc } from '../../OrderInform/__generated__/OrderInform.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOrderByIdQuery = { __typename?: 'Query', getOrderById?: { __typename?: 'Order', code: string, ads: { __typename?: 'Ads', device: { __typename?: 'AdsDevice', title: string } } } | null };


export const GetOrderByIdDocument = gql`
    query getOrderById($id: ID!) {
  getOrderById(id: $id) {
    ...OrderInformList
  }
}
    ${OrderInformListFragmentDoc}`;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;