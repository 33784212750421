import {FC} from "react";
import {Select} from "antd";
import {useGetAccessoriesSelectQuery} from "./__generated__/AccessoriesSelect.query.generated";

const AccessoriesSelect:FC<AccessoriesSelectProps> = ({ mode = "default", defaultValue, onChange, size = "middle", showSearch = true, allowClear = false, placeholder }) => {
  const {loading, data} = useGetAccessoriesSelectQuery();

  const type = mode === 'default' ? { onChange: onChange,  defaultValue: defaultValue } : { mode: "multiple", defaultValue: defaultValue } as const;

  return (
      <Select
        {...type}
        size={size}
        loading={loading}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: '100%' }}
        optionFilterProp="children"
      >
        {data?.getAccessories.map((service) => (
          <Select.Option
            key={service.id}
            value={service.id}
          >
            {service.title} - {service.category.name} - {service.price} ₸
          </Select.Option>
        ))}
      </Select>
  )
}

interface AccessoriesSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  defaultValue?: string | string[];
  size?: 'large' | 'middle' | 'small';
  onChange?: (value: string | string[]) => void;
}


export default AccessoriesSelect;
