import React, {FC} from "react";
import {Form, Input} from "antd";
import { toast } from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useCreateCategoryMutation} from "./__generated__/CategoryAdd.mutation.generated";



const CategoryAdd = ({  }) => {
  const [form] = Form.useForm<{ name: string }>();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useCreateCategoryMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Категория усешно добавлена!');
    },
    onError: () => {
      toast.error('При добавлении категории произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="name"
          label="Введите название категории"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default CategoryAdd;
