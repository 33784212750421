import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectManufacturersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectManufacturersQuery = { __typename?: 'Query', getManufacturers: Array<{ __typename?: 'Manufacturer', id: string, name: string, url?: string | null }> };


export const GetSelectManufacturersDocument = gql`
    query getSelectManufacturers {
  getManufacturers {
    id
    name
    url
  }
}
    `;

/**
 * __useGetSelectManufacturersQuery__
 *
 * To run a query within a React component, call `useGetSelectManufacturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectManufacturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectManufacturersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectManufacturersQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectManufacturersQuery, GetSelectManufacturersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectManufacturersQuery, GetSelectManufacturersQueryVariables>(GetSelectManufacturersDocument, options);
      }
export function useGetSelectManufacturersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectManufacturersQuery, GetSelectManufacturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectManufacturersQuery, GetSelectManufacturersQueryVariables>(GetSelectManufacturersDocument, options);
        }
export type GetSelectManufacturersQueryHookResult = ReturnType<typeof useGetSelectManufacturersQuery>;
export type GetSelectManufacturersLazyQueryHookResult = ReturnType<typeof useGetSelectManufacturersLazyQuery>;
export type GetSelectManufacturersQueryResult = Apollo.QueryResult<GetSelectManufacturersQuery, GetSelectManufacturersQueryVariables>;