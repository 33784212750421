import {Input, Label, Group} from "../";
import styled from "styled-components";
import { InputProps } from "../Input/Input";
import { Path, RegisterOptions, UseFormRegister } from "react-hook-form";


const Wrapper = styled.div`
  position: relative;
`;

const Error = styled.span`
  color: red;
  display: block;
  font-size: 1.2rem;
  margin-top: .5em;
`;


export interface FormInputProps<T> extends Omit<InputProps, 'name'> {
  error?: string;
  label: string;
  name: Path<T>;
  rules?: RegisterOptions;
  register?: UseFormRegister<T>;
}


export const FormInput = <T extends Record<string, any>>({
   id,
   name,
   error,
   disabled,
   register,
   label,
   rules,
   ...props
}: FormInputProps<T>): JSX.Element => {

  return (
    <Group>
      { label &&
          <Label htmlFor={id} disabled={disabled}>{label}</Label>
      }
      <Wrapper>
        <Input
          id={id}
          name={name}
          disabled={disabled}
          isError={!!error}
          {...props}
          {...(register && register(name, rules))}
        />
      </Wrapper>
      { error &&
          <Error>{error}</Error>
      }
    </Group>
  );
};

export default FormInput;
