import {FC} from "react";
import {Select, Form } from "antd";
import {useGetSelectModelQuery} from "./__generated__/ModelSelect.query.generated";


const ModelSelect: FC<CategorySelectProps> = ({ defaultValue, onChange, size = "middle", showSearch = true, allowClear = false, placeholder }) => {
  const { data, loading } = useGetSelectModelQuery();

  return (
      <Select
        size={size}
        loading={loading}
        onChange={onChange}
        allowClear={allowClear}
        placeholder={placeholder}
        showSearch={showSearch}
        defaultValue={defaultValue}
        style={{width: '100%'}}
        optionFilterProp="children"
      >
        {data?.getModels.map((model) => (
          <Select.Option
            key={model.id}
            value={model.id}
          >
            {model.name}
          </Select.Option>
        ))}
      </Select>
  )
}

interface CategorySelectProps {
  showSearch?: boolean;
  allowClear?: boolean;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}

export default ModelSelect;
