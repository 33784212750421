import {FC} from "react";
import {Select} from "antd";
import {useGetSelectUserQuery} from "./__generated__/UserSelect.query.generated";

const UserSelect:FC<UserSelectProps> = ({ onChange, size = "middle", showSearch = true, allowClear = false, placeholder, defaultValue }) => {
  const {data, loading} = useGetSelectUserQuery();

  return (
      <Select
        size={size}
        loading={loading}
        onChange={onChange}
        allowClear={allowClear}
        placeholder={placeholder}
        defaultValue={defaultValue}
        showSearch={showSearch}
        style={{width: '100%'}}
        optionFilterProp="children"
      >
        {data?.users.map((user) => (
          <Select.Option
            key={user._id}
            value={user._id}
          >
            {user.firstName} {user.lastName}
          </Select.Option>
        ))}
      </Select>
  )
}

interface UserSelectProps {
  showSearch?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}

export default UserSelect;
