import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { AdsItemFragmentDoc } from '../../AdsItem/__generated__/AdsItem.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdEditInfoQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAdEditInfoQuery = { __typename?: 'Query', getAdById: { __typename?: 'Ads', id: string, code: string, selectGeokurs: Array<string>, status: { __typename?: 'Status', id: string, type: Types.StatusType, title: string }, device: { __typename?: 'AdsDevice', title: string, description: string, category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, model: { __typename?: 'Models', id: string, name: string }, condition: { __typename?: 'Condition', id: string, name?: string | null }, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } }, addedServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, selectServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, desirableServices: Array<{ __typename?: 'ServicesCategory', id: string, title: string, subtitle: string } | null>, accessories: Array<{ __typename?: 'Accessories', id: string, title: string, price: number, category: { __typename?: 'Category', id: string, name: string } } | null>, images: { __typename?: 'AdsImages', jpeg: { __typename?: 'AdsImagesType', small: Array<string | null> } }, labels: Array<{ __typename?: 'Label', id: string, name: string, color: string, active?: boolean | null, description: string }>, user: { __typename?: 'User', _id: string, lastName?: string | null, email?: string | null, phone?: string | null, firstName?: string | null }, manager?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null } };


export const GetAdEditInfoDocument = gql`
    query getAdEditInfo($id: ID!) {
  getAdById(id: $id) {
    ...AdsItem
  }
}
    ${AdsItemFragmentDoc}`;

/**
 * __useGetAdEditInfoQuery__
 *
 * To run a query within a React component, call `useGetAdEditInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdEditInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdEditInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdEditInfoQuery(baseOptions: Apollo.QueryHookOptions<GetAdEditInfoQuery, GetAdEditInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdEditInfoQuery, GetAdEditInfoQueryVariables>(GetAdEditInfoDocument, options);
      }
export function useGetAdEditInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdEditInfoQuery, GetAdEditInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdEditInfoQuery, GetAdEditInfoQueryVariables>(GetAdEditInfoDocument, options);
        }
export type GetAdEditInfoQueryHookResult = ReturnType<typeof useGetAdEditInfoQuery>;
export type GetAdEditInfoLazyQueryHookResult = ReturnType<typeof useGetAdEditInfoLazyQuery>;
export type GetAdEditInfoQueryResult = Apollo.QueryResult<GetAdEditInfoQuery, GetAdEditInfoQueryVariables>;