import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModelEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetModelEditQuery = { __typename?: 'Query', getModelById: { __typename?: 'Models', id: string, name: string, categories: { __typename?: 'Category', id: string, name: string }, manufacturers: { __typename?: 'Manufacturer', id: string, name: string } } };


export const GetModelEditDocument = gql`
    query getModelEdit($id: ID!) {
  getModelById(id: $id) {
    id
    name
    categories {
      id
      name
    }
    manufacturers {
      id
      name
    }
  }
}
    `;

/**
 * __useGetModelEditQuery__
 *
 * To run a query within a React component, call `useGetModelEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModelEditQuery(baseOptions: Apollo.QueryHookOptions<GetModelEditQuery, GetModelEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelEditQuery, GetModelEditQueryVariables>(GetModelEditDocument, options);
      }
export function useGetModelEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelEditQuery, GetModelEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelEditQuery, GetModelEditQueryVariables>(GetModelEditDocument, options);
        }
export type GetModelEditQueryHookResult = ReturnType<typeof useGetModelEditQuery>;
export type GetModelEditLazyQueryHookResult = ReturnType<typeof useGetModelEditLazyQuery>;
export type GetModelEditQueryResult = Apollo.QueryResult<GetModelEditQuery, GetModelEditQueryVariables>;