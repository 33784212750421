import {FC, useState} from "react";
import styled from "styled-components";
import Link from "../../../ui/Link/Link";
import {useAuthenticateQuery} from "../../../routes/ProtectedRoute/__generated__/ProtectedRoute.generated";


const Options = styled.div`
  h3 {
    font-size: 2rem;
    font-weight: 400;
    color: #ac2b6f;
    margin-bottom: 2rem;
  }
`;


interface HeaderSidebarProps {
  isOpen: boolean;
  toggleHandler: () => void;
}


const HeaderSidebar:FC<HeaderSidebarProps> = ({ isOpen, toggleHandler }) => {
  const { data, loading } = useAuthenticateQuery();
  return (
    <>
      {/*<Sidebar*/}
      {/*  isActive={isOpen}*/}
      {/*  isLoading={loading}*/}
      {/*  closeHandler={toggleHandler}*/}
      {/*>*/}
      {/*  <Options>*/}
      {/*    <h3>Управление</h3>*/}
      {/*    <ul>*/}
      {/*      <Link colorTheme="green">Настройка личных данных</Link>*/}
      {/*      <Link colorTheme="red">Выйти из консоли</Link>*/}
      {/*    </ul>*/}
      {/*  </Options>*/}
      {/*</Sidebar>*/}
    </>
  )
}

export default HeaderSidebar;
