import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModelEditMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
  manufacturerID: Types.Scalars['ID'];
  categoryID: Types.Scalars['ID'];
}>;


export type ModelEditMutation = { __typename?: 'Mutation', editModels?: { __typename?: 'Models', id: string } | null };


export const ModelEditDocument = gql`
    mutation modelEdit($id: ID!, $name: String!, $manufacturerID: ID!, $categoryID: ID!) {
  editModels(
    id: $id
    name: $name
    manufacturerID: $manufacturerID
    categoryID: $categoryID
  ) {
    id
  }
}
    `;
export type ModelEditMutationFn = Apollo.MutationFunction<ModelEditMutation, ModelEditMutationVariables>;

/**
 * __useModelEditMutation__
 *
 * To run a mutation, you first call `useModelEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModelEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modelEditMutation, { data, loading, error }] = useModelEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      manufacturerID: // value for 'manufacturerID'
 *      categoryID: // value for 'categoryID'
 *   },
 * });
 */
export function useModelEditMutation(baseOptions?: Apollo.MutationHookOptions<ModelEditMutation, ModelEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModelEditMutation, ModelEditMutationVariables>(ModelEditDocument, options);
      }
export type ModelEditMutationHookResult = ReturnType<typeof useModelEditMutation>;
export type ModelEditMutationResult = Apollo.MutationResult<ModelEditMutation>;
export type ModelEditMutationOptions = Apollo.BaseMutationOptions<ModelEditMutation, ModelEditMutationVariables>;