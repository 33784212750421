import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import {AccessoriesAdd, AccessoriesList, AccessoriesRoot, AccessoriesEdit, AccessoriesDelete} from "../../containers/Accessories";


const AccessoriesPage:FC = () => {
  return (
    <>
      <AccessoriesList />

      <Sidebar
        name="accessoriesAdd"
        rootDrawerElement={() => <AccessoriesAdd />}
      />

      <Sidebar
        name="accessoriesSettings"
        rootDrawerElement={() => <AccessoriesRoot />}
        childrenDrawerElements={[
          { name: "accessoriesSettingsDelete", element: () => <AccessoriesDelete /> },
          { name: "accessoriesSettingsEdit", element: () => <AccessoriesEdit /> }
        ]}
      />
    </>
  )
}


export default AccessoriesPage;
