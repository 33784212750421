import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import {StatusList, StatusRoot, StatusEdit, StatusDelete} from "../../containers/Status";


const StatusPage:FC = () => {
  return (
    <>
      <StatusList />

      <Sidebar
        name="statusSettings"
        rootDrawerElement={() => <StatusRoot />}
        childrenDrawerElements={[
          { name: "statusSettingsDelete", element: () => <StatusDelete /> },
          { name: "statusSettingsEdit", element: () => <StatusEdit /> }
        ]}
      />
    </>
  )
}


export default StatusPage;
