import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ServiceListFragmentDoc } from '../../ServicesList/__generated__/ServicesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditServiceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  title: Types.Scalars['String'];
  desc: Types.Scalars['String'];
  category: Types.Scalars['ID'];
  price: Types.Scalars['Int'];
}>;


export type EditServiceMutation = { __typename?: 'Mutation', editService?: { __typename?: 'Services', id: string, desc: string, title: string, price: number, category: { __typename?: 'ServicesCategory', id: string, title: string } } | null };


export const EditServiceDocument = gql`
    mutation editService($id: ID!, $title: String!, $desc: String!, $category: ID!, $price: Int!) {
  editService(
    id: $id
    inputs: {category: $category, title: $title, desc: $desc, price: $price}
  ) {
    ...ServiceList
  }
}
    ${ServiceListFragmentDoc}`;
export type EditServiceMutationFn = Apollo.MutationFunction<EditServiceMutation, EditServiceMutationVariables>;

/**
 * __useEditServiceMutation__
 *
 * To run a mutation, you first call `useEditServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editServiceMutation, { data, loading, error }] = useEditServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      desc: // value for 'desc'
 *      category: // value for 'category'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useEditServiceMutation(baseOptions?: Apollo.MutationHookOptions<EditServiceMutation, EditServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditServiceMutation, EditServiceMutationVariables>(EditServiceDocument, options);
      }
export type EditServiceMutationHookResult = ReturnType<typeof useEditServiceMutation>;
export type EditServiceMutationResult = Apollo.MutationResult<EditServiceMutation>;
export type EditServiceMutationOptions = Apollo.BaseMutationOptions<EditServiceMutation, EditServiceMutationVariables>;