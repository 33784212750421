import React, {FC} from "react";
import {toast} from "react-toastify";
import {Button, Checkbox, Form, Input, InputNumber, Upload} from "antd";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {UploadOutlined} from "@ant-design/icons";
import Loading from "../../../ui/Loading/Loading";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import {useGetAccessoriesEditByIdQuery} from "./__generated__/AccessoriesEdit.query.generated";
import {useEditAccessoriesMutation} from "./__generated__/AccessoriesEdit.mutation.generated";

const path = process.env.NODE_ENV === 'production' ? 'https://api2.geokurs.kz/uploads/accessories/' : 'http://localhost:5000/uploads/accessories/';

const AccessoriesEdit:FC = () => {
  const [form] = Form.useForm()
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetAccessoriesEditByIdQuery({ variables: { id } })

  const [editHandler, { loading: editManufacturer }] = useEditAccessoriesMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Категория успешно обновлена!');
    },
    onError: () => {
      toast.error('При обновлении категории произошла ошибка!')
    }
  })

  const handleSubmit = () => form.validateFields().then((values) =>  editHandler({ variables: { ...values, id }}));

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getAccessoriesById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editManufacturer}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          title: data?.getAccessoriesById?.title || '',
          subtitle: data?.getAccessoriesById?.subtitle || '',
          price: data?.getAccessoriesById?.price || 0,
          active: data?.getAccessoriesById?.active || false,
          categoryID: data?.getAccessoriesById?.category.id
        }}
      >
        <Form.Item
          name="title"
          label="Введите название акссесуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item
          name="subtitle"
          label="Введите описание аксессуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item
          name="categoryID"
          label="Выберите категорию"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <CategorySelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
            defaultValue={data?.getAccessoriesById?.category.id}
          />
        </Form.Item>

        <Form.Item
          name="price"
          label="Введите стоимость аксессуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <InputNumber addonAfter="₸" style={{ width: '100%' }}/>
        </Form.Item>

        <Form.Item
          name="image"
          label="Изображения продукта"
        >
          <Upload beforeUpload={() => false} listType="picture" multiple={true} defaultFileList={[
            {
              uid: '1',
              status: 'done',
              name: data?.getAccessoriesById?.image,
              url: `${path}${data?.getAccessoriesById?.image}`,
            },
          ]}>
            <Button icon={<UploadOutlined />}>Изменить фотографию</Button>
          </Upload>
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default AccessoriesEdit;
