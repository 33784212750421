import React, {FC} from "react";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {UploadOutlined} from "@ant-design/icons";
import {Button, Checkbox, Form, Input, InputNumber, Upload} from "antd";
import {useCreateAccessoriesMutation} from "./__generated__/AccessoriesAdd.mutation.generated";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";


const AccessoriesAdd:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useCreateAccessoriesMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Акссесуар усешно добавлен!');
    },
    onError: () => {
      toast.error('При добавлении акссесуара произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="title"
          label="Введите название аксессуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="subtitle"
          label="Введите описание аксессуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>
        <Form.Item
          name="categoryID"
          label="Выберите категорию"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <CategorySelect
            allowClear={true}
            showSearch={true}
          />
        </Form.Item>
        <Form.Item
          name="price"
          label="Введите стоимость аксессуара"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <InputNumber addonAfter="₸" style={{ width: '100%' }}/>
        </Form.Item>
        <Form.Item
          name="image"
          label="Изображения продукта"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Upload beforeUpload={() => false} listType="picture" multiple={true}>
            <Button icon={<UploadOutlined />}>Загрузить фотографию</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default AccessoriesAdd;
