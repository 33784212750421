import {FC} from "react";
import styled from "styled-components";
import {ServiceListFragment} from "../ServicesList/__generated__/ServicesList.fragment.generated";


const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;


interface ServicesInformProps {
  info: ServiceListFragment
}

const ServicesInform:FC<ServicesInformProps> = ({ info }) => {
  return (
      <Container>
        <p>Информация о сервисе:</p>
        <h2>{info.title}</h2>
        {/*<p>{info.subtitle}</p>*/}
      </Container>
  )
}

export default ServicesInform;
