import {Link} from "../../../ui";
import styled from "styled-components";
import {LoadingIcon} from "../../../ui/Icons";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import ModelInform from "../ModelInform/ModelInform";
import {useGetModelQuery} from "./__generated__/ModelRoot.query.generated";


const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const ModelRoot = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetModelQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getModelById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <ModelInform
        info={data?.getModelById}
      />
      <Options>
        <h2>Управление моделью</h2>
        <ul>
          <Link colorTheme="green" onClick={() => openChildrenHandler('modelsSettingsEdit')}>Редактировать модель</Link>
          <Link colorTheme="red" onClick={() => openChildrenHandler('modelsSettingsDelete')}>Удалить модель</Link>
        </ul>
      </Options>
    </>
  )
}

export default ModelRoot;
