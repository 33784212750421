import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ServicesCreateMutationVariables = Types.Exact<{
  category: Types.Scalars['ID'];
  price: Types.Scalars['Int'];
  desc: Types.Scalars['String'];
  title: Types.Scalars['String'];
}>;


export type ServicesCreateMutation = { __typename?: 'Mutation', createService?: { __typename?: 'Services', id: string, desc: string, title: string } | null };


export const ServicesCreateDocument = gql`
    mutation servicesCreate($category: ID!, $price: Int!, $desc: String!, $title: String!) {
  createService(
    inputs: {category: $category, title: $title, price: $price, desc: $desc}
  ) {
    id
    desc
    title
  }
}
    `;
export type ServicesCreateMutationFn = Apollo.MutationFunction<ServicesCreateMutation, ServicesCreateMutationVariables>;

/**
 * __useServicesCreateMutation__
 *
 * To run a mutation, you first call `useServicesCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useServicesCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [servicesCreateMutation, { data, loading, error }] = useServicesCreateMutation({
 *   variables: {
 *      category: // value for 'category'
 *      price: // value for 'price'
 *      desc: // value for 'desc'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useServicesCreateMutation(baseOptions?: Apollo.MutationHookOptions<ServicesCreateMutation, ServicesCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ServicesCreateMutation, ServicesCreateMutationVariables>(ServicesCreateDocument, options);
      }
export type ServicesCreateMutationHookResult = ReturnType<typeof useServicesCreateMutation>;
export type ServicesCreateMutationResult = Apollo.MutationResult<ServicesCreateMutation>;
export type ServicesCreateMutationOptions = Apollo.BaseMutationOptions<ServicesCreateMutation, ServicesCreateMutationVariables>;