import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditManufacturerMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  id: Types.Scalars['ID'];
  categoryIDs: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EditManufacturerMutation = { __typename?: 'Mutation', editManufacturer?: { __typename?: 'Manufacturer', id: string, name: string, active?: boolean | null } | null };


export const EditManufacturerDocument = gql`
    mutation editManufacturer($name: String!, $id: ID!, $categoryIDs: [ID!]!, $active: Boolean) {
  editManufacturer(
    name: $name
    id: $id
    categoryIDs: $categoryIDs
    active: $active
  ) {
    id
    name
    active
  }
}
    `;
export type EditManufacturerMutationFn = Apollo.MutationFunction<EditManufacturerMutation, EditManufacturerMutationVariables>;

/**
 * __useEditManufacturerMutation__
 *
 * To run a mutation, you first call `useEditManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManufacturerMutation, { data, loading, error }] = useEditManufacturerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *      categoryIDs: // value for 'categoryIDs'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useEditManufacturerMutation(baseOptions?: Apollo.MutationHookOptions<EditManufacturerMutation, EditManufacturerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditManufacturerMutation, EditManufacturerMutationVariables>(EditManufacturerDocument, options);
      }
export type EditManufacturerMutationHookResult = ReturnType<typeof useEditManufacturerMutation>;
export type EditManufacturerMutationResult = Apollo.MutationResult<EditManufacturerMutation>;
export type EditManufacturerMutationOptions = Apollo.BaseMutationOptions<EditManufacturerMutation, EditManufacturerMutationVariables>;