import React, {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import PageLayout from "../../layout/PageLayout";
import {PublicServicesDelete, PublicServicesEdit, PublicServicesList, PublicServicesAdd, PublicServicesRoot} from "../../containers/PublicServices";


const PublicServicesPage:FC = () => {
  return (
    <>
      <PublicServicesList />

      <Sidebar
        name="publicServicesAdd"
        rootDrawerElement={() => <PublicServicesAdd />}
      />

      <Sidebar
        name="servicesSettings"
        rootDrawerElement={() => <PublicServicesRoot />}
        childrenDrawerElements={[
          { name: 'publicServicesSettingsEdit', element: () => <PublicServicesEdit /> },
          { name: 'publicServicesSettingsDelete', element: () => <PublicServicesDelete /> }
        ]}
      />

    </>
  )
}


export default PublicServicesPage;
