import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { StatusListFragmentDoc } from './StatusList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStatusListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatusListQuery = { __typename?: 'Query', getStatuses: Array<{ __typename?: 'Status', id: string, title: string, type: Types.StatusType }> };


export const GetStatusListDocument = gql`
    query getStatusList {
  getStatuses {
    ...StatusList
  }
}
    ${StatusListFragmentDoc}`;

/**
 * __useGetStatusListQuery__
 *
 * To run a query within a React component, call `useGetStatusListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusListQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusListQuery, GetStatusListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusListQuery, GetStatusListQueryVariables>(GetStatusListDocument, options);
      }
export function useGetStatusListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusListQuery, GetStatusListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusListQuery, GetStatusListQueryVariables>(GetStatusListDocument, options);
        }
export type GetStatusListQueryHookResult = ReturnType<typeof useGetStatusListQuery>;
export type GetStatusListLazyQueryHookResult = ReturnType<typeof useGetStatusListLazyQuery>;
export type GetStatusListQueryResult = Apollo.QueryResult<GetStatusListQuery, GetStatusListQueryVariables>;