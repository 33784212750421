import {FC} from "react";
import styled from "styled-components";

const StyledTr = styled.tr`
  width: 100%;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #ebeff3;
`;

const Tr:FC = ({ children}) => {
  return (
    <StyledTr>{children}</StyledTr>
  )
}

export default Tr;
