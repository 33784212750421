import {FC} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Tag} from "antd";
import {useGetPointsQuery} from "./__generated__/PointList.query.generated";
import {PointItemFragment} from "./__generated__/PointList.fragment.generated";

const PointList:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading } = useGetPointsQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('pointAdd');

  const columns: ColumnsType<PointItemFragment> = [
    {
      title: 'Город',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Адреса',
      dataIndex: 'address',
      key: 'address'
    },
    {
      title: 'Телефоны',
      dataIndex: 'phones',
      key: 'phones',
      render: (_, row) => {
        return (
          <>
            { row.phones.map(item => (
              <p>{item?.phone} {item?.type && (item?.type)}</p>
            ))}
          </>
        )
      }
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      key: 'emails',
      render: (_, row) => {
        return (
          <>
            { row.emails.map(item => (
              <p>{item?.email} {item?.type && (item?.type)}</p>
            ))}
          </>
        )
      }
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={row.active ? "green" : "volcano"}>
            { row.active ? "Активно" : "Скрыто" }
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('pointSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={data?.getPoints}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={openDrawer}>Добавить филиал</Button>
          </Col>
        </Row>
      )}
    />
  )
}

export default PointList;
