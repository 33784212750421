import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type CategoryListFragment = { __typename?: 'Category', id: string, url?: string | null, name: string, active?: boolean | null };

export const CategoryListFragmentDoc = gql`
    fragment CategoryList on Category {
  id
  url
  name
  active
}
    `;