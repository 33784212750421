import {FC} from "react";
import styled from "styled-components";
import {ManufacturerInformFragment} from "./__generated__/ManufacturerInform.fragment.generated";

const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;

interface ManufacturerInformProps {
  info: ManufacturerInformFragment;
}

const ManufacturerInform:FC<ManufacturerInformProps> = ({ info }) => {
  return (
    <Container>
      <p>Информация о производителе:</p>
      <h2>{info.name}</h2>
      <p>Url: {info.url}</p>
    </Container>
  )
}

export default ManufacturerInform;
