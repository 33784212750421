import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { AccessoriesListFragmentDoc } from '../../AccessoriesList/__generated__/AccessoriesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccessoriesByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAccessoriesByIdQuery = { __typename?: 'Query', getAccessoriesById: { __typename?: 'Accessories', id: string, title: string, price: number, image: string, active?: boolean | null, subtitle: string, category: { __typename?: 'Category', id: string, name: string } } };


export const GetAccessoriesByIdDocument = gql`
    query getAccessoriesById($id: ID!) {
  getAccessoriesById(id: $id) {
    ...AccessoriesList
  }
}
    ${AccessoriesListFragmentDoc}`;

/**
 * __useGetAccessoriesByIdQuery__
 *
 * To run a query within a React component, call `useGetAccessoriesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoriesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoriesByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAccessoriesByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAccessoriesByIdQuery, GetAccessoriesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessoriesByIdQuery, GetAccessoriesByIdQueryVariables>(GetAccessoriesByIdDocument, options);
      }
export function useGetAccessoriesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoriesByIdQuery, GetAccessoriesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessoriesByIdQuery, GetAccessoriesByIdQueryVariables>(GetAccessoriesByIdDocument, options);
        }
export type GetAccessoriesByIdQueryHookResult = ReturnType<typeof useGetAccessoriesByIdQuery>;
export type GetAccessoriesByIdLazyQueryHookResult = ReturnType<typeof useGetAccessoriesByIdLazyQuery>;
export type GetAccessoriesByIdQueryResult = Apollo.QueryResult<GetAccessoriesByIdQuery, GetAccessoriesByIdQueryVariables>;