import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type AdsItemFragment = { __typename?: 'Ads', id: string, code: string, selectGeokurs: Array<string>, status: { __typename?: 'Status', id: string, type: Types.StatusType, title: string }, device: { __typename?: 'AdsDevice', title: string, description: string, category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, model: { __typename?: 'Models', id: string, name: string }, condition: { __typename?: 'Condition', id: string, name?: string | null }, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } }, addedServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, selectServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, desirableServices: Array<{ __typename?: 'ServicesCategory', id: string, title: string, subtitle: string } | null>, accessories: Array<{ __typename?: 'Accessories', id: string, title: string, price: number, category: { __typename?: 'Category', id: string, name: string } } | null>, images: { __typename?: 'AdsImages', jpeg: { __typename?: 'AdsImagesType', small: Array<string | null> } }, labels: Array<{ __typename?: 'Label', id: string, name: string, color: string, active?: boolean | null, description: string }>, user: { __typename?: 'User', _id: string, lastName?: string | null, email?: string | null, phone?: string | null, firstName?: string | null }, manager?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null };

export const AdsItemFragmentDoc = gql`
    fragment AdsItem on Ads {
  id
  code
  status {
    id
    type
    title
  }
  device {
    title
    description
    category {
      id
      name
    }
    manufacturer {
      id
      name
    }
    model {
      id
      name
    }
    condition {
      id
      name
    }
    price {
      current
      auction
    }
  }
  addedServices {
    id
    title
  }
  selectServices {
    id
    title
  }
  desirableServices {
    id
    title
    subtitle
  }
  selectGeokurs
  accessories {
    id
    title
    price
    category {
      id
      name
    }
  }
  images {
    jpeg {
      small
    }
  }
  labels {
    id
    name
    color
    active
    description
  }
  user {
    _id
    lastName
    email
    phone
    firstName
  }
  manager {
    _id
    firstName
    lastName
  }
}
    `;