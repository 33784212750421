import React, {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import PageLayout from "../../layout/PageLayout";
import {ModelAdd, ModelDelete, ModelRoot, ModelsList, ModelEdit} from "../../containers/Models";

const ModelsPage:FC = () => {
  return (
    <>
      <ModelsList />

      <Sidebar
        name="modelsAdd"
        rootDrawerElement={() => <ModelAdd />}
      />

      <Sidebar
        name="modelsSettings"
        rootDrawerElement={() => <ModelRoot />}
        childrenDrawerElements={[
          { name: 'modelsSettingsEdit', element: () => <ModelEdit /> },
          { name: 'modelsSettingsDelete', element: () => <ModelDelete /> }
        ]}
      />

    </>
  )
}

export default ModelsPage;
