import React, { FC } from "react";
import {Sidebar} from "../../ui/Sidebar";
import {LabelsAdd, LabelsLists, LabelsRoot, LabelsEdit, LabelsDelete} from "../../containers/Labels";

const LabelsPage:FC = () => {
  return (
    <>
      <LabelsLists />

      <Sidebar
        name="labelsCreate"
        rootDrawerElement={() => <LabelsAdd />}
      />

      <Sidebar
        name="labelsSettings"
        rootDrawerElement={() => <LabelsRoot />}
        childrenDrawerElements={[
          { name: "labelsSettingsEdit", element: () => <LabelsEdit /> },
          { name: "labelsSettingsDelete", element: () => <LabelsDelete /> }
        ]}
      />
    </>
  )
}

export default LabelsPage;
