import {FC} from "react";
import { Select } from "antd";
import {useGetSelectManufacturersQuery} from "./__generated__/ManufacturerSelect.query.generated";


const ManufacturerSelect:FC<ManufacturerSelectProps> = ({ defaultValue, onChange, size = "middle", showSearch = true, allowClear = false, placeholder }) => {
  const { data, loading } = useGetSelectManufacturersQuery();

  return (
    <Select
      size={size}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      defaultValue={defaultValue}
      showSearch={showSearch}
      placeholder={placeholder}
      style={{width: '100%'}}
      optionFilterProp="children"
    >
      {data?.getManufacturers.map((manufacturer) => (
        <Select.Option
          key={manufacturer.id}
          value={manufacturer.id}
        >
          {manufacturer.name}
        </Select.Option>
      ))}
    </Select>
  )
}


interface ManufacturerSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}


export default ManufacturerSelect;
