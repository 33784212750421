import {FC} from "react";
import {Link} from "../../../ui";
import {ManufacturerInform} from "../";
import styled from "styled-components";
import {LoadingIcon} from "../../../ui/Icons";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetManufacturerQuery} from "./__generated__/ManufacturerRoot.query.generated";

const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const ManufacturerRoot:FC = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetManufacturerQuery({ variables: { id } });

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getManufacturerById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <ManufacturerInform
        info={data?.getManufacturerById}
      />
      <Options>
        <h2>Управление производителем</h2>
        <ul>
          <Link colorTheme="green" onClick={() => openChildrenHandler('manufacturerSettingsEdit')}>Редактировать модель</Link>
          <Link colorTheme="red" onClick={() => openChildrenHandler('manufacturerSettingsDelete')}>Удалить модель</Link>
        </ul>
      </Options>
    </>
  )
}

export default ManufacturerRoot;
