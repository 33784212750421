import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectCitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectCitiesQuery = { __typename?: 'Query', getCities: Array<{ __typename?: 'City', id: string, name: string, latin: string }> };


export const GetSelectCitiesDocument = gql`
    query getSelectCities {
  getCities {
    id
    name
    latin
  }
}
    `;

/**
 * __useGetSelectCitiesQuery__
 *
 * To run a query within a React component, call `useGetSelectCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectCitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectCitiesQuery, GetSelectCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectCitiesQuery, GetSelectCitiesQueryVariables>(GetSelectCitiesDocument, options);
      }
export function useGetSelectCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectCitiesQuery, GetSelectCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectCitiesQuery, GetSelectCitiesQueryVariables>(GetSelectCitiesDocument, options);
        }
export type GetSelectCitiesQueryHookResult = ReturnType<typeof useGetSelectCitiesQuery>;
export type GetSelectCitiesLazyQueryHookResult = ReturnType<typeof useGetSelectCitiesLazyQuery>;
export type GetSelectCitiesQueryResult = Apollo.QueryResult<GetSelectCitiesQuery, GetSelectCitiesQueryVariables>;