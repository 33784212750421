import {FC} from "react";
import {Link} from "../../../ui";
import {StatusInform} from "../";
import styled from "styled-components";
import {LoadingIcon} from "../../../ui/Icons";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetStatusByIdQuery} from "./__generated__/StatusRoot.query.generated";

const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const StatusRoot:FC = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetStatusByIdQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getStatusById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
     <>
       <StatusInform
         info={data?.getStatusById}
       />
       <Options>
         <h2>Управление статусом</h2>
         <ul>
           <Link colorTheme="green" onClick={() => openChildrenHandler('statusSettingsEdit')}>Редактировать статус</Link>
           <Link colorTheme="red" onClick={() => openChildrenHandler('statusSettingsDelete')}>Удалить статус</Link>
         </ul>
       </Options>
     </>
  )
}

export default StatusRoot;
