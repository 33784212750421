import {FC} from "react";
import styled, {css} from "styled-components";
import { LoadingIcon } from "../Icons";
import { LoadingColors } from "../Icons/LoadingIcon/LoadingIcon";

const StyledButton = styled.button<{ buttonTheme: ButtonTheme }>`
  width: 100%;
  color: #fff;
  height: 4.2rem;
  display: block;
  max-width: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  border-radius: .3rem;

  ${props => {
    switch (props.buttonTheme) {
      case 'outlined':
        return css`
          color: #202020;
          background: transparent;
          border: 2px solid #bdcdd5;
          &:hover {
            color: ${props.theme.palette.purple};
            border: 2px solid ${props.theme.palette.purple};
          }
        `;
      case 'default':
      default:
        return css`
          border: 1px solid #166724;
          background: ${props.theme.palette.green};
        `;
    }
  }}
`;

const Loading = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 50%;
  }
`;


type ButtonTheme = 'default' | 'outlined';


interface ButtonProps {
  type: 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
  buttonTheme?: ButtonTheme;
  loadingColor?: LoadingColors;
}


const Button:FC<ButtonProps> = ({ children, type, disabled = false, loading = false, loadingColor, onClick, buttonTheme = "default" }) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      buttonTheme={buttonTheme}
    >
      { loading
        ? <Loading><LoadingIcon color={loadingColor} /></Loading>
        : children
      }
    </StyledButton>
  )
}


export default Button;
