import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
export type SelectCategoryFragment = { __typename?: 'Category', id: string, name: string };

export const SelectCategoryFragmentDoc = gql`
    fragment SelectCategory on Category {
  id
  name
}
    `;