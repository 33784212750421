import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { LabelsListFragmentDoc } from '../../LabelsLists/__generated__/LabelLists.fragmet.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLabelsByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetLabelsByIdQuery = { __typename?: 'Query', getLabelById?: { __typename?: 'Label', id: string, icon: string, name: string, color: string, active?: boolean | null, description: string } | null };


export const GetLabelsByIdDocument = gql`
    query getLabelsById($id: ID!) {
  getLabelById(id: $id) {
    ...LabelsList
  }
}
    ${LabelsListFragmentDoc}`;

/**
 * __useGetLabelsByIdQuery__
 *
 * To run a query within a React component, call `useGetLabelsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLabelsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLabelsByIdQuery, GetLabelsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelsByIdQuery, GetLabelsByIdQueryVariables>(GetLabelsByIdDocument, options);
      }
export function useGetLabelsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelsByIdQuery, GetLabelsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelsByIdQuery, GetLabelsByIdQueryVariables>(GetLabelsByIdDocument, options);
        }
export type GetLabelsByIdQueryHookResult = ReturnType<typeof useGetLabelsByIdQuery>;
export type GetLabelsByIdLazyQueryHookResult = ReturnType<typeof useGetLabelsByIdLazyQuery>;
export type GetLabelsByIdQueryResult = Apollo.QueryResult<GetLabelsByIdQuery, GetLabelsByIdQueryVariables>;