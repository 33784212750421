import {FC} from "react";
import styled from "styled-components";
import {Tbody, Th, Thead, Tr} from "../index";

const Wrapper = styled.table`
  width: 100%;
  text-align: left;
  position: relative;
  border-collapse: collapse;
`;


interface ITableHeader {
  key: string;
  name: string;
}

interface TableProps {
  header: ITableHeader[];
}


const Table:FC<TableProps> = ({ header, children }) => {
  return (
    <Wrapper>
      <Thead>
        <Tr>
          { header.map((item) => (
            <Th key={item.key}>{item.name}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {children}
      </Tbody>
    </Wrapper>
  )
}

export default Table;
