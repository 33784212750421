import {FC} from "react";
import {Select} from "antd";
import {useGetSelectLabelsQuery} from "./__generated__/LabelSelect.query.generated";

const LabelSelect:FC<LabelSelectProps> = ({  mode = "default", defaultValue, onChange, size = "middle", showSearch = true, placeholder, allowClear = false }) => {
  const { data, loading } = useGetSelectLabelsQuery();

  const type = mode === 'default' ? { onChange: onChange,  defaultValue: defaultValue } : { mode: "multiple", defaultValue: defaultValue } as const;

  return (
    <Select
      {...type}
      size={size}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      style={{ width: '100%' }}
      optionFilterProp="children"
    >
      {data?.getLabels.map((labels) => (
        <Select.Option
          key={labels.id}
          value={labels.id}
        >
          {labels.name}
        </Select.Option>
      ))}
    </Select>
  )
}

interface LabelSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  defaultValue?: string | string[];
  size?: 'large' | 'middle' | 'small';
  onChange?: (value: string | string[]) => void;
}

export default LabelSelect;
