import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type LabelsListFragment = { __typename?: 'Label', id: string, icon: string, name: string, color: string, active?: boolean | null, description: string };

export const LabelsListFragmentDoc = gql`
    fragment LabelsList on Label {
  id
  icon
  name
  color
  active
  description
}
    `;