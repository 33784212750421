import {Button, Col, Row} from "antd";
import {Sidebar} from "../../ui/Sidebar";
import React, {FC, useState} from "react";
import useDrawer from "../../hooks/useDrawer";
import {AdsLists, AdsFilter, AdsRoot, AdsManager, AdsStatus, AdsDelete, AdsCreate, AdsEdit} from "../../containers/Ads";


const AdsPage:FC = () => {
  const {openRootHandler} = useDrawer();
  const [filter, setFilter] = useState('ALL');

  const openDrawer = () => openRootHandler('adCreate');


  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Button type="primary" onClick={openDrawer}>Добавить объявление</Button>
        </Col>
        <Col span={24} >
          <AdsFilter
            filter={filter}
            setFilter={setFilter}
          />
        </Col>
      </Row>


      <AdsLists
        filter={filter}
      />

      <Sidebar
        width={900}
        name="adCreate"
        rootDrawerElement={() => <AdsCreate />}
      />

      <Sidebar
        width={1000}
        name="adEdit"
        rootDrawerElement={() => <AdsEdit />}
      />

      <Sidebar
        name="ads"
        rootDrawerElement={() => <AdsRoot /> }
        childrenDrawerElements={[
          { name: 'adsSettingsDelete', element: () => <AdsDelete /> },
          { name: 'adsSettingsManager', element: () => <AdsManager /> },
          { name: 'adsSettingsStatus', element: () => <AdsStatus /> },
        ]}
      />
    </>
  )
}


export default AdsPage;
