import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetServiceCategorySelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServiceCategorySelectQuery = { __typename?: 'Query', getServicesCategory: Array<{ __typename?: 'ServicesCategory', id: string, title: string, desc: string }> };


export const GetServiceCategorySelectDocument = gql`
    query getServiceCategorySelect {
  getServicesCategory {
    id
    title
    desc
  }
}
    `;

/**
 * __useGetServiceCategorySelectQuery__
 *
 * To run a query within a React component, call `useGetServiceCategorySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceCategorySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceCategorySelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceCategorySelectQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceCategorySelectQuery, GetServiceCategorySelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceCategorySelectQuery, GetServiceCategorySelectQueryVariables>(GetServiceCategorySelectDocument, options);
      }
export function useGetServiceCategorySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceCategorySelectQuery, GetServiceCategorySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceCategorySelectQuery, GetServiceCategorySelectQueryVariables>(GetServiceCategorySelectDocument, options);
        }
export type GetServiceCategorySelectQueryHookResult = ReturnType<typeof useGetServiceCategorySelectQuery>;
export type GetServiceCategorySelectLazyQueryHookResult = ReturnType<typeof useGetServiceCategorySelectLazyQuery>;
export type GetServiceCategorySelectQueryResult = Apollo.QueryResult<GetServiceCategorySelectQuery, GetServiceCategorySelectQueryVariables>;