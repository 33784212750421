import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderStatusesSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetOrderStatusesSelectQuery = { __typename?: 'Query', getOrderStatuses: Array<{ __typename?: 'OrderStatus', id: string, type: Types.OrderStatusType, title: string }> };


export const GetOrderStatusesSelectDocument = gql`
    query getOrderStatusesSelect {
  getOrderStatuses {
    id
    type
    title
  }
}
    `;

/**
 * __useGetOrderStatusesSelectQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrderStatusesSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetOrderStatusesSelectQuery, GetOrderStatusesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderStatusesSelectQuery, GetOrderStatusesSelectQueryVariables>(GetOrderStatusesSelectDocument, options);
      }
export function useGetOrderStatusesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderStatusesSelectQuery, GetOrderStatusesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderStatusesSelectQuery, GetOrderStatusesSelectQueryVariables>(GetOrderStatusesSelectDocument, options);
        }
export type GetOrderStatusesSelectQueryHookResult = ReturnType<typeof useGetOrderStatusesSelectQuery>;
export type GetOrderStatusesSelectLazyQueryHookResult = ReturnType<typeof useGetOrderStatusesSelectLazyQuery>;
export type GetOrderStatusesSelectQueryResult = Apollo.QueryResult<GetOrderStatusesSelectQuery, GetOrderStatusesSelectQueryVariables>;