import {FC} from "react";

const ErrorPage:FC = () => {
  return (
    <div>
      Error
    </div>
  )
}

export default ErrorPage;
