import {FC} from "react";
import styled from "styled-components";
import {images} from "../../../assets";
import {ArrowIcon} from "../../../ui/Icons";
import {useAuthenticateQuery} from "../../../routes/ProtectedRoute/__generated__/ProtectedRoute.generated";

const Container = styled.header`
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 101;
  background: #fff;
  padding:  0 1.5rem;
  position: absolute;
  display: flex;
  height: 5.4rem;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 1.4rem;
    margin: 0 1rem 0 2rem;
    fill: ${props => props.theme.palette.darkGrey};
  }
  img {
    height: 3.5rem;
  }
  p {
    margin: 0;
    font-size: 1.3rem;
    font-weight: 500;
    margin-left: 1rem;
  }
`;


const Profile = styled.div<{ isOpen: boolean }>`
  user-select: none;
  cursor: pointer;
  display: flex;
  height: 5.4rem;
  padding: 0 2rem;
  align-items: center;
  background: ${props => props.isOpen ? '#dce3e9' : '#fff'};
`;

const Avatar = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  color: #fff;
  font-weight: 600;
  border-radius: 100%;
  background: ${props => props.theme.palette.purple};
`;

const User = styled.div`
  font-size: 1.3rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  span,
  p {
    line-height: 100%;
  }
  p {
    font-size: 1em;
    font-weight: 500;
    margin-bottom: .25rem;
  }
  span {
    font-size: .85em;
    font-weight: 300;
    color: ${props => props.theme.palette.darkGrey};
  }
`;

interface HeaderNavigationProps {
  title?: string;
  isOpen: boolean;
  toggleHandler: () => void;
}


const HeaderNavigation:FC<HeaderNavigationProps> = ({ isOpen, toggleHandler, title }) => {
  const {data, loading } = useAuthenticateQuery();

  return (
    <Container>
      <Logo>
        <img src={images.logoSvg} alt="" />
        <p>Консоль<br /> управления</p>
        <ArrowIcon />
        <p>{title}</p>
      </Logo>
      { !loading && (
        <Profile onClick={toggleHandler} isOpen={isOpen}>
          <Avatar>{data?.authenticate?.firstName?.slice(0, 1)}</Avatar>
          <User>
            <p>{data?.authenticate?.firstName}</p>
            <span>{data?.authenticate?.lastName}</span>
          </User>
        </Profile>
      ) }
    </Container>
  )
}

export default HeaderNavigation;
