import {FC} from "react";
import styled from "styled-components";

const StyledGroup = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Group:FC = ({ children }) => {
  return (
    <StyledGroup>
      {children}
    </StyledGroup>
  )
}

export default Group;
