import {FC} from "react";
import {Select, Form } from "antd";


const ServicesGeokursSelect: FC<ServicesGeokursSelectProps> = ({ defaultValue, mode = 'default', onChange, size = "middle", showSearch = true, placeholder, allowClear = false }) => {
  const type = mode === 'default' ? { onChange: onChange,  defaultValue: defaultValue } : { mode: "multiple", defaultValue: defaultValue } as const;

  return (
    <Select
      {...type}
      size={size}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={onChange}
      showSearch={showSearch}
      style={{ width: '100%' }}
      optionFilterProp="children"
    >
      <Select.Option key="1" value="Гарантия - 6 месяцев">
        Гарантия - 6 месяцев
      </Select.Option>
      <Select.Option key="2" value="Полностью проверен и готов к работе">
        Полностью проверен и готов к работе
      </Select.Option>
      <Select.Option key="3" value="Свидетельство о поверке">
        Свидетельство о поверке
      </Select.Option>
    </Select>
  )
}

interface ServicesGeokursSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string | string[] | null;
  mode?: 'multiple' | 'tags';
  onChange?: (value: string | string[]) => void;
  size?: 'large' | 'middle' | 'small';
}

export default ServicesGeokursSelect;
