import React, {FC} from "react";
import {Checkbox, Form, Input} from "antd";
import useDrawer from "../../../hooks/useDrawer";
import {toast} from "react-toastify";
import Loading from "../../../ui/Loading/Loading";
import {Contentbar} from "../../../ui/Sidebar";
import {useGetEditLabelsQuery} from "./__generated__/LabelsEdit.query.generated";
import {useEditLabelMutation} from "./__generated__/LabelsEdit.mutation.generated";

const LabelsEdit:FC = () => {
  const [form] = Form.useForm<{
    name: string
    icon: string
    color: string
    active: boolean
    description: string
  }>();

  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetEditLabelsQuery({ variables: { id } })

  const [editHandler, { loading: editLoading }] = useEditLabelMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Лейбл успешно обновлен!');
    },
    onError: () => {
      toast.error('При обновлении лейбла произошла ошибка!')
    }
  })

  const handleSubmit = () => {
    form.validateFields().then((values) =>  editHandler({ variables: { inputs: { ...values }, id }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getLabelById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: data?.getLabelById?.name || '',
          icon: data?.getLabelById?.icon || '',
          color: data?.getLabelById?.color || '',
          active: data?.getLabelById?.active || false,
          description: data?.getLabelById?.description || '',
        }}
      >
        <Form.Item
          name="name"
          label="Введите название лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="icon"
          label="Введите название иконки лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="color"
          label="Введите цвет лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="description"
          label="Введите описание лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default LabelsEdit;
