import React, {FC} from "react";
import {Checkbox, Form, Input} from "antd";
import { toast } from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useCreateCityMutation} from "./__generated__/CityAdd.mutation.generated";


const CityAdd = ({  }) => {
  const [form] = Form.useForm<{ name: string, active?: boolean }>();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useCreateCityMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Город усешно добавлен!');
    },
    onError: ({ graphQLErrors}) => {
      toast.error(graphQLErrors[0].message || 'При добавлении города произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { ...values }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="name"
          label="Введите наименование города"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default CityAdd;
