import React, {FC} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Input} from "antd";
import {useGetPublicServicesQuery} from "./__generated__/PublicServicesList.query.generated";
import {PublicServicesListFragment} from "./__generated__/PublicServicesList.fragment.generated";

const { Search } = Input;

const PublicServicesList:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading } = useGetPublicServicesQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('publicServicesAdd');

  const columns: ColumnsType<PublicServicesListFragment> = [
    {
      title: 'Наименование',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Подзаголовок',
      dataIndex: 'subtitle',
      key: 'subtitle',
    },
    {
      title: 'Описание',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('servicesSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      rowKey="id"
      indentSize={50}
      loading={loading}
      columns={columns}
      dataSource={data?.getServicesCategory}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить категорию</Button>
          </Col>
          <Col span={12} offset={8}>
            <Row justify="space-between">
              <Col span={11} />
              <Col span={11}>
                <Search style={{ width: '100%' }} placeholder="Введите название модели" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default PublicServicesList;
