import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import PageLayout from "../../layout/PageLayout";
import {ManufacturerList, ManufacturerAdd, ManufacturerRoot, ManufacturerDelete, ManufacturerEdit} from "../../containers/Manufacturer";


const ManufacturerPage:FC = () => {
  return (
    <>
      <ManufacturerList />

      <Sidebar
        name="manufacturerAdd"
        rootDrawerElement={() => <ManufacturerAdd />}
      />

      <Sidebar
        name="manufacturerSettings"
        rootDrawerElement={() => <ManufacturerRoot />}
        childrenDrawerElements={[
          { name: "manufacturerSettingsDelete", element: () => <ManufacturerDelete /> },
          { name: "manufacturerSettingsEdit", element: () => <ManufacturerEdit /> }
        ]}
      />
    </>
  )
}


export default ManufacturerPage;
