import {FC} from "react";
import {Select, Form } from "antd";
import {useGetSelectCategoriesQuery} from "./__generated__/CategorySelect.query.generated";


const CategorySelect: FC<CategorySelectProps> = ({ defaultValue, mode = 'default', onChange, size = "middle", showSearch = true, placeholder, allowClear = false }) => {
  const {data, loading} = useGetSelectCategoriesQuery();

  const type = mode === 'default' ? { onChange: onChange,  defaultValue: defaultValue } : { mode: "multiple", defaultValue: defaultValue } as const;

  return (
    <Select
      {...type}
      size={size}
      loading={loading}
      allowClear={allowClear}
      placeholder={placeholder}
      onChange={onChange}
      showSearch={showSearch}
      style={{ width: '100%' }}
      optionFilterProp="children"
    >
      {data?.getCategories.map((category) => (
        <Select.Option
          key={category.id}
          value={category.id}
        >
          {category.name}
        </Select.Option>
      ))}
    </Select>
  )
}

interface CategorySelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string | string[];
  mode?: 'multiple' | 'tags';
  onChange?: (value: string | string[]) => void;
  size?: 'large' | 'middle' | 'small';
}

export default CategorySelect;
