import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { LabelsListFragmentDoc } from '../../../containers/Labels/LabelsLists/__generated__/LabelLists.fragmet.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectLabelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectLabelsQuery = { __typename?: 'Query', getLabels: Array<{ __typename?: 'Label', id: string, icon: string, name: string, color: string, active?: boolean | null, description: string }> };


export const GetSelectLabelsDocument = gql`
    query getSelectLabels {
  getLabels {
    ...LabelsList
  }
}
    ${LabelsListFragmentDoc}`;

/**
 * __useGetSelectLabelsQuery__
 *
 * To run a query within a React component, call `useGetSelectLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectLabelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectLabelsQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectLabelsQuery, GetSelectLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectLabelsQuery, GetSelectLabelsQueryVariables>(GetSelectLabelsDocument, options);
      }
export function useGetSelectLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectLabelsQuery, GetSelectLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectLabelsQuery, GetSelectLabelsQueryVariables>(GetSelectLabelsDocument, options);
        }
export type GetSelectLabelsQueryHookResult = ReturnType<typeof useGetSelectLabelsQuery>;
export type GetSelectLabelsLazyQueryHookResult = ReturnType<typeof useGetSelectLabelsLazyQuery>;
export type GetSelectLabelsQueryResult = Apollo.QueryResult<GetSelectLabelsQuery, GetSelectLabelsQueryVariables>;