import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { AdsDrawerInfoFragmentDoc } from '../../AdsInform/__generated__/AdsInform.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAdByIdQuery = { __typename?: 'Query', getAdById: { __typename?: 'Ads', id: string, code: string, status: { __typename?: 'Status', id: string, title: string }, manager?: { __typename?: 'User', _id: string, lastName?: string | null, firstName?: string | null } | null, device: { __typename?: 'AdsDevice', category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, model: { __typename?: 'Models', id: string, name: string } } } };


export const GetAdByIdDocument = gql`
    query getAdById($id: ID!) {
  getAdById(id: $id) {
    id
    status {
      id
      title
    }
    manager {
      _id
      lastName
      firstName
    }
    ...AdsDrawerInfo
  }
}
    ${AdsDrawerInfoFragmentDoc}`;

/**
 * __useGetAdByIdQuery__
 *
 * To run a query within a React component, call `useGetAdByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdByIdQuery, GetAdByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdByIdQuery, GetAdByIdQueryVariables>(GetAdByIdDocument, options);
      }
export function useGetAdByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdByIdQuery, GetAdByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdByIdQuery, GetAdByIdQueryVariables>(GetAdByIdDocument, options);
        }
export type GetAdByIdQueryHookResult = ReturnType<typeof useGetAdByIdQuery>;
export type GetAdByIdLazyQueryHookResult = ReturnType<typeof useGetAdByIdLazyQuery>;
export type GetAdByIdQueryResult = Apollo.QueryResult<GetAdByIdQuery, GetAdByIdQueryVariables>;