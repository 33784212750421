import React, {FC} from "react";
import { Routes, Route } from "react-router-dom";
import RedirectRoute from "./RedirectRoute/ProtectedRoute";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import {
  AdsPage,
  CityPage,
  LoginPage,
  ErrorPage,
  UsersPage,
  OrderPage,
  ModelsPage,
  StatusPage,
  LabelsPage,
  CategoryPage,
  ServicesPage,
  AccessoriesPage,
  ManufacturerPage,
  PublicServicesPage
} from "../pages";
import PointsPage from "../pages/PointsPage/PointsPage";


const AppRoute: FC = () => {
  return (
      <Routes>
          <Route element={<RedirectRoute to="/ads" />}>
            <Route path="/" element={<LoginPage />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<RedirectRoute to="/ads" />} />
            <Route path="ads" element={<AdsPage />} />
            <Route path="ads/models" element={<ModelsPage />} />
            <Route path="ads/categories" element={<CategoryPage />} />
            <Route path="ads/manufacturer" element={<ManufacturerPage />} />
            <Route path="ads/users" element={<UsersPage />} />
            <Route path="ads/services" element={<ServicesPage />} />
            <Route path="ads/services/categories" element={<PublicServicesPage />} />
            <Route path="ads/accessories" element={<AccessoriesPage />} />
            <Route path="ads/city" element={<CityPage />} />
            <Route path="ads/status" element={<StatusPage />} />
            <Route path="ads/orders" element={<OrderPage />} />
            <Route path="ads/labels" element={<LabelsPage />} />
            <Route path="ads/points" element={<PointsPage />} />
          </Route>

          <Route path="*" element={<ErrorPage />}/>
      </Routes>
  )
}

export default AppRoute;
