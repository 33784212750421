import React, {FC, useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Tag, Input, Image} from "antd";
import {useGetAccessoriesQuery} from "./__generated__/AccessoriesListe.query.generated";
import {AccessoriesListFragment} from "./__generated__/AccessoriesList.fragment.generated";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";

const { Search } = Input;

const AccessoriesList:FC = () => {
  const { openRootHandler } = useDrawer();
  const [search, setSearch] = useState('');
  const [category, setCategory] = useState('');
  const { data, loading, error } = useGetAccessoriesQuery({
    pollInterval: 1500,
    variables: {
      categoryIDs: category ? [category] : []
    }
  });

  const path = process.env.NODE_ENV === 'production' ? 'https://api2.geokurs.kz/uploads/accessories/' : 'http://localhost:5000/uploads/accessories/';

  const openDrawer = () => openRootHandler('accessoriesAdd');

  const columns: ColumnsType<AccessoriesListFragment> = [
    {
      width: 100,
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (_, row) => <Image width={100} src={`${path}${row.image}`} />,
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
      key: 'title',
      render: (_, row) => <b>{row.title}</b>,
    },
    {
      title: 'Категория',
      dataIndex: ['category', 'name'],
      key: 'categoryName'
    },
    {
      title: 'Описание',
      dataIndex: 'subtitle',
      key: 'subtitle'
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      render: (_, row) => <>{row.price} ₸</>,
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={row.active ? "green" : "volcano"}>
            { row.active ? "Активно" : "Скрыто"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('accessoriesSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={data?.getAccessories}
      title={() => (
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={openDrawer}>Добавить акссесуар</Button>
          </Col>
          <Col span={12} offset={4}>
            <Row justify="space-between">
              <Col span={11}>
                <CategorySelect
                  showSearch={true}
                  allowClear={true}
                  placeholder="Выберите категорию"
                  onChange={value => setCategory(value.toString())}
                />
              </Col>
              <Col span={11}>
                <Search placeholder="Введите название категории" onChange={(e) => setSearch(e.target.value)}/>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default AccessoriesList;
