import React, {FC, useEffect, useState} from "react";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetUserEditQuery} from "./__generated__/UserEdit.query.generated";
import {Contentbar} from "../../../ui/Sidebar";
import {Col, Form, Input, Radio, Row} from "antd";
import CitySelect from "../../../components/CitySelect/CitySelect";
import {toast} from "react-toastify";
import {useUserEditProfileMutation} from "./__generated__/UserEdit.mutation.generated";

const UserEdit:FC = () => {
  const [form] = Form.useForm();
  const [userType, setUserType] = useState('');
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { data, loading, error } = useGetUserEditQuery({
    variables: { id },
    onCompleted: (data) => {
      setUserType(data?.userGetByIdManager?.person?.type || '');
    }
  });

  const [editHandler, { loading: editLoading }] = useUserEditProfileMutation({
    onCompleted: () => {
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Пользователь успешно обновлен!');
    },
    onError: () => {
      toast.error('При обновлении категории произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      console.log(values);
      editHandler({ variables: { ...values, id }})
    });
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.userGetByIdManager) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} isLoading={editLoading} onSubmit={handleSubmit}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          ...data?.userGetByIdManager,
          city: data?.userGetByIdManager.city?.id
        }}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label="Введите имя"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label="Введите фамилию"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Введите номер телефона"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Введите email"
              rules={[
                {
                  type: 'email',
                  message: 'Введите верный email',
                },
                {
                  required: true,
                  message: 'Введите email',
                },
              ]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="city"
              label="Укажите город"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <CitySelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите город"
                defaultValue={data?.userGetByIdManager?.city?.name}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Адрес"
              name="address"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name={['person', 'type']}
          label="Выберите тип регистрации"
        >
          <Radio.Group onChange={(e) => setUserType(e.target.value)} value="test">
            <Radio value="INDIVIDUAL">Частное лицо</Radio>
            <Radio value="ENTITY">Представитель компании</Radio>
          </Radio.Group>
        </Form.Item>

        { userType === 'ENTITY' && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={['person', 'company']}
                label="Наименование компании"
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="БИН"
                name={['person', 'bin']}
                rules={[{ required: true, message: 'Заполните поле' }]}
              >
                <Input allowClear />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Contentbar>
  )
}

export default UserEdit;
