import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetUserEditQuery = { __typename?: 'Query', userGetByIdManager: { __typename?: 'User', phone?: string | null, email?: string | null, address?: string | null, firstName?: string | null, lastName?: string | null, city?: { __typename?: 'City', id: string, name: string } | null, person?: { __typename?: 'Person', type?: Types.PersonType | null, bin: string, company: string } | null } };


export const GetUserEditDocument = gql`
    query getUserEdit($id: ID!) {
  userGetByIdManager(id: $id) {
    city {
      id
      name
    }
    phone
    email
    address
    firstName
    lastName
    person {
      type
      bin
      company
    }
  }
}
    `;

/**
 * __useGetUserEditQuery__
 *
 * To run a query within a React component, call `useGetUserEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserEditQuery(baseOptions: Apollo.QueryHookOptions<GetUserEditQuery, GetUserEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEditQuery, GetUserEditQueryVariables>(GetUserEditDocument, options);
      }
export function useGetUserEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEditQuery, GetUserEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEditQuery, GetUserEditQueryVariables>(GetUserEditDocument, options);
        }
export type GetUserEditQueryHookResult = ReturnType<typeof useGetUserEditQuery>;
export type GetUserEditLazyQueryHookResult = ReturnType<typeof useGetUserEditLazyQuery>;
export type GetUserEditQueryResult = Apollo.QueryResult<GetUserEditQuery, GetUserEditQueryVariables>;