import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useDeletePointMutation} from "./__generated__/PointDelete.mutation.generated";


const PointDelete = () => {
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const [deleteHandler, { loading }] = useDeletePointMutation({
    variables: { id },
    onCompleted: () => {
      toast.success('Удаление выполнено!');
      closeRootHandler();
      closeChildrenHandler();
    },
    onError: () => {
      toast.error('При удалении произошла ошибка');
    }
  });

  return (
    <Contentbar isLoading={loading} onClose={closeChildrenHandler} onSubmit={deleteHandler}>
      <h2>Вы точно уверены?</h2>
      <p><b>Внимание!</b> Удаление происходит безвозвратно, удаленные данные восстановлению не подлежат.</p>
      <p>Для продолжения нажмите кнопку <b>"Подтвердить".</b></p>
    </Contentbar>
  )
}

export default PointDelete;
