import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type PointItemFragment = { __typename?: 'Point', id: string, e: string, n: string, name: string, active?: boolean | null, address: string, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null>, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null> };

export const PointItemFragmentDoc = gql`
    fragment PointItem on Point {
  id
  e
  n
  name
  active
  address
  phones {
    type
    phone
  }
  emails {
    type
    email
  }
}
    `;