import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAdManagerMutationVariables = Types.Exact<{
  user: Types.Scalars['ID'];
  status: Types.Scalars['ID'];
  images: Array<Types.InputMaybe<Types.Scalars['Upload']>> | Types.InputMaybe<Types.Scalars['Upload']>;
  addedServices?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  accessories?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  selectServices?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  selectGeokurs?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  device: Types.AdsCreatedInputsDevice;
}>;


export type CreateAdManagerMutation = { __typename?: 'Mutation', createAdManager?: { __typename?: 'Ads', id: string, device: { __typename?: 'AdsDevice', description: string, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } } } | null };


export const CreateAdManagerDocument = gql`
    mutation createAdManager($user: ID!, $status: ID!, $images: [Upload]!, $addedServices: [String], $accessories: [String], $selectServices: [String], $selectGeokurs: [String], $device: AdsCreatedInputsDevice!) {
  createAdManager(
    inputs: {user: $user, device: $device, images: $images, status: $status, accessories: $accessories, addedServices: $addedServices, selectGeokurs: $selectGeokurs, selectServices: $selectServices}
  ) {
    id
    device {
      price {
        current
        auction
      }
      description
    }
  }
}
    `;
export type CreateAdManagerMutationFn = Apollo.MutationFunction<CreateAdManagerMutation, CreateAdManagerMutationVariables>;

/**
 * __useCreateAdManagerMutation__
 *
 * To run a mutation, you first call `useCreateAdManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdManagerMutation, { data, loading, error }] = useCreateAdManagerMutation({
 *   variables: {
 *      user: // value for 'user'
 *      status: // value for 'status'
 *      images: // value for 'images'
 *      addedServices: // value for 'addedServices'
 *      accessories: // value for 'accessories'
 *      selectServices: // value for 'selectServices'
 *      selectGeokurs: // value for 'selectGeokurs'
 *      device: // value for 'device'
 *   },
 * });
 */
export function useCreateAdManagerMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdManagerMutation, CreateAdManagerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdManagerMutation, CreateAdManagerMutationVariables>(CreateAdManagerDocument, options);
      }
export type CreateAdManagerMutationHookResult = ReturnType<typeof useCreateAdManagerMutation>;
export type CreateAdManagerMutationResult = Apollo.MutationResult<CreateAdManagerMutation>;
export type CreateAdManagerMutationOptions = Apollo.BaseMutationOptions<CreateAdManagerMutation, CreateAdManagerMutationVariables>;