import {FC} from "react";
import {useGetStatuses} from "../../../apollo/actions";
import styled from "styled-components";

const Filter = styled.div`
  display: flex;
  column-gap: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #bdcdd6;
  button {
    height: 4rem;
    padding: 0 3rem;
    font-weight: 500;
    font-size: 1.4rem;
    &.active {
      color: #fff;
      border-top-left-radius: .5rem;
      border-top-right-radius: .5rem;
      background: ${props => props.theme.palette.green};
    }
  }
`;

interface AdsFilterProps {
  filter: string;
  setFilter: (type: string) => void;
}

const AdsFilter:FC<AdsFilterProps> = ({ setFilter, filter }) => {
  const { loading, data } = useGetStatuses();

  const filterHandler = (type: string) => {
    return () => {
      setFilter(type);
    }
  }

  return (
    <Filter>
      <button
        onClick={filterHandler('ALL')}
        className={filter === 'ALL' ? 'active' : 'non-active'}
      >
        Все
      </button>
      { !loading && data && data.getStatuses.map((item) => {
        return (
          <button
            key={item.id}
            className={filter === item.type ? 'active' : 'non-active'}
            onClick={filterHandler(item.type)}
          >
            {item.title}
          </button>
        )
      })}
    </Filter>
  )
}

export default AdsFilter;
