import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccessoriesSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAccessoriesSelectQuery = { __typename?: 'Query', getAccessories: Array<{ __typename?: 'Accessories', id: string, title: string, price: number, category: { __typename?: 'Category', id: string, name: string } }> };


export const GetAccessoriesSelectDocument = gql`
    query getAccessoriesSelect {
  getAccessories {
    id
    title
    price
    category {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAccessoriesSelectQuery__
 *
 * To run a query within a React component, call `useGetAccessoriesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoriesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoriesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessoriesSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessoriesSelectQuery, GetAccessoriesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessoriesSelectQuery, GetAccessoriesSelectQueryVariables>(GetAccessoriesSelectDocument, options);
      }
export function useGetAccessoriesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoriesSelectQuery, GetAccessoriesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessoriesSelectQuery, GetAccessoriesSelectQueryVariables>(GetAccessoriesSelectDocument, options);
        }
export type GetAccessoriesSelectQueryHookResult = ReturnType<typeof useGetAccessoriesSelectQuery>;
export type GetAccessoriesSelectLazyQueryHookResult = ReturnType<typeof useGetAccessoriesSelectLazyQuery>;
export type GetAccessoriesSelectQueryResult = Apollo.QueryResult<GetAccessoriesSelectQuery, GetAccessoriesSelectQueryVariables>;