import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type CategoryInfoFragment = { __typename?: 'Category', name: string, url?: string | null };

export const CategoryInfoFragmentDoc = gql`
    fragment CategoryInfo on Category {
  name
  url
}
    `;