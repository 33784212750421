import {useAppDispatch, useAppSelector} from "./useAppDispatch";
import {closeChildrenDrawer, closeRootDrawer, openChildrenDrawer, openRootDrawer} from "../store/reducers/DrawerSlice/DrawerSlice";



const useToggle = () => {
  const dispatch = useAppDispatch();
  const { id, childrenDrawer, rootDrawer, childrenWidth } = useAppSelector(state => state.drawer);

  const openRootHandler = (type: string, id?: string) => dispatch(openRootDrawer({ id: id, rootDrawer: type }))
  const openChildrenHandler = (type: string, childrenWidth?: number) => dispatch(openChildrenDrawer({ childrenDrawer: type, childrenWidth }))

  const closeRootHandler = () => dispatch(closeRootDrawer());
  const closeChildrenHandler = () => dispatch(closeChildrenDrawer());



  return {
    id,
    rootDrawer,
    childrenWidth,
    childrenDrawer,
    closeRootHandler,
    closeChildrenHandler,
    openRootHandler,
    openChildrenHandler
  }
}

export default useToggle;
