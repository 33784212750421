import React from "react";
import {Form, Input} from "antd";
import {toast} from "react-toastify";
import Loading from "../../../ui/Loading/Loading";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useGetModelEditQuery} from "./__generated__/ModelEdit.query.generated";
import {useModelEditMutation} from "./__generated__/ModelEdit.mutation.generated";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";


const ModelEdit = ({ }) => {
  const [form] = Form.useForm();
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();
  const { data, loading, error } = useGetModelEditQuery({ variables: { id }});

  const [editHandler, { loading: editLoading }] = useModelEditMutation({
    onCompleted: () => {
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Категория успешно обновлена!');
    },
    onError: () => {
      toast.error('При обновлении категории произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => editHandler({ variables: { ...values, id }}));
  };


  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getModelById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  console.log(data?.getModelById.name)

  return (
    <Contentbar onClose={closeChildrenHandler} isLoading={editLoading} onSubmit={handleSubmit}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: data?.getModelById?.name || '',
          categoryID: data?.getModelById?.categories?.id,
          manufacturerID: data?.getModelById?.manufacturers?.id
        }}>
        <Form.Item
          name="name"
          label="Введите название модели"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="categoryID"
          label="Выберите категорию"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <CategorySelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
            defaultValue={data?.getModelById?.categories?.id}
          />
        </Form.Item>
        <Form.Item
          name="manufacturerID"
          label="Выберите производителя"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <ManufacturerSelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
            defaultValue={data?.getModelById?.manufacturers?.id}
          />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default ModelEdit;
