import {FC} from "react";
import {UserInform} from "../";
import {Link} from "../../../ui";
import styled from "styled-components";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {LoadingIcon} from "../../../ui/Icons";
import {useAppSelector} from "../../../hooks/useAppDispatch";
import {useGetUserByIdQuery} from "./__generated__/UserRoot.query.generated";


const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const UserRoot:FC = () => {
  const { openChildrenHandler } = useDrawer();
  const { id } = useAppSelector(state => state.drawer);
  const { data, loading, error } = useGetUserByIdQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.userGetByIdManager) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <UserInform
        info={data.userGetByIdManager}
      />
      <Options>
        <h2>Управление объявлением</h2>
        <ul>
          <Link onClick={() => openChildrenHandler('userSettingsEdit', 750)}>Редактировать пользователя</Link>
          <Link onClick={() => openChildrenHandler('userSettingsDelete')}>Удалить пользователя</Link>
        </ul>
      </Options>
    </>
  )
}

export default UserRoot;
