import React, {FC} from "react";
import {Form, Input} from "antd";
import { toast } from "react-toastify";
import {useGetCategoryEditQuery} from "./__generated__/CategoryEdit.query.generated";
import useDrawer from "../../../hooks/useDrawer";
import {Contentbar} from "../../../ui/Sidebar";
import {useCategoryEditMutation} from "./__generated__/CategoryEdit.mutation.generated";
import Loading from "../../../ui/Loading/Loading";

const CategoryEdit:FC = () => {
  const [form] = Form.useForm<{ name: string }>()
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetCategoryEditQuery({ variables: { id } })

  const [editHandler, { loading: editLoading }] = useCategoryEditMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Категория успешно обновлена!');
    },
    onError: () => {
      toast.error('При обновлении категории произошла ошибка!')
    }
  })

  const handleSubmit = () => {
    form.validateFields().then((values) =>  editHandler({ variables: { ...values, id }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getCategoryById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          name: data?.getCategoryById?.name || ''
        }}
      >
        <Form.Item
          name="name"
          label="Введите название категории"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default CategoryEdit;
