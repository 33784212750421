import {FC} from "react";
import styled, {css} from "styled-components";
import {SettingsIcon} from "../../../ui/Icons";
import {Link} from "../../../ui";
import {AdsItemFragment} from "./__generated__/AdsItem.fragment.generated";
import useDrawer from "../../../hooks/useDrawer";

const Container = styled.div`
  width: 50%;
  display: block;
  padding: .75rem;
`;



const Wrapper = styled.div<{ type: string }>`
  display: flex;
  background: #fff;
  position: relative;
  padding-right: 4rem;
  border-left: 3px solid;
  ${props => {
    switch (props.type) {
      case 'ACTIVE':
        return css`
          border-color: ${props.theme.palette.green};
        `; 
      case 'ARCHIVE':
        return css`
          border-color: #ff9800;
        `;
      case 'STOP':
        return css`
          border-color: #ffeb3b;
        `;
      case 'MODERATION':
        return css`
          border-color: ${props.theme.palette.purple};
        `;
      case 'CANCELED':
        return css`
          border-color: ${props.theme.palette.red};
        `;
      case 'default':
      default:
        return css`
          border-left: 2px solid ${props.theme.palette.green};
        `;
    }
  }}
`;


const Image = styled.div`
  width: 25rem;
  height: 25rem;
  padding: 1rem;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: .5rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
  justify-content: space-between;
  width: calc(100% - 25rem);
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  p {
    &.code {
      color: ${props => props.theme.palette.darkGrey};
    }
  }
`;

const Controls = styled.button`
  width: 4rem;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.palette.grey};
  svg {
    width: 3rem;
  }
`;


const Row = styled.div`
  
`;

const Users = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface AdsItemProps {
  ads: AdsItemFragment
}


const AdsItem:FC<AdsItemProps> = ({ ads }) => {
  const { openRootHandler } = useDrawer();
  const path = process.env.NODE_ENV === 'production' ? 'https://api2.geokurs.kz' : 'http://localhost:5000';

  const openSettingsHandler = () => openRootHandler('ads', ads?.id);

  return (
    <Container>
      <Wrapper type={ads.status.type}>
        <Image>
           <img src={`${path}/${ads.images?.jpeg?.small[0]}`} />
        </Image>
        <Content>
          <Row>
            <p className="code">Номер объявления: {ads.code} {ads.status?.title}</p>
          </Row>
          <Row>
            <h2>{ads.device?.category?.name} {ads.device?.manufacturer?.name} {ads.device?.model?.name}</h2>
            <p>{ads.device?.description?.substring(0, 150)}...</p>
          </Row>
          <Row>
            <Users>
              <p>Пользователь: <Link sizeTheme="small">{ads.user?.firstName} {ads.user?.lastName}</Link></p>
              <p>Менеджер: <Link sizeTheme="small">{ads.manager?.firstName} {ads.manager?.lastName}</Link></p>
            </Users>
          </Row>
        </Content>

        <Controls onClick={openSettingsHandler}><SettingsIcon /></Controls>
      </Wrapper>
    </Container>
  )
}

export default AdsItem;
