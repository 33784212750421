import {FC} from "react";
import SimpleBar from "simplebar-react";
import {Button} from "../../index";
import styled from "styled-components";
import Loading from "../../Loading/Loading";
import {LoadingIcon} from "../../Icons";


const Main = styled.div`
  height: 100%;
  padding-bottom: 8.2rem;
`;

const Wrapper = styled.div`
  padding-right: 2rem;
  padding-bottom: 5rem;
`;

const Header = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;



const Footer = styled.div`
  background: #fff;
  padding: 2rem 3rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

interface ContentProps {
  title?: string;
  description?: string;
  isLoading?: boolean;
  onClose: () => void;
  onSubmit?: () => void;
}

const Contentbar:FC<ContentProps> = ({ children, isLoading = false, onSubmit, onClose, title, description }) => {
  const submitExist = typeof onSubmit === 'function';


  return (
    <>
      <Main>
        <SimpleBar style={{ height: '100%' }} autoHide={false}>
          <Wrapper>
            { children }
          </Wrapper>
        </SimpleBar>
      </Main>
      <Footer>
        { submitExist &&
            <Button
                type="submit"
                onClick={onSubmit}
                loading={isLoading}
                disabled={isLoading}
                buttonTheme="default"
            >
                Подтвердить
            </Button>
        }
        <Button
          type="button"
          onClick={onClose}
          buttonTheme="outlined"
        >
          Закрыть
        </Button>
      </Footer>
    </>
  )
}

export default Contentbar;
