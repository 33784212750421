import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PublicServicesListFragmentDoc } from '../../PublicServicesList/__generated__/PublicServicesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRootPublicServiceByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetRootPublicServiceByIdQuery = { __typename?: 'Query', getServicesCategoryById: { __typename?: 'ServicesCategory', id: string, desc: string, title: string, subtitle: string } };


export const GetRootPublicServiceByIdDocument = gql`
    query getRootPublicServiceById($id: ID!) {
  getServicesCategoryById(id: $id) {
    ...PublicServicesList
  }
}
    ${PublicServicesListFragmentDoc}`;

/**
 * __useGetRootPublicServiceByIdQuery__
 *
 * To run a query within a React component, call `useGetRootPublicServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootPublicServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootPublicServiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRootPublicServiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRootPublicServiceByIdQuery, GetRootPublicServiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRootPublicServiceByIdQuery, GetRootPublicServiceByIdQueryVariables>(GetRootPublicServiceByIdDocument, options);
      }
export function useGetRootPublicServiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRootPublicServiceByIdQuery, GetRootPublicServiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRootPublicServiceByIdQuery, GetRootPublicServiceByIdQueryVariables>(GetRootPublicServiceByIdDocument, options);
        }
export type GetRootPublicServiceByIdQueryHookResult = ReturnType<typeof useGetRootPublicServiceByIdQuery>;
export type GetRootPublicServiceByIdLazyQueryHookResult = ReturnType<typeof useGetRootPublicServiceByIdLazyQuery>;
export type GetRootPublicServiceByIdQueryResult = Apollo.QueryResult<GetRootPublicServiceByIdQuery, GetRootPublicServiceByIdQueryVariables>;