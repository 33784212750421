import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PointItemFragmentDoc } from '../../PointList/__generated__/PointList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditPointMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  inputs: Types.PointsInputs;
}>;


export type EditPointMutation = { __typename?: 'Mutation', editPoint?: { __typename?: 'Point', id: string, e: string, n: string, name: string, active?: boolean | null, address: string, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null>, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null> } | null };


export const EditPointDocument = gql`
    mutation editPoint($id: ID!, $inputs: PointsInputs!) {
  editPoint(id: $id, inputs: $inputs) {
    ...PointItem
  }
}
    ${PointItemFragmentDoc}`;
export type EditPointMutationFn = Apollo.MutationFunction<EditPointMutation, EditPointMutationVariables>;

/**
 * __useEditPointMutation__
 *
 * To run a mutation, you first call `useEditPointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPointMutation, { data, loading, error }] = useEditPointMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useEditPointMutation(baseOptions?: Apollo.MutationHookOptions<EditPointMutation, EditPointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPointMutation, EditPointMutationVariables>(EditPointDocument, options);
      }
export type EditPointMutationHookResult = ReturnType<typeof useEditPointMutation>;
export type EditPointMutationResult = Apollo.MutationResult<EditPointMutation>;
export type EditPointMutationOptions = Apollo.BaseMutationOptions<EditPointMutation, EditPointMutationVariables>;