import React, {FC} from "react";
import {Form, Input} from "antd";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetPublicServiceByIdQuery} from "./__generated__/PublicServicesEdit.query.generated";
import {useEditPublicServicesMutation} from "./__generated__/PublicServiceEdit.mutation.generated";


const PublicServicesEdit:FC = () => {
  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();
  const { data, loading, error } = useGetPublicServiceByIdQuery({ variables: { id } });

  const [form] = Form.useForm()

  const [editHandler, { loading: editLoading }] = useEditPublicServicesMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Сервис успешно обновлен!');
    },
    onError: () => {
      toast.error('При обновлении сервсиа произошла ошибка!')
    }
  })

  const handleSubmit = () => {
    form.validateFields().then((values) =>  editHandler({ variables: { ...values, id }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getServicesCategoryById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          ...data?.getServicesCategoryById
        }}
      >
        <Form.Item
          name="title"
          label="Введите название сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="subtitle"
          label="Введите подзаголовок сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Введите описание сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default PublicServicesEdit;
