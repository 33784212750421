import {FC} from "react";
import {Link} from "../../../ui";
import {PointInform} from "../index";
import styled from "styled-components";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {LoadingIcon} from "../../../ui/Icons";
import {useGetPointByIdQuery} from "./__generated__/PointRoot.query.generated";

const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const PointRoot:FC = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetPointByIdQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getPointById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <PointInform
        info={data?.getPointById}
      />
      <Options>
        <h2>Управление филиалом</h2>
        <ul>
          <Link colorTheme="green" onClick={() => openChildrenHandler('pointSettingsEdit')}>Редактировать филиал</Link>
          <Link colorTheme="red" onClick={() => openChildrenHandler('pointSettingsDelete')}>Удалить филиал</Link>
        </ul>
      </Options>
    </>
  )
}

export default PointRoot;
