import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import {PointList, PointAdd, PointDelete, PointEdit, PointRoot} from "../../containers/Point";


const PointsPage:FC = () => {
  return (
    <>
      <PointList />

      <Sidebar
        width={450}
        name="pointAdd"
        rootDrawerElement={() => <PointAdd />}
      />

      <Sidebar
        name="pointSettings"
        rootDrawerElement={() => <PointRoot />}
        childrenDrawerElements={[
          { name: "pointSettingsEdit", element: () => <PointEdit /> },
          { name: "pointSettingsDelete", element: () => <PointDelete /> }
        ]}
      />
    </>
  )
}


export default PointsPage;
