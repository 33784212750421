import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  title: Types.Scalars['String'];
  type: Types.StatusType;
}>;


export type EditStatusMutation = { __typename?: 'Mutation', editStatus?: { __typename?: 'Status', id: string, type: Types.StatusType, title: string } | null };


export const EditStatusDocument = gql`
    mutation editStatus($id: ID!, $title: String!, $type: StatusType!) {
  editStatus(id: $id, type: $type, title: $title) {
    id
    type
    title
  }
}
    `;
export type EditStatusMutationFn = Apollo.MutationFunction<EditStatusMutation, EditStatusMutationVariables>;

/**
 * __useEditStatusMutation__
 *
 * To run a mutation, you first call `useEditStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStatusMutation, { data, loading, error }] = useEditStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useEditStatusMutation(baseOptions?: Apollo.MutationHookOptions<EditStatusMutation, EditStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStatusMutation, EditStatusMutationVariables>(EditStatusDocument, options);
      }
export type EditStatusMutationHookResult = ReturnType<typeof useEditStatusMutation>;
export type EditStatusMutationResult = Apollo.MutationResult<EditStatusMutation>;
export type EditStatusMutationOptions = Apollo.BaseMutationOptions<EditStatusMutation, EditStatusMutationVariables>;