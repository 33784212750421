import React, {FC} from "react";
import {toast} from "react-toastify";
import Text from "antd/es/typography/Text";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {Button, Checkbox, Form, Input, Space} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useGetEditPointQuery} from "./__generated__/PointEdit.query.generated";
import {useEditPointMutation} from "./__generated__/PointEdit.mutation.generated";


const PointEdit:FC = () => {
  const [form] = Form.useForm<{
    n: string
    e: string
    name: string,
    active: boolean
    address: string,
    emails: [{
      email: string,
      type: string
    }],
    phones: [{
      phone: string,
      type: string
    }]
  }>();

  const { id, closeChildrenHandler, closeRootHandler } = useDrawer();

  const { loading, error, data } = useGetEditPointQuery({ variables: { id } })

  const [editHandler, { loading: editLoading }] = useEditPointMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Лейбл успешно обновлен!');
    },
    onError: () => {
      toast.error('При обновлении лейбла произошла ошибка!')
    }
  })

  const handleSubmit = () => {
    form.validateFields().then((values) =>  editHandler({ variables: { inputs: { ...values }, id }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getPointById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeChildrenHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        layout="vertical"
        hideRequiredMark
        form={form}
        initialValues={{
          n: data?.getPointById?.n || '',
          e: data?.getPointById?.e || '',
          name: data?.getPointById?.name || '',
          address: data?.getPointById?.address || '',
          phones: data?.getPointById?.phones.map(item => {
            return { phone: item?.phone, type: item?.type }
          }) || [],
          emails: data?.getPointById?.emails.map(item => {
            return { email: item?.email, type: item?.type }
          }) || [],
          active: data?.getPointById?.active || false,
        }}
      >
        <Form.Item
          name="name"
          label="Введите название города"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="address"
          label="Введите адрес"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>
        <Form.Item
          name="n"
          label="Координаты на карте (n)"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="e"
          label="Координаты на карте (e)"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Text style={{ marginBottom: 8, display: 'block' }}>Номера телефонов</Text>
        <Form.List name="phones" initialValue={data?.getPointById?.phones.map(item => {
          return { phone: item?.phone, type: item?.type }
        })}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space
                  key={field.key}
                  align="baseline"
                  style={{ display: "flex" }}
                >
                  <Form.Item
                    {...field}
                    name={[field.name, "phone"]}
                    rules={[{ required: true, message: 'Заполните поле' }]}
                  >
                    <Input placeholder="Номер телефона" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "type"]}
                  >
                    <Input placeholder="Тип номера" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item style={{ marginTop: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить номер
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Text style={{ marginBottom: .8, display: 'block' }}>Email адреса</Text>
        <Form.List name="emails" initialValue={data?.getPointById?.emails.map(item => {
          return { phone: item?.email, type: item?.type }
        })}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Space
                  key={field.key}
                  style={{ display: "flex" }}
                  align="baseline"
                >
                  <Form.Item
                    {...field}
                    name={[field.name, "email"]}
                    rules={[{ required: true, message: 'Заполните поле' }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    name={[field.name, "type"]}
                  >
                    <Input placeholder="Тип email" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item style={{ marginTop: 0 }}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить email
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default PointEdit;
