import {FC} from "react";
import styled from "styled-components";

const Item = styled.a<{ colorTheme: LinkColors, sizeTheme: LinkSize }>`
  display: inline-block;
  position: relative;
  text-decoration: none;
  margin-bottom: 1.5rem;
  span {
    color: #000;
    font-weight: 500;
    cursor: pointer;
    font-size: ${props => props.sizeTheme === 'small' ? '1.3rem' : '1.6rem' };
  }
  &:hover {
    span {
      border-bottom: transparent;
    }
  }
  span {
    ${props => {
      switch (props.colorTheme) {
        case 'red':
          return `
            border-bottom: 1px solid ${props.theme.palette.red};
        `;
        case 'purple':
          return `
            border-bottom: 1px solid ${props.theme.palette.purple};
        `;
        case 'green':
        default:
          return `
            border-bottom: 1px solid ${props.theme.palette.green};
        `;
      }
    }}
  }
`;

type LinkColors = 'red' | 'green' | 'purple';
type LinkSize = 'default' | 'small';

interface LinkProps {
  colorTheme?: LinkColors;
  sizeTheme?: LinkSize;
  onClick?: () => void;
}

const Link:FC<LinkProps> = ({ children, colorTheme = "green", sizeTheme = "default", onClick }) => {
  return (
    <Item
      onClick={onClick}
      sizeTheme={sizeTheme}
      colorTheme={colorTheme}
    >
      <span>{children}</span>
    </Item>
  )
}

export default Link;
