import {FC} from "react";
import {Select} from "antd";
import {useGetSelectConditionsQuery} from "./__generated__/ConditionSelect.query.generated";


const ConditionSelect: FC<ConditionSelectProps> = ({ defaultValue, onChange, size = "middle", showSearch = true, allowClear= false, placeholder }) => {
  const { data, loading } = useGetSelectConditionsQuery();

  return (
    <Select
      size={size}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      style={{width: '100%'}}
      defaultValue={defaultValue}
      optionFilterProp="children"
    >
      {data?.getConditions.map((condition) => (
        <Select.Option
          key={condition.id}
          value={condition.id}
        >
          {condition.name}
        </Select.Option>
      ))}
    </Select>
  )
}

interface ConditionSelectProps {
  showSearch?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}

export default ConditionSelect;
