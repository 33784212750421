import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PointItemFragmentDoc } from './PointList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPointsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPointsQuery = { __typename?: 'Query', getPoints: Array<{ __typename?: 'Point', id: string, e: string, n: string, name: string, active?: boolean | null, address: string, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null>, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null> }> };


export const GetPointsDocument = gql`
    query getPoints {
  getPoints {
    ...PointItem
  }
}
    ${PointItemFragmentDoc}`;

/**
 * __useGetPointsQuery__
 *
 * To run a query within a React component, call `useGetPointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPointsQuery(baseOptions?: Apollo.QueryHookOptions<GetPointsQuery, GetPointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointsQuery, GetPointsQueryVariables>(GetPointsDocument, options);
      }
export function useGetPointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointsQuery, GetPointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointsQuery, GetPointsQueryVariables>(GetPointsDocument, options);
        }
export type GetPointsQueryHookResult = ReturnType<typeof useGetPointsQuery>;
export type GetPointsLazyQueryHookResult = ReturnType<typeof useGetPointsLazyQuery>;
export type GetPointsQueryResult = Apollo.QueryResult<GetPointsQuery, GetPointsQueryVariables>;