import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserEditProfileMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  phone: Types.Scalars['String'];
  email: Types.Scalars['String'];
  city: Types.Scalars['ID'];
  person: Types.RegisterPersonInput;
}>;


export type UserEditProfileMutation = { __typename?: 'Mutation', userChangeManager?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null, phone?: string | null, email?: string | null, city?: { __typename?: 'City', id: string, name: string } | null, person?: { __typename?: 'Person', type?: Types.PersonType | null } | null } | null };


export const UserEditProfileDocument = gql`
    mutation userEditProfile($id: ID!, $firstName: String!, $lastName: String!, $phone: String!, $email: String!, $city: ID!, $person: RegisterPersonInput!) {
  userChangeManager(
    id: $id
    inputs: {firstName: $firstName, lastName: $lastName, phone: $phone, email: $email, city: $city, person: $person}
  ) {
    _id
    firstName
    lastName
    phone
    email
    city {
      id
      name
    }
    person {
      type
    }
  }
}
    `;
export type UserEditProfileMutationFn = Apollo.MutationFunction<UserEditProfileMutation, UserEditProfileMutationVariables>;

/**
 * __useUserEditProfileMutation__
 *
 * To run a mutation, you first call `useUserEditProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserEditProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userEditProfileMutation, { data, loading, error }] = useUserEditProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      city: // value for 'city'
 *      person: // value for 'person'
 *   },
 * });
 */
export function useUserEditProfileMutation(baseOptions?: Apollo.MutationHookOptions<UserEditProfileMutation, UserEditProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserEditProfileMutation, UserEditProfileMutationVariables>(UserEditProfileDocument, options);
      }
export type UserEditProfileMutationHookResult = ReturnType<typeof useUserEditProfileMutation>;
export type UserEditProfileMutationResult = Apollo.MutationResult<UserEditProfileMutation>;
export type UserEditProfileMutationOptions = Apollo.BaseMutationOptions<UserEditProfileMutation, UserEditProfileMutationVariables>;