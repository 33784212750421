import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePublicServicesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePublicServicesMutation = { __typename?: 'Mutation', deleteServiceCategory?: string | null };


export const DeletePublicServicesDocument = gql`
    mutation deletePublicServices($id: ID!) {
  deleteServiceCategory(id: $id)
}
    `;
export type DeletePublicServicesMutationFn = Apollo.MutationFunction<DeletePublicServicesMutation, DeletePublicServicesMutationVariables>;

/**
 * __useDeletePublicServicesMutation__
 *
 * To run a mutation, you first call `useDeletePublicServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicServicesMutation, { data, loading, error }] = useDeletePublicServicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePublicServicesMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicServicesMutation, DeletePublicServicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicServicesMutation, DeletePublicServicesMutationVariables>(DeletePublicServicesDocument, options);
      }
export type DeletePublicServicesMutationHookResult = ReturnType<typeof useDeletePublicServicesMutation>;
export type DeletePublicServicesMutationResult = Apollo.MutationResult<DeletePublicServicesMutation>;
export type DeletePublicServicesMutationOptions = Apollo.BaseMutationOptions<DeletePublicServicesMutation, DeletePublicServicesMutationVariables>;