import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { CityListFragmentDoc } from '../../CityList/__generated__/CityList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCityMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateCityMutation = { __typename?: 'Mutation', createCity?: { __typename?: 'City', id: string, name: string, latin: string, active?: boolean | null } | null };


export const CreateCityDocument = gql`
    mutation createCity($name: String!, $active: Boolean) {
  createCity(name: $name, active: $active) {
    ...CityList
  }
}
    ${CityListFragmentDoc}`;
export type CreateCityMutationFn = Apollo.MutationFunction<CreateCityMutation, CreateCityMutationVariables>;

/**
 * __useCreateCityMutation__
 *
 * To run a mutation, you first call `useCreateCityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCityMutation, { data, loading, error }] = useCreateCityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateCityMutation(baseOptions?: Apollo.MutationHookOptions<CreateCityMutation, CreateCityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCityMutation, CreateCityMutationVariables>(CreateCityDocument, options);
      }
export type CreateCityMutationHookResult = ReturnType<typeof useCreateCityMutation>;
export type CreateCityMutationResult = Apollo.MutationResult<CreateCityMutation>;
export type CreateCityMutationOptions = Apollo.BaseMutationOptions<CreateCityMutation, CreateCityMutationVariables>;