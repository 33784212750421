import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { AccessoriesListFragmentDoc } from './AccessoriesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAccessoriesQueryVariables = Types.Exact<{
  categoryIDs?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type GetAccessoriesQuery = { __typename?: 'Query', getAccessories: Array<{ __typename?: 'Accessories', id: string, title: string, price: number, image: string, active?: boolean | null, subtitle: string, category: { __typename?: 'Category', id: string, name: string } }> };


export const GetAccessoriesDocument = gql`
    query getAccessories($categoryIDs: [ID]) {
  getAccessories(categoryIDs: $categoryIDs) {
    ...AccessoriesList
  }
}
    ${AccessoriesListFragmentDoc}`;

/**
 * __useGetAccessoriesQuery__
 *
 * To run a query within a React component, call `useGetAccessoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessoriesQuery({
 *   variables: {
 *      categoryIDs: // value for 'categoryIDs'
 *   },
 * });
 */
export function useGetAccessoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessoriesQuery, GetAccessoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessoriesQuery, GetAccessoriesQueryVariables>(GetAccessoriesDocument, options);
      }
export function useGetAccessoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessoriesQuery, GetAccessoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessoriesQuery, GetAccessoriesQueryVariables>(GetAccessoriesDocument, options);
        }
export type GetAccessoriesQueryHookResult = ReturnType<typeof useGetAccessoriesQuery>;
export type GetAccessoriesLazyQueryHookResult = ReturnType<typeof useGetAccessoriesLazyQuery>;
export type GetAccessoriesQueryResult = Apollo.QueryResult<GetAccessoriesQuery, GetAccessoriesQueryVariables>;