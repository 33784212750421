import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { ModelsListFragmentDoc } from './ModelsList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetModelsQueryVariables = Types.Exact<{
  manufacturerIDs?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  categoryIDs?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
}>;


export type GetModelsQuery = { __typename?: 'Query', getModels: Array<{ __typename?: 'Models', id: string, name: string, url: string, active?: boolean | null, manufacturers: { __typename?: 'Manufacturer', id: string, name: string }, categories: { __typename?: 'Category', id: string, name: string } }> };


export const GetModelsDocument = gql`
    query getModels($manufacturerIDs: [String], $categoryIDs: [String]) {
  getModels(manufacturerIDs: $manufacturerIDs, categoryIDs: $categoryIDs) {
    ...ModelsList
  }
}
    ${ModelsListFragmentDoc}`;

/**
 * __useGetModelsQuery__
 *
 * To run a query within a React component, call `useGetModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsQuery({
 *   variables: {
 *      manufacturerIDs: // value for 'manufacturerIDs'
 *      categoryIDs: // value for 'categoryIDs'
 *   },
 * });
 */
export function useGetModelsQuery(baseOptions?: Apollo.QueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
      }
export function useGetModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
        }
export type GetModelsQueryHookResult = ReturnType<typeof useGetModelsQuery>;
export type GetModelsLazyQueryHookResult = ReturnType<typeof useGetModelsLazyQuery>;
export type GetModelsQueryResult = Apollo.QueryResult<GetModelsQuery, GetModelsQueryVariables>;