import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type UserInformFragment = { __typename?: 'User', firstName?: string | null, lastName?: string | null };

export const UserInformFragmentDoc = gql`
    fragment UserInform on User {
  firstName
  lastName
}
    `;