import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderStatusQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetOrderStatusQuery = { __typename?: 'Query', getOrderById?: { __typename?: 'Order', _id: string, status: { __typename?: 'OrderStatus', id: string, title: string } } | null };


export const GetOrderStatusDocument = gql`
    query getOrderStatus($id: ID!) {
  getOrderById(id: $id) {
    _id
    status {
      id
      title
    }
  }
}
    `;

/**
 * __useGetOrderStatusQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderStatusQuery(baseOptions: Apollo.QueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(GetOrderStatusDocument, options);
      }
export function useGetOrderStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderStatusQuery, GetOrderStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderStatusQuery, GetOrderStatusQueryVariables>(GetOrderStatusDocument, options);
        }
export type GetOrderStatusQueryHookResult = ReturnType<typeof useGetOrderStatusQuery>;
export type GetOrderStatusLazyQueryHookResult = ReturnType<typeof useGetOrderStatusLazyQuery>;
export type GetOrderStatusQueryResult = Apollo.QueryResult<GetOrderStatusQuery, GetOrderStatusQueryVariables>;