import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type ManufacturerListFragment = { __typename?: 'Manufacturer', id: string, url?: string | null, name: string, active?: boolean | null, categories: Array<{ __typename?: 'Category', id: string, name: string, active?: boolean | null }> };

export const ManufacturerListFragmentDoc = gql`
    fragment ManufacturerList on Manufacturer {
  id
  url
  name
  active
  categories {
    id
    name
    active
  }
}
    `;