import {FC} from "react";
import {Drawer} from "antd";
import useDrawer from "../../../hooks/useDrawer";

interface SidebarProps {
  name: string;
  width?: number;
  rootDrawerElement?: () => JSX.Element
  childrenDrawerElements?: {
    name: string
    element: () => JSX.Element
  }[]
}

const Sidebar:FC<SidebarProps> = ({ width = 350, name, rootDrawerElement: RootDrawer = () => null, childrenDrawerElements }) => {
  const { childrenWidth, closeChildrenHandler, closeRootHandler, childrenDrawer, rootDrawer } = useDrawer();

  const childrenDrawerElement = childrenDrawerElements && childrenDrawerElements.find((element) => element.name === childrenDrawer);

  return (
    <Drawer
      width={width}
      closable={false}
      onClose={closeRootHandler}
      visible={rootDrawer === name}
    >
      <RootDrawer />

      <Drawer
        closable={false}
        width={childrenWidth}
        visible={!!childrenDrawer}
        onClose={closeChildrenHandler}
      >
        { !!childrenDrawer && childrenDrawerElement?.element()}
      </Drawer>
    </Drawer>
  )
}

export default Sidebar;
