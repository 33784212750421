import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoryEditMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name: Types.Scalars['String'];
}>;


export type CategoryEditMutation = { __typename?: 'Mutation', editCategory?: { __typename?: 'Category', id: string } | null };


export const CategoryEditDocument = gql`
    mutation categoryEdit($id: ID!, $name: String!) {
  editCategory(name: $name, id: $id) {
    id
  }
}
    `;
export type CategoryEditMutationFn = Apollo.MutationFunction<CategoryEditMutation, CategoryEditMutationVariables>;

/**
 * __useCategoryEditMutation__
 *
 * To run a mutation, you first call `useCategoryEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoryEditMutation, { data, loading, error }] = useCategoryEditMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCategoryEditMutation(baseOptions?: Apollo.MutationHookOptions<CategoryEditMutation, CategoryEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CategoryEditMutation, CategoryEditMutationVariables>(CategoryEditDocument, options);
      }
export type CategoryEditMutationHookResult = ReturnType<typeof useCategoryEditMutation>;
export type CategoryEditMutationResult = Apollo.MutationResult<CategoryEditMutation>;
export type CategoryEditMutationOptions = Apollo.BaseMutationOptions<CategoryEditMutation, CategoryEditMutationVariables>;