import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditAccessoriesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  price: Types.Scalars['Int'];
  title: Types.Scalars['String'];
  image?: Types.InputMaybe<Types.Scalars['Upload']>;
  subtitle: Types.Scalars['String'];
  categoryID: Types.Scalars['ID'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type EditAccessoriesMutation = { __typename?: 'Mutation', editAccessoriesManager: { __typename?: 'Accessories', id: string, image: string, title: string, active?: boolean | null, subtitle: string, category: { __typename?: 'Category', id: string, name: string, active?: boolean | null } } };


export const EditAccessoriesDocument = gql`
    mutation editAccessories($id: ID!, $price: Int!, $title: String!, $image: Upload, $subtitle: String!, $categoryID: ID!, $active: Boolean) {
  editAccessoriesManager(
    id: $id
    inputs: {title: $title, image: $image, price: $price, active: $active, subtitle: $subtitle, categoryID: $categoryID}
  ) {
    id
    image
    title
    active
    subtitle
    category {
      id
      name
      active
    }
  }
}
    `;
export type EditAccessoriesMutationFn = Apollo.MutationFunction<EditAccessoriesMutation, EditAccessoriesMutationVariables>;

/**
 * __useEditAccessoriesMutation__
 *
 * To run a mutation, you first call `useEditAccessoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAccessoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAccessoriesMutation, { data, loading, error }] = useEditAccessoriesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      price: // value for 'price'
 *      title: // value for 'title'
 *      image: // value for 'image'
 *      subtitle: // value for 'subtitle'
 *      categoryID: // value for 'categoryID'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useEditAccessoriesMutation(baseOptions?: Apollo.MutationHookOptions<EditAccessoriesMutation, EditAccessoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAccessoriesMutation, EditAccessoriesMutationVariables>(EditAccessoriesDocument, options);
      }
export type EditAccessoriesMutationHookResult = ReturnType<typeof useEditAccessoriesMutation>;
export type EditAccessoriesMutationResult = Apollo.MutationResult<EditAccessoriesMutation>;
export type EditAccessoriesMutationOptions = Apollo.BaseMutationOptions<EditAccessoriesMutation, EditAccessoriesMutationVariables>;