import {FC} from "react";
import {AdsInform} from "../";
import {Link} from "../../../ui";
import styled from "styled-components";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {LoadingIcon} from "../../../ui/Icons";
import {useAppSelector} from "../../../hooks/useAppDispatch";
import {useGetAdByIdQuery} from "./__generated__/AdsRoot.query.generated";

const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const AdsRoot:FC = () => {
  const { openChildrenHandler, openRootHandler } = useDrawer();
  const { id } = useAppSelector(state => state.drawer);
  const { data, loading, error } = useGetAdByIdQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getAdById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <>
      <AdsInform
        info={data.getAdById}
      />
      <Options>
        <h2>Управление объявлением</h2>
        <ul>
          <Link onClick={() => openRootHandler('adEdit', id)}>Редактировать объявление</Link>
          <Link onClick={() => openChildrenHandler('adsSettingsStatus')}>Изменить статус</Link>
          <Link onClick={() => openChildrenHandler('adsSettingsManager')}>Изменить менеджера</Link>
          <Link onClick={() => openChildrenHandler('adsSettingsDelete')}>Удалить объявление</Link>
        </ul>
      </Options>
    </>
  )
}

export default AdsRoot;
