import React from 'react';
import AppRoute from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar/dist/simplebar.min.css';
import './App.less';
import {ThemeProvider} from "styled-components";
import {defaultTheme as theme, Global} from "./theme";
import {createUploadLink} from "apollo-upload-client";
import {setContext} from "@apollo/client/link/context";
import {ToastContainer} from "react-toastify";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";


const httpLink = createUploadLink({
  uri: process.env.NODE_ENV === 'production' ? 'https://api2.geokurs.kz/api' : 'http://localhost:5000/api',
  credentials: 'include',
});


const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('accessToken');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    },
  };
});


const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  link: ApolloLink.from([authLink, httpLink])
});


function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ToastContainer position="top-center" />

        <Global/>

        <AppRoute />

      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App;
