import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteAccessoriesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteAccessoriesMutation = { __typename?: 'Mutation', deleteAccessoriesManager?: string | null };


export const DeleteAccessoriesDocument = gql`
    mutation deleteAccessories($id: ID!) {
  deleteAccessoriesManager(id: $id)
}
    `;
export type DeleteAccessoriesMutationFn = Apollo.MutationFunction<DeleteAccessoriesMutation, DeleteAccessoriesMutationVariables>;

/**
 * __useDeleteAccessoriesMutation__
 *
 * To run a mutation, you first call `useDeleteAccessoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccessoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccessoriesMutation, { data, loading, error }] = useDeleteAccessoriesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAccessoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccessoriesMutation, DeleteAccessoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccessoriesMutation, DeleteAccessoriesMutationVariables>(DeleteAccessoriesDocument, options);
      }
export type DeleteAccessoriesMutationHookResult = ReturnType<typeof useDeleteAccessoriesMutation>;
export type DeleteAccessoriesMutationResult = Apollo.MutationResult<DeleteAccessoriesMutation>;
export type DeleteAccessoriesMutationOptions = Apollo.BaseMutationOptions<DeleteAccessoriesMutation, DeleteAccessoriesMutationVariables>;