import {FC, useEffect, useState} from "react";
import styled from "styled-components";
import AdsItem from "../AdsItem/AdsItem";
import Loading from "../../../ui/Loading/Loading";
import {useGetAdsQuery} from "./__generated__/AdsLists.query.generated";
import {LoadingIcon} from "../../../ui/Icons";
import {Empty} from "antd";

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 50rem;
  margin-left: -.5rem;
  position: relative;
  width: calc(100% + 1rem);
`;

interface AdsItemsProps {
  filter: string;
}

const AdsLists:FC<AdsItemsProps> = ({ filter }) => {
  const { loading, data, error } = useGetAdsQuery({
    variables: { status: filter ? filter : undefined, limit: 100 },
    pollInterval: 2000
  });

  if (loading || !data?.getAds?.data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (data?.getAds?.data.length <= 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Объявления не найдены" />;
  }

  return (
      <Items>
        { loading && <Loading /> }
        { !loading && data?.getAds?.data.map((item) => {
          return (
            <AdsItem
              ads={item}
              key={item?.id}
            />
          )
        })}
      </Items>
  )
}

export default AdsLists;
