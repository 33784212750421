import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectModelQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectModelQuery = { __typename?: 'Query', getModels: Array<{ __typename?: 'Models', id: string, name: string, url: string }> };


export const GetSelectModelDocument = gql`
    query getSelectModel {
  getModels {
    id
    name
    url
  }
}
    `;

/**
 * __useGetSelectModelQuery__
 *
 * To run a query within a React component, call `useGetSelectModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectModelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectModelQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectModelQuery, GetSelectModelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectModelQuery, GetSelectModelQueryVariables>(GetSelectModelDocument, options);
      }
export function useGetSelectModelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectModelQuery, GetSelectModelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectModelQuery, GetSelectModelQueryVariables>(GetSelectModelDocument, options);
        }
export type GetSelectModelQueryHookResult = ReturnType<typeof useGetSelectModelQuery>;
export type GetSelectModelLazyQueryHookResult = ReturnType<typeof useGetSelectModelLazyQuery>;
export type GetSelectModelQueryResult = Apollo.QueryResult<GetSelectModelQuery, GetSelectModelQueryVariables>;