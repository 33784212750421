import {FC, useState} from "react";
import Loading from "../../ui/Loading/Loading";
import PageLayout from "../../layout/PageLayout";
import { Navigate, Outlet } from "react-router-dom";
import {useAuthenticateQuery} from "./__generated__/ProtectedRoute.generated";


const ProtectedRoute:FC = ({  }) => {
  const [isAuth, setIsAuth] = useState(false);

  const { data, loading } = useAuthenticateQuery({
    onError: () => {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
    },
    onCompleted: (data) => {
      if (data.authenticate && data.authenticate.roles === 'admin') {
        setIsAuth(true);
      } else {
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessToken');
      }
    }
  });

  if (loading) {
    return <Loading />
  }

  if (isAuth) {
    return <PageLayout><Outlet /></PageLayout>;
  }

  return <Navigate to="/" />;
}


export default ProtectedRoute;
