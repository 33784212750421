import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import {CityList, CityAdd, CityRoot, CityEdit, CityDelete} from "../../containers/City";


const CityPage:FC = () => {
  return (
    <>
      <CityList />

      <Sidebar
        name="cityAdd"
        rootDrawerElement={() => <CityAdd />}
      />

      <Sidebar
        name="citySettings"
        rootDrawerElement={() => <CityRoot />}
        childrenDrawerElements={[
          { name: "citySettingsEdit", element: () => <CityEdit /> },
          { name: "citySettingsDelete", element: () => <CityDelete /> }
        ]}
      />
    </>
  )
}


export default CityPage;
