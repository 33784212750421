import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { AdsItemFragmentDoc } from '../../AdsItem/__generated__/AdsItem.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdsQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAdsQuery = { __typename?: 'Query', getAds: { __typename?: 'AdsPaginator', data: Array<{ __typename?: 'Ads', id: string, code: string, selectGeokurs: Array<string>, status: { __typename?: 'Status', id: string, type: Types.StatusType, title: string }, device: { __typename?: 'AdsDevice', title: string, description: string, category: { __typename?: 'Category', id: string, name: string }, manufacturer: { __typename?: 'Manufacturer', id: string, name: string }, model: { __typename?: 'Models', id: string, name: string }, condition: { __typename?: 'Condition', id: string, name?: string | null }, price: { __typename?: 'AdsDevicePrice', current: number, auction: boolean } }, addedServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, selectServices: Array<{ __typename?: 'Services', id: string, title: string } | null>, desirableServices: Array<{ __typename?: 'ServicesCategory', id: string, title: string, subtitle: string } | null>, accessories: Array<{ __typename?: 'Accessories', id: string, title: string, price: number, category: { __typename?: 'Category', id: string, name: string } } | null>, images: { __typename?: 'AdsImages', jpeg: { __typename?: 'AdsImagesType', small: Array<string | null> } }, labels: Array<{ __typename?: 'Label', id: string, name: string, color: string, active?: boolean | null, description: string }>, user: { __typename?: 'User', _id: string, lastName?: string | null, email?: string | null, phone?: string | null, firstName?: string | null }, manager?: { __typename?: 'User', _id: string, firstName?: string | null, lastName?: string | null } | null }>, paginator?: { __typename?: 'Paginator', itemCount: number, perPage: number, pageCount: number, currentPage: number, slNo: number, hasPrevPage: boolean, hasNextPage: boolean, prev?: string | null, next?: string | null } | null } };


export const GetAdsDocument = gql`
    query getAds($status: String, $limit: Int) {
  getAds(status: $status, limit: $limit) {
    data {
      ...AdsItem
    }
    paginator {
      itemCount
      perPage
      pageCount
      currentPage
      slNo
      hasPrevPage
      hasNextPage
      prev
      next
    }
  }
}
    ${AdsItemFragmentDoc}`;

/**
 * __useGetAdsQuery__
 *
 * To run a query within a React component, call `useGetAdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAdsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
      }
export function useGetAdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdsQuery, GetAdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdsQuery, GetAdsQueryVariables>(GetAdsDocument, options);
        }
export type GetAdsQueryHookResult = ReturnType<typeof useGetAdsQuery>;
export type GetAdsLazyQueryHookResult = ReturnType<typeof useGetAdsLazyQuery>;
export type GetAdsQueryResult = Apollo.QueryResult<GetAdsQuery, GetAdsQueryVariables>;