import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStatusesSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatusesSelectQuery = { __typename?: 'Query', getStatuses: Array<{ __typename?: 'Status', id: string, type: Types.StatusType, title: string }> };


export const GetStatusesSelectDocument = gql`
    query getStatusesSelect {
  getStatuses {
    id
    type
    title
  }
}
    `;

/**
 * __useGetStatusesSelectQuery__
 *
 * To run a query within a React component, call `useGetStatusesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusesSelectQuery, GetStatusesSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusesSelectQuery, GetStatusesSelectQueryVariables>(GetStatusesSelectDocument, options);
      }
export function useGetStatusesSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesSelectQuery, GetStatusesSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusesSelectQuery, GetStatusesSelectQueryVariables>(GetStatusesSelectDocument, options);
        }
export type GetStatusesSelectQueryHookResult = ReturnType<typeof useGetStatusesSelectQuery>;
export type GetStatusesSelectLazyQueryHookResult = ReturnType<typeof useGetStatusesSelectLazyQuery>;
export type GetStatusesSelectQueryResult = Apollo.QueryResult<GetStatusesSelectQuery, GetStatusesSelectQueryVariables>;