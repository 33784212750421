import React, {FC} from "react";
import {Form, Input} from "antd";
import useDrawer from "../../../hooks/useDrawer";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import {StatusType} from "../../../__generated__/types";
import {useEditStatusMutation} from "./__generated__/StatusEdit.mutation.generated";
import {useGetStatusEditQuery} from "./__generated__/StatusEdit.query.generated";
import Loading from "../../../ui/Loading/Loading";

const StatusEdit:FC = () => {
  const { id, closeRootHandler, closeChildrenHandler } = useDrawer();
  const [form] = Form.useForm<{ title: string, type: StatusType }>();

  const { data, loading, error } = useGetStatusEditQuery({ variables: { id }});

  const [editHandler, { loading: editLoading }] = useEditStatusMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      closeChildrenHandler();
      toast.success('Статус успешно обновлен!');
    },
    onError: () => {
      toast.error('При добавлении статуса произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => editHandler({ variables: { id, ...values }}));
  };

  if (loading || !data) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getStatusById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={editLoading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        initialValues={{
          title: data?.getStatusById.title,
          type: data?.getStatusById.type
        }}
      >
        <Form.Item
          name="title"
          label="Введите название статуса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="type"
          label="Введите тип статуса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear disabled />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default StatusEdit;
