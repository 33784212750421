import {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import PageLayout from "../../layout/PageLayout";
import {CategoryLists, CategoryAdd, CategoryRoot, CategoryDelete, CategoryEdit} from "../../containers/Category";


const CategoryPage:FC = () => {
  return (
    <>
      <CategoryLists />

      <Sidebar
        name="categoryAdd"
        rootDrawerElement={() => <CategoryAdd />}
      />

      <Sidebar
        name="categorySettings"
        rootDrawerElement={() => <CategoryRoot />}
        childrenDrawerElements={[
          { name: "categorySettingsDelete", element: () => <CategoryDelete /> },
          { name: "categorySettingsEdit", element: () => <CategoryEdit /> }
        ]}
      />
    </>
  )
}


export default CategoryPage;
