import React, {FC} from "react";
import styled from "styled-components";
import AuthForm from "../../components/AuthForm/AuthForm";
import { bg } from "../../assets";

const Container = styled.main`
  height: 100vh;
  width: 100%;
  display: flex;
  background: #fff;
`;

const Left = styled.div`
  width: 40%;
  background: ${props => props.theme.palette.green};
`;

const Right = styled.div`
  width: 60%;
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${bg.bgGrey}) no-repeat;
  background-size: cover;
`;



const LoginPage:FC = ({ }) => {
  return (
    <Container>
      <Left />

      <Right>
        <AuthForm />
      </Right>
    </Container>
  )
}

export default LoginPage;
