import React, {FC} from "react";
import {Sidebar} from "../../ui/Sidebar";
import PageLayout from "../../layout/PageLayout";
import {ServicesAdd, ServicesList, ServicesRoot, ServicesEdit, ServicesDelete} from "../../containers/Services";


const ServicesPage:FC = () => {
  return (
    <>
      <ServicesList />

      <Sidebar
        name="servicesAdd"
        rootDrawerElement={() => <ServicesAdd />}
      />

      <Sidebar
        name="servicesSettings"
        rootDrawerElement={() => <ServicesRoot />}
        childrenDrawerElements={[
          { name: 'servicesSettingsEdit', element: () => <ServicesEdit /> },
          { name: 'servicesSettingsDelete', element: () => <ServicesDelete /> }
        ]}
      />

    </>
  )
}


export default ServicesPage;
