import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetServiceSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServiceSelectQuery = { __typename?: 'Query', getServices: Array<{ __typename?: 'Services', id: string, title: string, desc: string, price: number, category: { __typename?: 'ServicesCategory', title: string, subtitle: string } }> };


export const GetServiceSelectDocument = gql`
    query getServiceSelect {
  getServices {
    id
    title
    desc
    price
    category {
      title
      subtitle
    }
  }
}
    `;

/**
 * __useGetServiceSelectQuery__
 *
 * To run a query within a React component, call `useGetServiceSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServiceSelectQuery(baseOptions?: Apollo.QueryHookOptions<GetServiceSelectQuery, GetServiceSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServiceSelectQuery, GetServiceSelectQueryVariables>(GetServiceSelectDocument, options);
      }
export function useGetServiceSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServiceSelectQuery, GetServiceSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServiceSelectQuery, GetServiceSelectQueryVariables>(GetServiceSelectDocument, options);
        }
export type GetServiceSelectQueryHookResult = ReturnType<typeof useGetServiceSelectQuery>;
export type GetServiceSelectLazyQueryHookResult = ReturnType<typeof useGetServiceSelectLazyQuery>;
export type GetServiceSelectQueryResult = Apollo.QueryResult<GetServiceSelectQuery, GetServiceSelectQueryVariables>;