import {FC} from "react";
import styled from "styled-components";
import {AccessoriesInformFragment} from "./__generated__/AccessoriesInform.fragment.generated";


const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
`;


interface AccessoriesInformProps {
  info: AccessoriesInformFragment
}

const AccessoriesInform:FC<AccessoriesInformProps> = ({ info }) => {
  return (
      <Container>
        <p>Информация о акссесуаре:</p>
        <h2>{info.title}</h2>
        <p>{info.category.name}</p>
      </Container>
  )
}

export default AccessoriesInform;
