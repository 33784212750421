import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PointItemFragmentDoc } from '../../PointList/__generated__/PointList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPointByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetPointByIdQuery = { __typename?: 'Query', getPointById?: { __typename?: 'Point', id: string, e: string, n: string, name: string, active?: boolean | null, address: string, phones: Array<{ __typename?: 'PointPhones', type?: string | null, phone?: string | null } | null>, emails: Array<{ __typename?: 'PointsEmails', type?: string | null, email?: string | null } | null> } | null };


export const GetPointByIdDocument = gql`
    query getPointById($id: ID!) {
  getPointById(id: $id) {
    ...PointItem
  }
}
    ${PointItemFragmentDoc}`;

/**
 * __useGetPointByIdQuery__
 *
 * To run a query within a React component, call `useGetPointByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPointByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPointByIdQuery, GetPointByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointByIdQuery, GetPointByIdQueryVariables>(GetPointByIdDocument, options);
      }
export function useGetPointByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointByIdQuery, GetPointByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointByIdQuery, GetPointByIdQueryVariables>(GetPointByIdDocument, options);
        }
export type GetPointByIdQueryHookResult = ReturnType<typeof useGetPointByIdQuery>;
export type GetPointByIdLazyQueryHookResult = ReturnType<typeof useGetPointByIdLazyQuery>;
export type GetPointByIdQueryResult = Apollo.QueryResult<GetPointByIdQuery, GetPointByIdQueryVariables>;