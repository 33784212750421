import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  firstName: Types.Scalars['String'];
  lastName: Types.Scalars['String'];
  phone: Types.Scalars['String'];
  email: Types.Scalars['String'];
  address: Types.Scalars['String'];
  city: Types.Scalars['ID'];
  password: Types.Scalars['String'];
  person: Types.RegisterPersonInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', userRegisterManager?: string | null };


export const CreateUserDocument = gql`
    mutation createUser($firstName: String!, $lastName: String!, $phone: String!, $email: String!, $address: String!, $city: ID!, $password: String!, $person: RegisterPersonInput!) {
  userRegisterManager(
    inputs: {city: $city, email: $email, phone: $phone, person: $person, address: $address, firstName: $firstName, lastName: $lastName, password: $password}
  )
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      password: // value for 'password'
 *      person: // value for 'person'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;