import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditAdMutationVariables = Types.Exact<{
  adsID?: Types.InputMaybe<Types.Scalars['ID']>;
  inputs?: Types.InputMaybe<Types.AdManagerEditInputs>;
}>;


export type EditAdMutation = { __typename?: 'Mutation', editAdManager?: string | null };


export const EditAdDocument = gql`
    mutation editAd($adsID: ID, $inputs: AdManagerEditInputs) {
  editAdManager(adsID: $adsID, inputs: $inputs)
}
    `;
export type EditAdMutationFn = Apollo.MutationFunction<EditAdMutation, EditAdMutationVariables>;

/**
 * __useEditAdMutation__
 *
 * To run a mutation, you first call `useEditAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdMutation, { data, loading, error }] = useEditAdMutation({
 *   variables: {
 *      adsID: // value for 'adsID'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useEditAdMutation(baseOptions?: Apollo.MutationHookOptions<EditAdMutation, EditAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditAdMutation, EditAdMutationVariables>(EditAdDocument, options);
      }
export type EditAdMutationHookResult = ReturnType<typeof useEditAdMutation>;
export type EditAdMutationResult = Apollo.MutationResult<EditAdMutation>;
export type EditAdMutationOptions = Apollo.BaseMutationOptions<EditAdMutation, EditAdMutationVariables>;