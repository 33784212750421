import {FC} from "react";
import {Link} from "../../../ui";
import styled from "styled-components";
import {useGetCategoryQuery} from "./__generated__/CategoryRoot.query.generated";
import useDrawer from "../../../hooks/useDrawer";
import CategoryInform from "../CategoryInform/CategoryInform";
import Loading from "../../../ui/Loading/Loading";
import {LoadingIcon} from "../../../ui/Icons";

const Options = styled.div`
  h2 {
    font-weight: 400;
    color: ${props => props.theme.palette.purple};
  }
  ul {
    display: flex;
    flex-direction: column;
  }
`;

const CategoryRoot:FC = () => {
  const { id, openChildrenHandler } = useDrawer();
  const { data, loading, error } = useGetCategoryQuery({ variables: { id }});

  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getCategoryById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
     <>
       <CategoryInform
         info={data?.getCategoryById}
       />
       <Options>
         <h2>Управление категорией</h2>
         <ul>
           <Link colorTheme="green" onClick={() => openChildrenHandler('categorySettingsEdit')}>Редактировать категорию</Link>
           <Link colorTheme="red" onClick={() => openChildrenHandler('categorySettingsDelete')}>Удалить категорию</Link>
         </ul>
       </Options>
     </>
  )
}

export default CategoryRoot;
