import {createGlobalStyle, DefaultTheme} from 'styled-components'

export const defaultTheme: DefaultTheme = {
  borderRadius: '.5rem',
  palette: {
    green: '#197323',
    grey: '#f2f5f7',
    red: "#F05658",
    purple: "#ac2b6f",
    black: '#284255',
    darkGrey: '#737e84',
    line: '#cfd7df',
  }
}


export const Global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;500&display=swap');

  .simplebar-scrollbar::before {
    background: #197323;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  html {
    font-size: 10px;
  }

  h1, h2, h3 {
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 2.4rem;
  }
  
  h2 {
    color: #284255;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  p {
    color: #284255;
    margin: 0 0 .8rem;
    font-size: 1.4rem;
  }

  button {
    border: none;
    cursor: pointer;
    background: none;
    outline: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input {
    outline: none;

    &:focus {
      outline: none
    }
  }
  
  .Toastify {
    font-size: 1.4rem;
  }

  .select {
    width: 100%;
    &__indicator {
      &-separator {
        display: none;
      }
    }
    &__control {
      cursor: pointer;
      min-height: 4rem !important;
      background-color: #FAFBFC !important;
      border: 1px solid #DDE3EB !important;
      &--is-focused {
        box-shadow: 0px 0px 0px 1px #227831 !important;
      }
    }
    &__placeholder {
      color: #284255 !important;
      font-weight: 600;
      font-size: 1.3rem;
    }
    &__single {
      &-value {
        font-weight: 600;
        font-size: 1.3rem;
      }
    }

    &__menu {
      margin-top: 5px!important;
      &-list {
        padding: 0 !important;
      }
    }
    &__option {
      cursor: pointer !important;
      padding: 10px 12px !important;
      color: #284255 !important;
      font-weight: 600;
      font-size: 1.3rem !important;
      &--is-focused {
        background: #bee8c6 !important;
      }
      &--is-selected {
        color: #fff !important;
      }
    }

    &__multi {
      &-value {
        font-size: 1.3rem;
        padding: .2em .2em .1em;
        background-color: #bee8c6 !important;
        &__remove {
          width: 1.6rem;
          height: 1.6rem;
          cursor: pointer;
          padding-left: 2px !important;
          padding-right: 2px !important;
          &:hover {
            color: #fff !important;
          }
        }
      }
    }
  }
`;
