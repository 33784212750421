import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type PublicServicesListFragment = { __typename?: 'ServicesCategory', id: string, desc: string, title: string, subtitle: string };

export const PublicServicesListFragmentDoc = gql`
    fragment PublicServicesList on ServicesCategory {
  id
  desc
  title
  subtitle
}
    `;