import {FC} from "react";
import styled from "styled-components";
import {PointItemFragment} from "../PointList/__generated__/PointList.fragment.generated";


const Container = styled.div`
  padding: 2.4rem;
  line-height: 130%;
  margin-top: -2.4rem;
  margin-left: -2.4rem;
  background: #e6ebef;
  margin-bottom: 2.5rem;
  width: calc(100% + 4.8rem);
  p {
    white-space: pre-wrap;
  }
`;


interface PointInfoProps {
  info: PointItemFragment
}

const LabelsInform:FC<PointInfoProps> = ({ info }) => {
  return (
    <Container>
      <p>Информация о филиале:</p>
      <h2>{info.name}</h2>
      <p>{info.address}</p>
    </Container>
  )
}

export default LabelsInform;
