import styled from "styled-components";
import {FC} from "react";

const StyledBody = styled.tbody`

`;

const Tbody:FC = ({ children }) => {
  return (
    <StyledBody>{children}</StyledBody>
  )
}

export default Tbody;
