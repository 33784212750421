import React, {FC} from "react";
import {toast} from "react-toastify";
import {Form, Input, InputNumber} from "antd";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useServicesCreateMutation} from "./__generated__/ServicesAdd.mutation.generated";
import ServicesCategorySelect from "../../../components/ServicesCategorySelect/ServicesCategorySelect";


const ServicesAdd:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useServicesCreateMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Сервис усешно добавлен!');
    },
    onError: (error: any) => {
      toast.error(error.message)
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      console.log(values);
      createHandler({variables: {...values}})
    });
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="category"
          label="Выберите категорию сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <ServicesCategorySelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите категорию"
          />
        </Form.Item>
        <Form.Item
          name="title"
          label="Введите заголовок сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="desc"
          label="Введите описание сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item
          name="price"
          label="Введите стоимость сервиса"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <InputNumber addonAfter="₸" style={{ width: "100%" }}/>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default ServicesAdd;
