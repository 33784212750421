import React, {FC} from "react";
import {Button, Col, Row, Space, Table, Tag} from "antd";
import {useGetStatusListQuery} from "./__generated__/StatusList.query.generated";
import {ColumnsType} from "antd/es/table";
import {StatusListFragment} from "./__generated__/StatusList.fragment.generated";
import useDrawer from "../../../hooks/useDrawer";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";


const StatusList:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading, error } = useGetStatusListQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('modelsAdd');

  const columns: ColumnsType<StatusListFragment> = [
    {
      title: 'Наименование',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Статус',
      key: 'active',
      render: (active: boolean) => (
        <>
          <Tag color={active ? "green" : "volcano"}>
            { active ? "Активно" : "Скрыто"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('statusSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      indentSize={50}
      dataSource={data?.getStatuses}
      pagination={{ defaultPageSize: 20 }}
    />
  )
}

export default StatusList;
