import {FC} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Row, Space, Table, Tag} from "antd";
import {useGetLabelsQuery} from "./__generated__/LabelsList.query.generated";
import {LabelsListFragment} from "./__generated__/LabelLists.fragmet.generated";

const LabelsLists:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading } = useGetLabelsQuery();

  const openDrawer = () => openRootHandler('labelsCreate');

  const columns: ColumnsType<LabelsListFragment> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Иконка',
      dataIndex: 'icon',
      key: 'icon',
    },
    {
      title: 'Статус',
      key: 'active',
      render: (active: boolean) => (
        <>
          <Tag color={active ? "green" : "volcano"}>
            { active ? "Активно" : "Скрыто" }
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('labelsSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];

  return (
    <Table
      bordered
      loading={loading}
      columns={columns}
      dataSource={data?.getLabels}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={8}>
            <Button type="primary" onClick={openDrawer}>Добавить лейбл</Button>
          </Col>
        </Row>
      )}
    />
  )
}

export default LabelsLists;
