import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePublicServiceMutationVariables = Types.Exact<{
  desc: Types.Scalars['String'];
  title: Types.Scalars['String'];
  subtitle: Types.Scalars['String'];
}>;


export type CreatePublicServiceMutation = { __typename?: 'Mutation', createServiceCategory: { __typename?: 'ServicesCategory', id: string, subtitle: string } };


export const CreatePublicServiceDocument = gql`
    mutation createPublicService($desc: String!, $title: String!, $subtitle: String!) {
  createServiceCategory(inputs: {desc: $desc, title: $title, subtitle: $subtitle}) {
    id
    subtitle
  }
}
    `;
export type CreatePublicServiceMutationFn = Apollo.MutationFunction<CreatePublicServiceMutation, CreatePublicServiceMutationVariables>;

/**
 * __useCreatePublicServiceMutation__
 *
 * To run a mutation, you first call `useCreatePublicServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicServiceMutation, { data, loading, error }] = useCreatePublicServiceMutation({
 *   variables: {
 *      desc: // value for 'desc'
 *      title: // value for 'title'
 *      subtitle: // value for 'subtitle'
 *   },
 * });
 */
export function useCreatePublicServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicServiceMutation, CreatePublicServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicServiceMutation, CreatePublicServiceMutationVariables>(CreatePublicServiceDocument, options);
      }
export type CreatePublicServiceMutationHookResult = ReturnType<typeof useCreatePublicServiceMutation>;
export type CreatePublicServiceMutationResult = Apollo.MutationResult<CreatePublicServiceMutation>;
export type CreatePublicServiceMutationOptions = Apollo.BaseMutationOptions<CreatePublicServiceMutation, CreatePublicServiceMutationVariables>;