import {Form} from "antd";
import React, {FC} from "react";
import {toast} from "react-toastify";
import {Contentbar} from "../../../ui/Sidebar";
import {LoadingIcon} from "../../../ui/Icons";
import useDrawer from "../../../hooks/useDrawer";
import Loading from "../../../ui/Loading/Loading";
import {useGetOrderStatusQuery} from "./__generated__/OrderStatus.query.generated";
import {useChangeOrderStatusMutation} from "./__generated__/OrderStatus.mutation.generated";
import StatusOrderSelect from "../../../components/StatusOrderSelect/StatusOrderSelect";



const OrderStatus:FC = () => {
  const { id, closeChildrenHandler } = useDrawer();
  const [form] = Form.useForm<{ statusID: string }>();

  const { data, loading, error } = useGetOrderStatusQuery({ variables: { id }});

  const [changeHandler, { loading: changeLoading }] = useChangeOrderStatusMutation({
    onCompleted: () => {
      closeChildrenHandler();
      toast.success('Изменение статуса прошло успешно!');
    },
    onError: () => {
      toast.error('При изменение статуса прозошла ошибка!');
    }
  });


  const handleSubmit = () => {
    form.validateFields().then((values) =>  changeHandler({ variables: { orderID: id, ...values }}));
  };


  if (loading || !data) {
    return (
      <Loading><LoadingIcon /></Loading>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!data?.getOrderById) {
    return <p>Возможно произошла ошибка или ID был пуст</p>;
  }

  return (
    <Contentbar isLoading={changeLoading} onSubmit={handleSubmit} onClose={closeChildrenHandler}>
      <h2>Смена статуса заказа:</h2>
      <p>Для продолжения укажите нужный статус и нажмите кнопку <b>"Подтвердить".</b></p>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        initialValues={{
          device: {
            price: {
              auction: false
            }
          }
        }}
      >
        <Form.Item
          name="statusID"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <StatusOrderSelect
            showSearch={true}
            allowClear={true}
            placeholder="Выберите статус"
            defaultValue={data.getOrderById.status.title}
          />
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default OrderStatus;
