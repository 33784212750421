import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { PublicServicesListFragmentDoc } from './PublicServicesList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPublicServicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPublicServicesQuery = { __typename?: 'Query', getServicesCategory: Array<{ __typename?: 'ServicesCategory', id: string, desc: string, title: string, subtitle: string }> };


export const GetPublicServicesDocument = gql`
    query getPublicServices {
  getServicesCategory {
    ...PublicServicesList
  }
}
    ${PublicServicesListFragmentDoc}`;

/**
 * __useGetPublicServicesQuery__
 *
 * To run a query within a React component, call `useGetPublicServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicServicesQuery, GetPublicServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicServicesQuery, GetPublicServicesQueryVariables>(GetPublicServicesDocument, options);
      }
export function useGetPublicServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicServicesQuery, GetPublicServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicServicesQuery, GetPublicServicesQueryVariables>(GetPublicServicesDocument, options);
        }
export type GetPublicServicesQueryHookResult = ReturnType<typeof useGetPublicServicesQuery>;
export type GetPublicServicesLazyQueryHookResult = ReturnType<typeof useGetPublicServicesLazyQuery>;
export type GetPublicServicesQueryResult = Apollo.QueryResult<GetPublicServicesQuery, GetPublicServicesQueryVariables>;