import {FC, useState} from "react";
import {Layout, Menu} from "antd";
import { NavLink } from "react-router-dom";
import Header from "../components/Header/Header/Header";
import {FileOutlined, ContainerOutlined, UserOutlined, ControlOutlined, GatewayOutlined, SettingOutlined, ShoppingCartOutlined, PushpinOutlined } from '@ant-design/icons';

const { Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

interface PageLayout {
  title?: string;
  description?: string;
}

const PageLayout:FC<PageLayout> = ({ children, title, description }) => {
  const [collapse, setCollapsed] = useState(false);

  return (
      <>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible collapsed={collapse} onCollapse={() => setCollapsed(!collapse)} style={{ zIndex: 150 }}>
            <div className="logo" />
            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
              <Menu.Item key="users" icon={<UserOutlined />}>
                <NavLink to="/ads/users">Пользователи</NavLink>
              </Menu.Item>
              <Menu.Item key="orders" icon={<ShoppingCartOutlined />}>
                <NavLink to="/ads/orders">Заказы</NavLink>
              </Menu.Item>
              <Menu.Item key="ads" icon={<FileOutlined />}>
                <NavLink to="/ads">Объявления</NavLink>
              </Menu.Item>
              <Menu.Item key="accessories" icon={<GatewayOutlined />}>
                <NavLink to="/ads/accessories">Аксессуары</NavLink>
              </Menu.Item>
              <SubMenu key="categories" icon={<ContainerOutlined />} title="Оборудование">
                <Menu.Item key="4">
                  <NavLink to="/ads/categories">Категории</NavLink>
                </Menu.Item>
                <Menu.Item key="5">
                  <NavLink to="/ads/manufacturer">Производители</NavLink>
                </Menu.Item>
                <Menu.Item key="6">
                  <NavLink to="/ads/models">Модели</NavLink>
                </Menu.Item>
                <Menu.Item key="7">
                  <NavLink to="/ads/labels">Лейблы</NavLink>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="services" icon={<ControlOutlined />} title="Сервисы">
                <Menu.Item key="servicesForUsers">
                  <NavLink to="/ads/services/categories">Категории</NavLink>
                </Menu.Item>
                <Menu.Item key="servicesForSeller">
                  <NavLink to="/ads/services">Сервисы</NavLink>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="points" icon={<PushpinOutlined />}>
                <NavLink to="/ads/points">Филиалы</NavLink>
              </Menu.Item>
              <SubMenu key="settings" icon={<SettingOutlined />} title="Настройки">
                <Menu.Item key="city">
                  <NavLink to="/ads/city">Города</NavLink>
                </Menu.Item>
                <Menu.Item key="status">
                  <NavLink to="/ads/status">Статусы</NavLink>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ position: "relative", paddingTop: 70 }}>
            <Header title={title} />
            <Content style={{ margin: '0 16px' }}>
              <div className="site-layout-background" style={{ minHeight: 360 }}>
                { children }
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Geokurs</Footer>
          </Layout>
        </Layout>
      </>
  )
}

export default PageLayout;
