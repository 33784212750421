import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSelectUserQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', _id: string, email?: string | null, phone?: string | null, firstName?: string | null, lastName?: string | null, city?: { __typename?: 'City', id: string, name: string } | null }> };


export const GetSelectUserDocument = gql`
    query getSelectUser {
  users {
    _id
    city {
      id
      name
    }
    email
    phone
    firstName
    lastName
  }
}
    `;

/**
 * __useGetSelectUserQuery__
 *
 * To run a query within a React component, call `useGetSelectUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelectUserQuery(baseOptions?: Apollo.QueryHookOptions<GetSelectUserQuery, GetSelectUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectUserQuery, GetSelectUserQueryVariables>(GetSelectUserDocument, options);
      }
export function useGetSelectUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectUserQuery, GetSelectUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectUserQuery, GetSelectUserQueryVariables>(GetSelectUserDocument, options);
        }
export type GetSelectUserQueryHookResult = ReturnType<typeof useGetSelectUserQuery>;
export type GetSelectUserLazyQueryHookResult = ReturnType<typeof useGetSelectUserLazyQuery>;
export type GetSelectUserQueryResult = Apollo.QueryResult<GetSelectUserQuery, GetSelectUserQueryVariables>;