import React from "react";
import {toast} from "react-toastify";
import {Checkbox, Form, Input} from "antd";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import {useCreateLabelMutation} from "./__generated__/LabelsAdd.mutation.generated";


const LabelsAdd = () => {
  const [form] = Form.useForm<{
    name: string
    icon: string
    color: string,
    active: boolean
    description: string
  }>();

  const { closeRootHandler } = useDrawer();

  const [createHandler, { loading }] = useCreateLabelMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Лейбл усешно добавлен!');
    },
    onError: () => {
      toast.error('При добавлении лейбла произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({ variables: { inputs: { ...values } }}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form layout="vertical" hideRequiredMark form={form}>
        <Form.Item
          name="name"
          label="Введите название лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="icon"
          label="Введите название иконки лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="color"
          label="Введите цвет лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input allowClear />
        </Form.Item>
        <Form.Item
          name="description"
          label="Введите описание лейбла"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>Показывать на сайте?</Checkbox>
        </Form.Item>
      </Form>
    </Contentbar>
  )
}

export default LabelsAdd;
