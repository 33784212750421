import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetManufacturerEditQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetManufacturerEditQuery = { __typename?: 'Query', getManufacturerById?: { __typename?: 'Manufacturer', id: string, name: string, active?: boolean | null, categories: Array<{ __typename?: 'Category', id: string, name: string }> } | null };


export const GetManufacturerEditDocument = gql`
    query getManufacturerEdit($id: ID!) {
  getManufacturerById(id: $id) {
    id
    name
    active
    categories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetManufacturerEditQuery__
 *
 * To run a query within a React component, call `useGetManufacturerEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManufacturerEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManufacturerEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManufacturerEditQuery(baseOptions: Apollo.QueryHookOptions<GetManufacturerEditQuery, GetManufacturerEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetManufacturerEditQuery, GetManufacturerEditQueryVariables>(GetManufacturerEditDocument, options);
      }
export function useGetManufacturerEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManufacturerEditQuery, GetManufacturerEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetManufacturerEditQuery, GetManufacturerEditQueryVariables>(GetManufacturerEditDocument, options);
        }
export type GetManufacturerEditQueryHookResult = ReturnType<typeof useGetManufacturerEditQuery>;
export type GetManufacturerEditLazyQueryHookResult = ReturnType<typeof useGetManufacturerEditLazyQuery>;
export type GetManufacturerEditQueryResult = Apollo.QueryResult<GetManufacturerEditQuery, GetManufacturerEditQueryVariables>;