import React, {FC} from "react";
import {Contentbar} from "../../../ui/Sidebar";
import useDrawer from "../../../hooks/useDrawer";
import { UploadOutlined } from '@ant-design/icons';
import UserSelect from "../../../components/UserSelect/UserSelect";
import ModelSelect from "../../../components/ModelSelect/ModelSelect";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import ServicesSelect from "../../../components/ServicesSelect/ServicesSelect";
import ConditionSelect from "../../../components/ConditionsSelect/ConditionSelect";
import {Form, Input, Checkbox, Button, Space, Divider, Upload, InputNumber, Row, Col} from "antd";
import {useCreateAdManagerMutation} from "./__generated__/AdsCreate.mutation.generated";
import ManufacturerSelect from "../../../components/ManufacturerSelect/ManufacturerSelect";
import {toast} from "react-toastify";
import StatusSelect from "../../../components/StatusSelect/StatusSelect";
import AccessoriesSelect from "../../../components/AccessoriesSelect/AccessoriesSelect";
import ServicesGeokursSelect from "../../../components/ServicesGeokursSelect/ServicesGeokursSelect";


const AdsCreate:FC = () => {
  const [form] = Form.useForm();
  const { closeRootHandler } = useDrawer();
  const [createHandler, { loading, data }] = useCreateAdManagerMutation({
    onCompleted: () => {
      form.resetFields();
      closeRootHandler();
      toast.success('Объявление успешно добавлено!');
    },
    onError: () => {
      toast.error('При добавлении объявления произошла ошибка!')
    }
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => createHandler({variables: {...values, images: [...values.images.fileList]}}));
  };

  return (
    <Contentbar onClose={closeRootHandler} onSubmit={handleSubmit} isLoading={loading}>
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        autoComplete="off"
        initialValues={{
          device: {
            price: {
              auction: false
            }
          }
        }}
      >
        <Divider orientation="left" style={{ color: '#ac2b6f' }}>Информация о приборе</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name={['device', "category"]}
              label="Выберите категорию"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <CategorySelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите категорию"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Выберите производителя"
              name={['device', "manufacturer"]}
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <ManufacturerSelect
                showSearch={true}
                allowClear={true}
                placeholder="Выберите производителя"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Выберите модель"
              name={['device', "model"]}
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <ModelSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите модель"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Выберите состояние"
              name={['device', "condition"]}
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <ConditionSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите состояние"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Укажите стоимость">
          <Space style={{ alignItems: 'center' }} size={25}>
            <Form.Item
              name={['device', 'price', 'current']}
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <InputNumber addonAfter="₸" />
            </Form.Item>
            <Form.Item  name={['device', 'price', 'auction']} valuePropName="checked">
              <Checkbox>Торг уместен</Checkbox>
            </Form.Item>
          </Space>
        </Form.Item>

        <Divider orientation="left" style={{ color: '#ac2b6f' }}>Описание и изображения прибора</Divider>
        <Form.Item
          label="Заголовок"
          name={['device', 'title']}
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input showCount maxLength={150} />
        </Form.Item>

        <Form.Item
          label="Описание"
          name={['device', 'description']}
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Input.TextArea showCount maxLength={1500} />
        </Form.Item>

        <Form.Item
          name="images"
          label="Изображения продукта"
          rules={[{ required: true, message: 'Заполните поле' }]}
        >
          <Upload beforeUpload={() => false} listType="picture" multiple={true}>
            <Button icon={<UploadOutlined />}>Загрузить фотографии</Button>
          </Upload>
        </Form.Item>

        <Divider orientation="left" style={{ color: '#ac2b6f' }}>Сервисы</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="addedServices"
              label="Выберите сервисы доступные по умолчанию"
            >
            <ServicesSelect
              allowClear={true}
              showSearch={true}
              placeholder="Выберите сервисы"
            />
            </Form.Item>

            <Form.Item
              name="selectGeokurs"
              label="Выберите сервисы геокурса"
            >
              <ServicesGeokursSelect
                mode="multiple"
                allowClear={true}
                showSearch={true}
                placeholder="Выберите сервисы"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="selectServices"
              label="Выберите сервисы доступные для покупателя"
            >
              <ServicesSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите сервисы"
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left" style={{ color: '#ac2b6f' }}>Аксессуары</Divider>
        <Form.Item
          name="accessories"
          label="Выберите аксессуары доступные для покупателя"
        >
          <AccessoriesSelect
            allowClear={true}
            showSearch={true}
            placeholder="Выберите аксессуары"
          />
        </Form.Item>


        <Divider orientation="left" style={{ color: '#ac2b6f' }}>Прочие настройки</Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="user"
              label="Выберите менеджера"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <UserSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите менеджера"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="status"
              label="Укажите статус объявления"
              rules={[{ required: true, message: 'Заполните поле' }]}
            >
              <StatusSelect
                allowClear={true}
                showSearch={true}
                placeholder="Выберите статус"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Contentbar>
  )
}


export default AdsCreate;
