import React, {FC, useState} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Input, Row, Space, Table, Tag} from "antd";
import CategorySelect from "../../../components/CategorySelect/CategorySelect";
import {useGetManufacturerListQuery} from "./__generated__/ManufacturerList.query.generated";
import {ManufacturerListFragment} from "./__generated__/ManufacturerList.fragment.generated";

const { Search } = Input;

const ManufacturerList:FC = () => {
  const { openRootHandler } = useDrawer();
  const [category, setCategory] = useState('');

  const { data, loading } = useGetManufacturerListQuery({
    pollInterval: 1500,
    variables: {
      categoryIDs: category ? [category] : []
    }
  });

  const openDrawer = () => openRootHandler('manufacturerAdd');

  const columns: ColumnsType<ManufacturerListFragment> = [
    {
      title: 'Производитель',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <b>{text}</b>,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Статус',
      key: 'active',
      render: (_, row) => (
        <>
          <Tag color={row.active ? "green" : "volcano"}>
            { row.active ? "Активно" : "Скрыто"}
          </Tag>
        </>
      ),
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('manufacturerSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];
  return (
    <Table
      bordered
      indentSize={50}
      loading={loading}
      columns={columns}
      dataSource={data?.getManufacturers}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить производителя</Button>
          </Col>
          <Col span={12} offset={8}>
            <Row justify="space-between">
              <Col span={11}>
                <CategorySelect
                  showSearch={true}
                  allowClear={true}
                  placeholder="Выберите категорию"
                  onChange={value => setCategory(value.toString())}
                />
              </Col>
              <Col span={11}>
                <Search style={{ width: '100%' }} placeholder="Введите название модели" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default ManufacturerList;
