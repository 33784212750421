import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAccessoriesMutationVariables = Types.Exact<{
  price: Types.Scalars['Int'];
  title: Types.Scalars['String'];
  image: Types.Scalars['Upload'];
  subtitle: Types.Scalars['String'];
  categoryID: Types.Scalars['ID'];
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CreateAccessoriesMutation = { __typename?: 'Mutation', createAccessoriesManager: { __typename?: 'Accessories', id: string, image: string, title: string, active?: boolean | null, subtitle: string, category: { __typename?: 'Category', id: string, name: string, active?: boolean | null } } };


export const CreateAccessoriesDocument = gql`
    mutation createAccessories($price: Int!, $title: String!, $image: Upload!, $subtitle: String!, $categoryID: ID!, $active: Boolean) {
  createAccessoriesManager(
    inputs: {title: $title, image: $image, price: $price, active: $active, subtitle: $subtitle, categoryID: $categoryID}
  ) {
    id
    image
    title
    active
    subtitle
    category {
      id
      name
      active
    }
  }
}
    `;
export type CreateAccessoriesMutationFn = Apollo.MutationFunction<CreateAccessoriesMutation, CreateAccessoriesMutationVariables>;

/**
 * __useCreateAccessoriesMutation__
 *
 * To run a mutation, you first call `useCreateAccessoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessoriesMutation, { data, loading, error }] = useCreateAccessoriesMutation({
 *   variables: {
 *      price: // value for 'price'
 *      title: // value for 'title'
 *      image: // value for 'image'
 *      subtitle: // value for 'subtitle'
 *      categoryID: // value for 'categoryID'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useCreateAccessoriesMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccessoriesMutation, CreateAccessoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccessoriesMutation, CreateAccessoriesMutationVariables>(CreateAccessoriesDocument, options);
      }
export type CreateAccessoriesMutationHookResult = ReturnType<typeof useCreateAccessoriesMutation>;
export type CreateAccessoriesMutationResult = Apollo.MutationResult<CreateAccessoriesMutation>;
export type CreateAccessoriesMutationOptions = Apollo.BaseMutationOptions<CreateAccessoriesMutation, CreateAccessoriesMutationVariables>;