import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import { LabelsListFragmentDoc } from '../../LabelsLists/__generated__/LabelLists.fragmet.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEditLabelsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetEditLabelsQuery = { __typename?: 'Query', getLabelById?: { __typename?: 'Label', id: string, icon: string, name: string, color: string, active?: boolean | null, description: string } | null };


export const GetEditLabelsDocument = gql`
    query getEditLabels($id: ID!) {
  getLabelById(id: $id) {
    ...LabelsList
  }
}
    ${LabelsListFragmentDoc}`;

/**
 * __useGetEditLabelsQuery__
 *
 * To run a query within a React component, call `useGetEditLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditLabelsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEditLabelsQuery(baseOptions: Apollo.QueryHookOptions<GetEditLabelsQuery, GetEditLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditLabelsQuery, GetEditLabelsQueryVariables>(GetEditLabelsDocument, options);
      }
export function useGetEditLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditLabelsQuery, GetEditLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditLabelsQuery, GetEditLabelsQueryVariables>(GetEditLabelsDocument, options);
        }
export type GetEditLabelsQueryHookResult = ReturnType<typeof useGetEditLabelsQuery>;
export type GetEditLabelsLazyQueryHookResult = ReturnType<typeof useGetEditLabelsLazyQuery>;
export type GetEditLabelsQueryResult = Apollo.QueryResult<GetEditLabelsQuery, GetEditLabelsQueryVariables>;