import { gql } from "@apollo/client";

export const GET_USER = gql`
    query userInfo {
        userInfo {
            id
            email
            firstName
            lastName
            secondName
        }
    }
`;

export const USER_REFRESH = gql`
    query userRefresh {
        userRefresh {
            id
            email
            lastName
            firstName
            accessToken
            refreshToken
        }
    }
`;



export const USER_LOGIN = gql`
    mutation userLogin(
        $email: String!
        $password: String!
    ) {
        userLogin(email: $email, password: $password) {
            accessToken
            refreshToken
        }
    }
`;

export const GET_AD_BY_ID = gql`
    query getAdById($id: ID!) {
        getAdById(id: $id) {
            id
            code
            user {
                firstName
                lastName
            }
            status {
                id
                type
                title
            }
            device {
                category {
                    name
                }
                model {
                    name
                }
                manufacturer {
                    name
                }
            }
        }
    }
`;


export const GET_ADS = gql`
    query getAds($status: String) {
        getAds(status: $status) {
            id
            code
            user {
                firstName
                lastName
            }
            status {
                id
                type
                title
            }
            date {
                createdAt
                activatedAt
            }
            images {
                jpeg {
                    small
                    big
                    middle
                }
            }
            device {
                title
                description
                category {
                    id
                    name
                }
                model {
                    id
                    name
                }
                manufacturer {
                    id
                    name
                }
                price {
                    current
                    auction
                }
                condition {
                    id
                    name
                }
            }
        }
    }
`;


export const GET_STATUSES = gql`
    query getStatuses {
        getStatuses {
            id
            type
            title
        }
    }
`;


export const DELETE_AD = gql`
    mutation deleteAd(
        $id: ID!
    ) {
        deleteAd(id: $id)
    }
`;

export const CHANGE_ADS_STATUS = gql`
    mutation changeAdStatus(
        $statusID: ID!
        $adsID: ID!
    ) {
        changeAdStatus(statusID: $statusID, adsID: $adsID)
    }
`;


export const GET_CATEGORIES = gql`
    query getCategories {
        getCategories {
            id
            url
            name
        }
    }
`;
