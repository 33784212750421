import React, {FC} from "react";
import {ColumnsType} from "antd/es/table";
import useDrawer from "../../../hooks/useDrawer";
import {Button, Col, Input, Row, Space, Table} from "antd";
import {useGetServicesQuery} from "./__generated__/ServicesList.query.generated";
import {ServiceListFragment} from "./__generated__/ServicesList.fragment.generated";

const { Search } = Input;

const ServicesList:FC = () => {
  const { openRootHandler } = useDrawer();
  const { data, loading } = useGetServicesQuery({ pollInterval: 1500 });

  const openDrawer = () => openRootHandler('servicesAdd');

  const columns: ColumnsType<ServiceListFragment> = [
    {
      title: 'Категория',
      key: 'category',
      render: (_, row) => <b>{row.category.title}</b>,
    },
    {
      title: 'Наименование',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Описание',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      render: (text: string) => <>{text} ₸</>,
    },
    {
      title: 'Действия',
      key: 'id',
      render: (_, row) => (
        <Space size="middle">
          <a onClick={() => openRootHandler('servicesSettings', row.id)}>Настройки</a>
        </Space>
      )
    }
  ];
  return (
    <Table
      bordered
      rowKey="id"
      indentSize={50}
      loading={loading}
      columns={columns}
      dataSource={data?.getServices}
      pagination={{ defaultPageSize: 20 }}
      title={() => (
        <Row>
          <Col span={4}>
            <Button type="primary" onClick={openDrawer}>Добавить сервис</Button>
          </Col>
          <Col span={12} offset={8}>
            <Row justify="space-between">
              <Col span={11} />
              <Col span={11}>
                <Search style={{ width: '100%' }} placeholder="Введите название модели" loading={loading} />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    />
  )
}

export default ServicesList;
