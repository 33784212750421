import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type AccessoriesListFragment = { __typename?: 'Accessories', id: string, title: string, price: number, image: string, active?: boolean | null, subtitle: string, category: { __typename?: 'Category', id: string, name: string } };

export const AccessoriesListFragmentDoc = gql`
    fragment AccessoriesList on Accessories {
  id
  title
  price
  image
  active
  subtitle
  category {
    id
    name
  }
}
    `;