import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type ServiceListFragment = { __typename?: 'Services', id: string, desc: string, title: string, price: number, category: { __typename?: 'ServicesCategory', id: string, title: string } };

export const ServiceListFragmentDoc = gql`
    fragment ServiceList on Services {
  id
  desc
  title
  price
  category {
    id
    title
  }
}
    `;