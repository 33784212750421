import {FC} from "react";
import {Select, Space, Tag} from "antd";
import {useGetStatusesSelectQuery} from "./__generated__/StatusSelect.query.generated";

const StatusSelect:FC<StatusSelectProps> = ({ onChange, size = "middle", showSearch = true, allowClear = false, placeholder, defaultValue }) => {
  const { data, loading } = useGetStatusesSelectQuery();

  return (
    <Select
      size={size}
      loading={loading}
      onChange={onChange}
      defaultValue={defaultValue}
      allowClear={allowClear}
      showSearch={showSearch}
      placeholder={placeholder}
      style={{ width: '100%' }}
      optionFilterProp="children"
    >
      {data?.getStatuses.map((status) => (
        <Select.Option
          key={status.id}
          value={status.id}
        >
          {status.title}
        </Select.Option>
      ))}
    </Select>
  )
}

interface StatusSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  size?: 'large' | 'middle' | 'small';
}


export default StatusSelect;
