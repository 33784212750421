import {toast} from "react-toastify";
import useDrawer from "../../../hooks/useDrawer";
import {useDeleteModelMutation} from "./__generated__/ModelDelete.mutation.generated";
import {Contentbar} from "../../../ui/Sidebar";

const ModelDelete = () => {
  const { id, closeRootHandler, closeChildrenHandler } = useDrawer();
  console.log(id);

  const [deleteHandler, { loading }] = useDeleteModelMutation({
    variables: { id },
    onCompleted: () => {
      toast.success('Удаление выполнено!');
      closeRootHandler();
      closeChildrenHandler();
    },
    onError: () => {
      toast.error('При удалении произошла ошибка');
    }
  })

  return (
    <Contentbar onClose={closeChildrenHandler} isLoading={loading} onSubmit={deleteHandler}>
      <h2>Вы точно уверены?</h2>
      <p><b>Внимание!</b> Удаление происиходит безвовратно, удаленые данные восстановлению не подлежать.</p>
      <p>Для продолжения нажмите кнопку <b>"Подтвердить".</b></p>
    </Contentbar>
  )
}

export default ModelDelete;
