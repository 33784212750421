import {FC} from "react";
import styled from "styled-components";

const StyledTh = styled.th`
  top: 0;
  z-index: 10;
  position: sticky;
  background: #F5F7F9;
  box-shadow: 0 1px 0 rgba(207, 215, 223, 0.25);
  border-radius: 2.5px 2.5px 0 0;
  padding: 2rem 1rem;
  font-size: 1.3rem;
`;


const Th:FC = ({ children }) => {
  return (
    <StyledTh>{children}</StyledTh>
  )
}

export default Th;
