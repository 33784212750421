import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePointMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePointMutation = { __typename?: 'Mutation', deletePoint?: string | null };


export const DeletePointDocument = gql`
    mutation deletePoint($id: ID!) {
  deletePoint(id: $id)
}
    `;
export type DeletePointMutationFn = Apollo.MutationFunction<DeletePointMutation, DeletePointMutationVariables>;

/**
 * __useDeletePointMutation__
 *
 * To run a mutation, you first call `useDeletePointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePointMutation, { data, loading, error }] = useDeletePointMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePointMutation(baseOptions?: Apollo.MutationHookOptions<DeletePointMutation, DeletePointMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePointMutation, DeletePointMutationVariables>(DeletePointDocument, options);
      }
export type DeletePointMutationHookResult = ReturnType<typeof useDeletePointMutation>;
export type DeletePointMutationResult = Apollo.MutationResult<DeletePointMutation>;
export type DeletePointMutationOptions = Apollo.BaseMutationOptions<DeletePointMutation, DeletePointMutationVariables>;