import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAdManagerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetAdManagerQuery = { __typename?: 'Query', getAdById: { __typename?: 'Ads', manager?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } };


export const GetAdManagerDocument = gql`
    query getAdManager($id: ID!) {
  getAdById(id: $id) {
    manager {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetAdManagerQuery__
 *
 * To run a query within a React component, call `useGetAdManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdManagerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdManagerQuery(baseOptions: Apollo.QueryHookOptions<GetAdManagerQuery, GetAdManagerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdManagerQuery, GetAdManagerQueryVariables>(GetAdManagerDocument, options);
      }
export function useGetAdManagerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdManagerQuery, GetAdManagerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdManagerQuery, GetAdManagerQueryVariables>(GetAdManagerDocument, options);
        }
export type GetAdManagerQueryHookResult = ReturnType<typeof useGetAdManagerQuery>;
export type GetAdManagerLazyQueryHookResult = ReturnType<typeof useGetAdManagerLazyQuery>;
export type GetAdManagerQueryResult = Apollo.QueryResult<GetAdManagerQuery, GetAdManagerQueryVariables>;