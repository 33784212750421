import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface DrawerSlice {
  id: string;
  rootDrawer: string;
  childrenDrawer: string;
  childrenWidth: number;
}

const initialState: DrawerSlice = {
  id: '',
  rootDrawer: '',
  childrenDrawer: '',
  childrenWidth: 350
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openRootDrawer: (state, action: PayloadAction<{ id?: string, rootDrawer: string }>) => {
      state.id = action.payload.id as string;
      state.rootDrawer = action.payload.rootDrawer as string;
    },
    closeRootDrawer: (state) => {
      state.id = '';
      state.rootDrawer = '';
    },

    openChildrenDrawer: (state, action: PayloadAction<{ childrenDrawer: string, childrenWidth?: number }>) => {
      state.childrenDrawer = action.payload.childrenDrawer;
      state.childrenWidth = action.payload.childrenWidth || 350;
    },
    closeChildrenDrawer: (state) => {
      state.childrenDrawer = '';
    }
  }
})

export const {
  openRootDrawer,
  closeRootDrawer,
  openChildrenDrawer,
  closeChildrenDrawer
} = drawerSlice.actions;

export default drawerSlice.reducer;
