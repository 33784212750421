import {FC} from "react";
import {Select, Space, Tag} from "antd";
import {useGetServiceSelectQuery} from "./__generated__/ServicesSelect.query.generated";

const ServicesSelect:FC<ServicesSelectProps> = ({ mode = "default", defaultValue, onChange, size = "middle", showSearch = true, allowClear = false, placeholder }) => {
  const {loading, data} = useGetServiceSelectQuery();

  const type = mode === 'default' ? { onChange: onChange,  defaultValue: defaultValue } : { mode: "multiple", defaultValue: defaultValue } as const;

  return (
      <Select
        {...type}
        size={size}
        loading={loading}
        onChange={onChange}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: '100%' }}
        optionFilterProp="children"
      >
        {data?.getServices.map((service) => (
          <Select.Option
            key={service.id}
            value={service.id}
          >
            {service.category.title} - {service.title} - {service.price} ₸
          </Select.Option>
        ))}
      </Select>
  )
}

interface ServicesSelectProps {
  allowClear?: boolean;
  showSearch?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  defaultValue?: string | string[];
  size?: 'large' | 'middle' | 'small';
  onChange?: (value: string | string[]) => void;
}


export default ServicesSelect;
