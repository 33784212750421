import * as Types from '../../../../__generated__/types';

import { gql } from '@apollo/client';
export type CityListFragment = { __typename?: 'City', id: string, name: string, latin: string, active?: boolean | null };

export const CityListFragmentDoc = gql`
    fragment CityList on City {
  id
  name
  latin
  active
}
    `;